/* eslint-disable no-unused-vars */
import { useState } from "react";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import { PopupModal } from "react-calendly";

/* ANIMATION */
import { animation, containerAnimation } from "config";

/* DATA */
import blogsList from "data/blogs";

/* USER DEFINED COMPONENTS */
import { Searchbar, CategoryBox, BlogBox } from "components/blogs";

/* FORM VALIDATIONS */
import * as yup from "yup";
import { useFormik } from "formik";

/* API */
import { newsletterAPI } from "service/api";

/* VALIDATION SCHEMA */
const waitlistValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .min(1, "Email Address is required")
    .required("Email Address is required."),
});

/* BLOG CATEGORIES */
const blogCategories = [
  {
    id: 1,
    name: "Task Management",
    link: "/blogs/task-management",
  },
  {
    id: 2,
    name: "Employee Engagement",
    link: "/blogs/employee-engagement",
  },
  {
    id: 3,
    name: "Performance Management",
    link: "/blogs/performance-management",
  },
  {
    id: 4,
    name: "Change Management",
    link: "/blogs/change-management",
  },
  {
    id: 5,
    name: "OKR Management",
    link: "/blogs/okr-management",
  },
  {
    id: 6,
    name: "Project Planning",
    link: "/blogs/project-planning",
  },
  {
    id: 7,
    name: "Project Management",
    link: "/blogs/project-management",
  },
  {
    id: 8,
    name: "Team collaboration",
    link: "/blogs/team-collaboration",
  },
];

const Blogs = () => {
  const [calendlyModal, setCalendlyModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  /* FORM HANDLING */
  const formHandling = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: waitlistValidation,
    onSubmit: (values) => {
      const obj = {
        email: values.email,
      };
      setFormLoading(true);
      newsletterAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(
              "Congratulations! 🎉 You have subscribed to our newsletter."
            );
          } else {
            toast.error(
              "We were not able to capture your details. Please try again."
            );
          }
          setFormLoading(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formHandling.resetForm();
          setFormLoading(false);
        });
    },
  });

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* BANNER */}
      <motion.div variants={animation} className="pt-5 xl:pt-12 lg:pt-12">
        <div className="container mx-auto">
          <div className="block xl:flex lg:flex items-center gap-y-5 xl:gap-10 lg:gap-10">
            <div className="w-full">
              <h1 className="text-3xl xl:text-4xl lg:text-4xl font-tight-bold text-black">
                Stay updated on{" "}
                <span className="text-brandColor">
                  Work Management Resources
                </span>{" "}
                with Aligned Rewards
              </h1>
              <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText mt-1">
                Discover the power of improved work management resources,
                employee engagement, goal-setting, and tips on using our
                employee rewards tool.
              </h2>
            </div>
            <div className="w-full xl:w-1/4 lg:w-1/4 mt-5 xl:mt-0 lg:mt-0 hidden">
              <Searchbar type="text" name="searchBlog" id="searchBlog" />
            </div>
          </div>
        </div>
      </motion.div>

      {/* CATEGORIES */}
      <motion.div
        variants={animation}
        className="pt-5 xl:pt-12 lg:pt-12 hidden"
      >
        <div className="container mx-auto">
          <div className="grid grid-cols-2 xl:grid-cols-4 lg:grid-cols-4 gap-3 xl:gap-5 lg:gap-5">
            {blogCategories.map((data) => (
              <div className="col-span-1" key={data.id}>
                <CategoryBox link={data.link} categoryName={data.name} />
              </div>
            ))}
          </div>
        </div>
      </motion.div>

      {/* BLOGS LIST AND SIDEBAR */}
      <motion.div variants={animation} className="py-5 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5 xl:gap-8 lg:gap-8">
            {blogsList.map((data) => (
              <div className="col-span-1" key={data.id}>
                <BlogBox
                  coverImg={data.coverImg}
                  time={data.time}
                  title={data.title}
                  description={data.description}
                  profile={data.profile}
                  name={data.name}
                  designation={data.designation}
                  link={data.link}
                  label={data.label}
                />
              </div>
            ))}
          </div>
        </div>
      </motion.div>

      {/* CALENDLY MODAL */}
      <PopupModal
        open={calendlyModal}
        onModalClose={() => setCalendlyModal(!calendlyModal)}
        rootElement={document.getElementById("root")}
        url="https://calendly.com/contact-ucp8/30min"
      />
    </motion.section>
  );
};

export default Blogs;
