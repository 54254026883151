import { useState } from "react";

/* PACKAGES */
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";

/* ICONS */
import { GiCheckMark } from "react-icons/gi";
import { FiArrowUpRight } from "react-icons/fi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";

/* CONFIG */
import { animation, GCP_BUCKET_URL } from "config";

/* DATA */
import { testimonials } from "data/homepage";

const CallToAction = () => {
  return (
    <motion.div variants={animation} className="py-5 xl:py-14 lg:py-14">
      <div className="container mx-auto">
        <div className="bg-brandColor bg-opacity-20 border border-brandColor border-opacity-20 rounded-2xl overflow-hidden shadow-md">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 items-center">
            <div className="col-span-1 p-5 xl:p-10 lg:p-10 relative">
              <h5 className="text-base font-proxima-semibold text-black">
                READY TO GROW?
              </h5>
              <h2 className="text-2xl xl:text-4xl lg:text-4xl font-tight-bold mt-5 leading-snug">
                Empowering Excellence in Workforce Performance with Improved
                Employee Engagement
              </h2>
              <h5 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-black mt-5 leading-relaxed tracking-tight">
                Implement your strategy effortlessly using the software that's
                highly favored and easy to use within the industry. Streamline
                operations with our intuitive platform, designed to meet your
                business needs.
              </h5>
              <div className="mt-5 xl:mt-10 lg:mt-10">
                <div className="block xl:flex lg:flex items-center gap-5">
                  <div className="flex items-center gap-2">
                    <GiCheckMark className="w-3 h-3 text-black" />
                    <p className="text-base xl:text-sm lg:text-sm font-proxima-regular text-black whitespace-nowrap">
                      Employee Engagement
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <GiCheckMark className="w-3 h-3 text-black" />
                    <p className="text-base xl:text-sm lg:text-sm font-proxima-regular text-black mt-2 xl:mt-0 lg:mt-0 whitespace-nowrap">
                      360° Performance Feedback
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <GiCheckMark className="w-3 h-3 text-black" />
                    <p className="text-base xl:text-sm lg:text-sm font-proxima-regular text-black mt-2 xl:mt-0 lg:mt-0 whitespace-nowrap">
                      Task Management
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <GiCheckMark className="w-3 h-3 text-black" />
                    <p className="text-base xl:text-sm lg:text-sm font-proxima-regular text-black mt-2 xl:mt-0 lg:mt-0 whitespace-nowrap">
                      Employee Development
                    </p>
                  </div>
                </div>
              </div>
              <Link to="/waitlist">
                <Button className="mt-5 xl:mt-10 lg:mt-0 shadow-none hover:shadow-none bg-black hover:bg-opacity-70 text-sm font-proxima-semibold text-white flex items-center justify-center gap-2 font-normal normal-case">
                  Join the waitlist
                  <FiArrowUpRight className="w-5 h-5 text-white" />
                </Button>
              </Link>

              {/* ARROW */}
              <div className="absolute top-10 right-0 hidden xl:block lg:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="119"
                  height="33"
                  fill="none"
                >
                  <path
                    fill="#000"
                    fill-rule="evenodd"
                    d="M107.941 19.973c-2.739-4.69-2.98-5.215-4.19-3.472-1.017 1.475-1.499 2.45-2.335 3.47-.298.363-.818.504-1.247.309a40.155 40.155 0 0 1-2.325-1.065c-2.922-1.385-3.313-1.35-3.494.149-.213 1.77.5 2.784 2.606 3.69 2.144.915 2.13.892 1.063 2.553-1.116 1.69-1.344 3.214-.685 4.355 1.906 3.301 2.465.54 16.525 2.62 3.285.48 5.5 1.133 3.326-1.92-1.024-1.499-3.504-4.533-9.244-10.689Zm-20.322-5.686c-4.56-2.688-7.318-2.965-7.124-.764.151 1.908.482 2.097 5.541 3.673 4.535 1.436 5.584-.532 1.583-2.91ZM74.365 9.049c-2.794-.89-9.51-1.826-10.223-1.414-1.71.987-.132 4.161 2.107 4.199 1.552.054 7.636 1.102 8.73 1.516 1.346.521 1.921-.951 1.188-2.935-.293-.78-.549-.95-1.802-1.366Zm-7.92 5.649c-1.705-1.803-2.032 1.141-.445 3.836 1.734 2.894 1.727 4.197.022 7.114-1.294 2.203-.533 4.234 1.112 2.968 3.102-2.361 2.686-10.324-.688-13.918Zm-9.167-5.95c-2.184.057-6.808 4.5-7.006 6.736-.201 2.175 1.03 3.428 1.962 1.971.763-1.2 2.76-2.955 4.54-3.983 2.305-1.33 2.688-4.782.504-4.725Zm-8.153-6.442c-3.12-1.62-10.171-1.982-11.24-.542-1.356 1.765.223 3.513 3.248 3.54 1.082.01 3.178.13 4.635.239 5.717.468 5.51.492 5.389-.705-.121-1.197-.586-1.783-2.032-2.532Zm10.472 26.037c-3.42-.876-7.452-3.964-8.473-6.446-.795-1.98-1.66-.024-1.034 2.433 1.182 4.734 10.852 10.458 11.786 6.973.401-1.498-.33-2.438-2.28-2.96ZM29.709 1.481c-3.416-.815-9.89.864-10.093 2.596-.262 2.4.724 3.066 3.419 2.302 3.082-.861 7.135-1.301 7.848-1.712 1.235-.714.447-2.792-1.174-3.186ZM12.834 5.934C7.95 6.474-.17 12.048.287 14.54c.297 1.665.538 1.59 6.763-1.782 3.105-1.698 6.061-3.215 6.568-3.38 2.048-.677 1.388-3.685-.784-3.444Z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
            <div className="col-span-1">
              <div className="-mb-5 xl:-mb-20 lg:-mb-20 mt-0 xl:mt-10 lg:mt-10 ml-5 xl:ml-16 lg:ml-16 -mr-10 xl:-mr-28 lg:-mr-28">
                <img
                  src={`${GCP_BUCKET_URL}/cta.png`}
                  className="w-full h-auto shadow-2xl rounded-2xl bg-blend-multiply"
                  alt="Elevate your employee performance"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const FAQAccordion = (props) => {
  return (
    <Accordion
      open={props.state}
      icon={
        props.state ? (
          <IoIosArrowUp className="w-4 h-4 text-black" />
        ) : (
          <IoIosArrowDown className="w-4 h-4 text-black" />
        )
      }
      className="mt-3 xl:mt-5 lg:mt-5 first:mt-0"
    >
      <AccordionHeader
        className={`bg-white border border-greyBorder px-5 py-3 ${
          props.state ? "rounded-t-lg" : "rounded-lg"
        } text-base font-proxima-semibold text-black`}
        onClick={props.onClick}
      >
        {props.title}
      </AccordionHeader>
      <AccordionBody
        className={`p-5 bg-white ${
          props.state ? "border border-t-0 rounded-b-lg" : "rounded-none"
        } border-greyBorder `}
      >
        <p className="text-base font-proxima-regular text-grey leading-relaxed">
          {props.description}
        </p>
      </AccordionBody>
    </Accordion>
  );
};

const CounterComponent = (props) => {
  return (
    <>
      <h2 className="text-5xl font-tight-bold text-brandColor leading-none">
        {props.number}
      </h2>
      <div className="mt-2">
        <h5 className="text-xl font-proxima-bold text-black">{props.title}</h5>
        <p className="text-base font-proxima-regular text-black text-opacity-70 mt-2 leading-relaxed">
          {props.description}
        </p>
      </div>
    </>
  );
};

const Testimonials = () => {
  return (
    <motion.div
      variants={animation}
      className="py-5 xl:py-14 lg:py-14 bg-brandColor bg-opacity-10"
    >
      {/* HEADING */}
      <div className="container mx-auto">
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h5 className="text-base font-proxima-semibold text-black text-opacity-80 uppercase">
              Case studies
            </h5>
            <h2 className="text-2xl xl:text-4xl lg:text-4xl font-tight-bold text-black">
              Why people-centric teams love Aligned Rewards
            </h2>
          </div>
          <div className="hidden xl:flex lg:flex items-center gap-3">
            <Button className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:text-white text-black text-opacity-80 border-2 border-black border-opacity-70 rounded-full p-3 transition-all duration-300 ease-in-out previous-btn">
              <IoArrowBack className="w-5 h-5" />
            </Button>
            <Button className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:text-white text-black text-opacity-80 border-2 border-black border-opacity-70 rounded-full p-3 transition-all duration-300 ease-in-out proceed-btn">
              <IoArrowForward className="w-5 h-5" />
            </Button>
          </div>
        </div>
      </div>

      {/* TESTIMONIALS SLIDER */}
      <div className="container mx-auto">
        {/* SWIPER */}
        <div className="mt-5 xl:mt-8 lg:mt-8 relative">
          <div className="hidden xl:block lg:block">
            <Swiper
              spaceBetween={40}
              breakpoints={{
                300: {
                  slidesPerView: 1,
                },
                400: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1000: {
                  slidesPerView: 3,
                },
              }}
              modules={[Navigation, Pagination]}
              effect="fade"
              navigation={{
                nextEl: ".proceed-btn",
                prevEl: ".previous-btn",
              }}
              className="relative pb-2"
            >
              {testimonials.map((data) => (
                <SwiperSlide key={data.id} className="!w-testimonial">
                  <div className="min-w-testimonial h-testimonial bg-white rounded-xl shadow-md">
                    <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
                      <div className="col-span-1">
                        <img
                          src={data.image}
                          className="w-full h-testimonial rounded-t-xl xl:rounded-t-none lg:rounded-t-none rounded-l-none xl:!rounded-l-xl lg:!rounded-l-xl object-cover"
                          alt="Deva Djaffar from American Kitchen"
                        />
                      </div>
                      <div className="col-span-2">
                        <div className="p-8">
                          <h5 className="text-base font-proxima-regular text-greyText leading-relaxed">
                            {data.testimonial}
                          </h5>
                          <div className="mt-5 pt-5 border-t border-greyBorder">
                            <h5 className="text-base font-proxima-semibold text-black tracking-tight">
                              {data.name}
                            </h5>
                            <p className="text-sm font-proxima-regular text-greyText">
                              {data.designation}
                            </p>
                            <p className="text-sm font-proxima-semibold text-black">
                              {data.company}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="block xl:hidden lg:hidden">
            <Swiper
              spaceBetween={40}
              breakpoints={{
                300: {
                  slidesPerView: 1,
                },
                400: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1000: {
                  slidesPerView: 3,
                },
              }}
              modules={[Navigation, Pagination, Autoplay, EffectFade]}
              effect="fade"
              autoplay={{ delay: 2500, disableOnInteraction: false }}
              navigation={{
                nextEl: ".proceed-btn",
                prevEl: ".previous-btn",
              }}
              className="relative pb-2"
            >
              {testimonials.map((data) => (
                <SwiperSlide
                  key={data.id}
                  className="!w-full xl:!w-testimonial lg:!w-testimonial"
                >
                  <div className="min-w-full xl:min-w-testimonial lg:min-w-testimonial h-auto xl:h-testimonial lg:h-testimonial bg-white rounded-xl shadow-md">
                    <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
                      <div className="col-span-1">
                        <img
                          src={data.image}
                          className="w-full h-testimonial rounded-t-xl xl:rounded-t-none lg:rounded-t-none rounded-l-none xl:!rounded-l-xl lg:!rounded-l-xl object-cover"
                          alt="Deva Djaffar from American Kitchen"
                        />
                      </div>
                      <div className="col-span-2">
                        <div className="p-8">
                          <h5 className="text-base font-proxima-regular text-greyText leading-relaxed">
                            {data.testimonial}
                          </h5>
                          <div className="mt-5 pt-5 border-t border-greyBorder">
                            <h5 className="text-base font-proxima-semibold text-black tracking-tight">
                              {data.name}
                            </h5>
                            <p className="text-sm font-proxima-regular text-greyText">
                              {data.designation}
                            </p>
                            <p className="text-sm font-proxima-semibold text-black">
                              {data.company}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const TestimonialsLight = () => {
  return (
    <motion.div variants={animation} className="py-5 xl:py-14 lg:py-14">
      {/* HEADING */}
      <div className="container mx-auto">
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h5 className="text-base font-proxima-semibold text-black text-opacity-80 uppercase">
              Case studies
            </h5>
            <h2 className="text-2xl xl:text-4xl lg:text-4xl font-tight-bold text-black">
              Why people-centric teams love Aligned Rewards
            </h2>
          </div>
          <div className="hidden xl:flex lg:flex items-center gap-3">
            <Button className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:text-white text-black text-opacity-80 border-2 border-black border-opacity-70 rounded-full p-3 transition-all duration-300 ease-in-out previous-btn">
              <IoArrowBack className="w-5 h-5" />
            </Button>
            <Button className="shadow-none hover:shadow-none bg-transparent hover:bg-black hover:text-white text-black text-opacity-80 border-2 border-black border-opacity-70 rounded-full p-3 transition-all duration-300 ease-in-out proceed-btn">
              <IoArrowForward className="w-5 h-5" />
            </Button>
          </div>
        </div>
      </div>

      {/* TESTIMONIALS SLIDER */}
      <div className="container mx-auto">
        {/* SWIPER */}
        <div className="mt-5 xl:mt-8 lg:mt-8 relative">
          <div className="hidden xl:block lg:block">
            <Swiper
              spaceBetween={40}
              breakpoints={{
                300: {
                  slidesPerView: 1,
                },
                400: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1000: {
                  slidesPerView: 3,
                },
              }}
              modules={[Navigation, Pagination]}
              effect="fade"
              navigation={{
                nextEl: ".proceed-btn",
                prevEl: ".previous-btn",
              }}
              className="relative pb-2"
            >
              {testimonials.map((data) => (
                <SwiperSlide key={data.id} className="!w-testimonial">
                  <div className="min-w-testimonial h-testimonial bg-white rounded-xl shadow-md">
                    <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
                      <div className="col-span-1">
                        <img
                          src={data.image}
                          className="w-full h-testimonial rounded-t-xl xl:rounded-t-none lg:rounded-t-none rounded-l-none xl:!rounded-l-xl lg:!rounded-l-xl object-cover"
                          alt="Deva Djaffar from American Kitchen"
                        />
                      </div>
                      <div className="col-span-2">
                        <div className="p-8">
                          <h5 className="text-base font-proxima-regular text-greyText leading-relaxed">
                            {data.testimonial}
                          </h5>
                          <div className="mt-5 pt-5 border-t border-greyBorder">
                            <h5 className="text-base font-proxima-semibold text-black tracking-tight">
                              {data.name}
                            </h5>
                            <p className="text-sm font-proxima-regular text-greyText">
                              {data.designation}
                            </p>
                            <p className="text-sm font-proxima-semibold text-black">
                              {data.company}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="block xl:hidden lg:hidden">
            <Swiper
              spaceBetween={40}
              breakpoints={{
                300: {
                  slidesPerView: 1,
                },
                400: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1000: {
                  slidesPerView: 3,
                },
              }}
              modules={[Navigation, Pagination, Autoplay, EffectFade]}
              effect="fade"
              autoplay={{ delay: 2500, disableOnInteraction: false }}
              navigation={{
                nextEl: ".proceed-btn",
                prevEl: ".previous-btn",
              }}
              className="relative pb-2"
            >
              {testimonials.map((data) => (
                <SwiperSlide
                  key={data.id}
                  className="!w-full xl:!w-testimonial lg:!w-testimonial"
                >
                  <div className="min-w-full xl:min-w-testimonial lg:min-w-testimonial h-auto xl:h-testimonial lg:h-testimonial bg-white rounded-xl shadow-md">
                    <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
                      <div className="col-span-1">
                        <img
                          src={data.image}
                          className="w-full h-testimonial rounded-t-xl xl:rounded-t-none lg:rounded-t-none rounded-l-none xl:!rounded-l-xl lg:!rounded-l-xl object-cover"
                          alt="Deva Djaffar from American Kitchen"
                        />
                      </div>
                      <div className="col-span-2">
                        <div className="p-8">
                          <h5 className="text-base font-proxima-regular text-greyText leading-relaxed">
                            {data.testimonial}
                          </h5>
                          <div className="mt-5 pt-5 border-t border-greyBorder">
                            <h5 className="text-base font-proxima-semibold text-black tracking-tight">
                              {data.name}
                            </h5>
                            <p className="text-sm font-proxima-regular text-greyText">
                              {data.designation}
                            </p>
                            <p className="text-sm font-proxima-semibold text-black">
                              {data.company}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const FrequentlyAskedQuestions = () => {
  const [accordion01, setAccordion01] = useState(false);
  const [accordion02, setAccordion02] = useState(false);
  const [accordion03, setAccordion03] = useState(false);
  const [accordion04, setAccordion04] = useState(false);
  const [accordion05, setAccordion05] = useState(false);

  return (
    <motion.div
      variants={animation}
      className="py-5 xl:py-14 lg:py-14 bg-pricingBlue"
    >
      <div className="container mx-auto">
        <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-20 lg:gap-20 items-start">
          {/* FAQS */}
          <div className="col-span-1">
            <div className="p-0">
              <h2 className="text-2xl xl:text-4xl lg:text-4xl font-tight-bold text-black">
                Frequently Asked Questions
              </h2>
              <div className="w-full xl:w-4/5 lg:w-4/5">
                <h5 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight mt-2">
                  Find answers to common questions independently, avoiding the
                  need for direct communication.
                </h5>
              </div>
            </div>
            <div className="mt-5 xl:mt-8 lg:mt-8">
              <FAQAccordion
                state={accordion01}
                onClick={() => setAccordion01(!accordion01)}
                title="Why does employee engagement matter?"
                description="Employee engagement is the commitment an employee makes towards an organization, meaning being fully present in terms of work, dedication, and attention. It is important because it contributes to a positive work environment, higher productivity levels, and lower employee turnover rates."
              />
              <FAQAccordion
                state={accordion02}
                onClick={() => setAccordion02(!accordion02)}
                title="How does artificial intelligence support performance feedback?"
                description="Artificial intelligence in performance feedback enables data-driven decision-making, automates mundane tasks, and enhances predictive analytics, leading to more efficient talent management processes."
              />
              <FAQAccordion
                state={accordion03}
                onClick={() => setAccordion03(!accordion03)}
                title="How does performance feedback support the top line of the organization?"
                description="Performance feedback boosts employee performance and satisfaction, leading to increased productivity, better customer experiences, and higher revenues and profits for the organization."
              />
              <FAQAccordion
                state={accordion04}
                onClick={() => setAccordion04(!accordion04)}
                title="What are the significant components of performance feedback?"
                description="The major features of talent management include recruitment, onboarding, learning and development, performance feedback, succession planning, and employee engagement."
              />
              <FAQAccordion
                state={accordion05}
                onClick={() => setAccordion05(!accordion05)}
                title="What are some powerful talent management strategies?"
                description="Talent management strategies include encouraging a culture of continuous learning, offering personalized development plans, conducting regular performance evaluations, and providing opportunities for career advancement."
              />
            </div>
          </div>

          {/* STOCK IMAGE */}
          <div className="col-span-1">
            <img
              src={`${GCP_BUCKET_URL}/faqs-banner.webp`}
              className="w-full h-[30vh] xl:h-faqBanner lg:h-faqBanner object-cover rounded-xl shadow-md"
              alt="Frequently Asked Questions"
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export {
  CallToAction,
  FAQAccordion,
  CounterComponent,
  Testimonials,
  TestimonialsLight,
  FrequentlyAskedQuestions,
};
