import { useState } from "react";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer } from "react-toastify";
import { PopupModal } from "react-calendly";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* ANIMATION */
import { animation, containerAnimation, GCP_BUCKET_URL } from "config";

/* COMPONENTS */
import {
  CallToAction,
  CounterComponent,
  FrequentlyAskedQuestions,
} from "components/homepage";

/* ICONS */
import { FaCircleCheck } from "react-icons/fa6";

const TaskManagement = () => {
  const [calendlyModal, setCalendlyModal] = useState(false);

  /* PRODUCTIVITY CARD */
  const PerformanceCard = (props) => {
    return (
      <div className="bg-white border border-greyBorder rounded-lg shadow-md p-8">
        <img src={props.image} className="w-16 h-16" alt={props.title} />
        <div className="mt-4">
          <h5 className="text-xl font-proxima-bold text-black">
            {props.title}
          </h5>
          <p className="text-base font-proxima-regular text-greyText mt-1">
            {props.description}
          </p>
        </div>
      </div>
    );
  };

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* BANNER */}
      <motion.div
        variants={animation}
        className="p-0 py-5 xl:p-5 lg:p-5 relative h-auto xl:h-featureBanner lg:h-featureBanner"
      >
        {/* LEFT SECTION */}
        <div className="container mx-auto h-full">
          <div className="h-full flex items-center">
            <div className="w-full xl:w-2/5 lg:w-2/5">
              <div className="bg-brandColor bg-opacity-10 rounded-full border border-brandColor w-fit px-4 py-1">
                <p className="text-sm font-proxima-semibold text-brandColor">
                  Task Management
                </p>
              </div>
              <div className="mt-5">
                <h1 className="text-3xl xl:text-4xl lg:text-4xl font-tight-bold text-black leading-snug">
                  Efficiently organize, track, and streamline tasks with our
                  intuitive management software
                </h1>
                <h2 className="mt-5 text-lg xl:text-xl lg:text-xl font-proxima-regular text-greyText tracking-tight">
                  Our Task Management Software simplifies organization,
                  collaboration, and tracking of tasks. Boost productivity,
                  prioritize effectively, and ensure deadlines are met with
                  ease. Try it now!
                </h2>
                <div className="mt-5 xl:mt-8 lg:mt-8">
                  <div className="flex items-center gap-3">
                    <Button
                      className="shadow-none hover:shadow-none rounded-lg bg-brandColor normal-case text-base font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                      onClick={() => setCalendlyModal(!calendlyModal)}
                    >
                      Schedule a Demo
                    </Button>
                  </div>
                </div>
                <div className="mt-8">
                  <div className="flex items-center gap-2">
                    <FaCircleCheck className="w-5 h-5 text-green-500" />
                    <p className="text-base font-proxima-regular text-greyText">
                      30 day free trial
                    </p>
                  </div>
                  <div className="flex items-center gap-2 mt-3">
                    <FaCircleCheck className="w-5 h-5 text-green-500" />
                    <p className="text-base font-proxima-regular text-greyText">
                      No credit card required
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* RIGHT SECTION */}
        <div className="relative xl:absolute lg:absolute top-5 right-0 w-full xl:w-1/2 lg:w-1/2 h-full overflow-hidden pl-5 xl:pl-0 lg:pl-0">
          <div className="h-full bg-gradient-to-tr from-blue-100 to-white rounded-l-2xl ">
            <div className="w-[150vh] h-[40vh] xl:h-featureBanner lg:h-featureBanner relative xl:absolute lg:absolute top-0 xl:top-10 lg:top-10 left-0 xl:left-10 lg:left-10">
              <img
                src={`${GCP_BUCKET_URL}/rename-task-development.png`}
                className="w-full h-[80vh]"
                alt="360° performance feedback with Aligned Rewards"
              />
            </div>
          </div>
        </div>
      </motion.div>

      {/* COUNTERS */}
      <motion.div variants={animation} className="mt-5 xl:mt-10 lg:mt-10">
        <div className="bg-footer bg-opacity-5 py-10">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-10 xl:gap-20 lg:gap-20">
              <div className="col-span-1">
                <CounterComponent
                  title="Enhance Performance Tracking "
                  description="Efficiently monitor employee performance with dynamic inbuilt tools, allowing accurate assessment and targeted feedback for continual improvement. "
                />
              </div>
              <div className="col-span-1">
                <CounterComponent
                  title="Reduce Administrative Burden"
                  description="Organize onboarding processes to reduce administrative workload by 30%, allowing for greater focus on strategic management initiatives."
                />
              </div>
              <div className="col-span-1">
                <CounterComponent
                  title="Amplify Task Organization"
                  description="Simplify task management processes for intensified productivity and collaboration, ensuring clear directives and skillful task completion."
                />
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* REQUIREMENTS - LEFT IMAGE */}
      <motion.div variants={animation} className="py-5 xl:py-14 lg:py-14">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-5 xl:gap-10 lg:gap-10 items-center">
            <div className="col-span-1">
              <div className="w-full h-[50vh] overflow-hidden shadow-md rounded-xl">
                <div className="w-full h-full rounded-xl bg-gradient-to-br from-blue-100 to-white relative">
                  <div className="w-[85vh] h-auto relative xl:absolute lg:absolute top-5 xl:top-14 lg:top-14 -left-5 xl:left-5 lg:left-5">
                    <img
                      src={`${GCP_BUCKET_URL}/task-management/01.png`}
                      className="w-full h-[55vh] rounded-lg"
                      alt=" Get the overall statistics of your objectives and key results at one place"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1 flex items-center justify-center">
              <div className="w-full xl:w-11/12 lg:w-11/12">
                <h2 className="text-2xl xl:text-3xl lg:text-3xl font-tight-bold text-black leading-snug">
                  Easily rank tasks based on priorities for efficient management
                </h2>
                <h5 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText mt-3">
                  Visualize tasks, track progress, and enhance collaboration
                  effortlessly, optimizing workflow management for your
                  performance.
                </h5>
                <div className="mt-5">
                  <div className="flex items-start gap-3">
                    <FaCircleCheck className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText">
                      Create tasks, set priorities, and set deadlines to get
                      tasks done. Divide your major tasks into subtasks and
                      track progress.
                    </p>
                  </div>
                  <div className="flex items-start gap-3 mt-5">
                    <FaCircleCheck className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText">
                      Sort your to-dos, coordinate what's most important for
                      your team, and make the most of your work hours with our
                      easy-to-use app.
                    </p>
                  </div>
                </div>
                <div className="mt-5 xl:mt-10 lg:mt-10">
                  <Button
                    className="shadow-none hover:shadow-none rounded-lg bg-brandColor normal-case text-base font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* GOALS AND OKRS - RIGHT IMAGE */}
      <motion.div
        variants={animation}
        className="py-5 xl:py-14 lg:py-14 bg-footer bg-opacity-5"
      >
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-5 xl:gap-10 lg:gap-10 items-center">
            <div className="col-span-1 order-2 xl:order-1 lg:order-1">
              <div className="w-full xl:w-11/12 lg:w-11/12">
                <h2 className="text-2xl xl:text-3xl lg:text-3xl font-tight-bold text-black leading-snug">
                  Collaborate with team members for improved tracking and faster
                  progress
                </h2>
                <h5 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText mt-3">
                  Work together with your team while always being aware of the
                  tasks in hand. Team effort means faster results!
                </h5>
                <div className="mt-5">
                  <div className="flex items-start gap-3">
                    <FaCircleCheck className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText">
                      Tag or mention team members on projects or tasks so
                      everyone remains updated on the status of work, knows
                      their involvement, and remains aware.
                    </p>
                  </div>
                  <div className="flex items-start gap-3 mt-3">
                    <FaCircleCheck className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText">
                      Enhance work with the comments feature, where your team
                      can leave comments to ask questions or for managers to
                      share real-time feedback, making it easy for everyone to
                      share thoughts and improve together.
                    </p>
                  </div>
                </div>
                <div className="mt-5 xl:mt-10 lg:mt-10">
                  <Button
                    className="shadow-none hover:shadow-none rounded-lg bg-brandColor normal-case text-base font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-span-1 order-1 xl:order-2 lg:order-2">
              <div className="w-full h-[50vh] overflow-hidden shadow-md rounded-xl">
                <div className="w-full h-full rounded-xl bg-gradient-to-br from-blue-100 to-white relative">
                  <div className="w-[85vh] h-auto relative xl:absolute lg:absolute top-5 xl:top-14 lg:top-14 -left-5 xl:left-5 lg:left-5">
                    <img
                      src={`${GCP_BUCKET_URL}/task-management/02.png`}
                      className="w-full h-[55vh] rounded-lg"
                      alt="Harmonize your teams with objectives for enhanced outcomes"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* OPTIONS */}
      <motion.div variants={animation} className="py-5 xl:py-14 lg:py-14">
        <div className="container mx-auto">
          {/* HEADING */}
          <div className="w-full xl:w-1/2 lg:w-1/2">
            <h5 className="text-base font-proxima-semibold text-brandColor uppercase">
              ALIGNED REWARDS
            </h5>
            <h2 className="text-2xl xl:text-4xl lg:text-4xl font-tight-bold text-black leading-snug mt-2">
              Boost Team Performance using our 360° Performance Feedback
              Software
            </h2>
          </div>

          {/* CONTENT */}
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5 xl:gap-10 lg:gap-10 mt-5 xl:mt-10 lg:mt-10">
            <div className="col-span-1">
              <PerformanceCard
                image="https://storage.googleapis.com/cdn-roundtechsquare/aligned-rewards/icons/boost-productivity.png"
                title="Boost Productivity"
                description="Maximize efficiency and output to achieve greater productivity in your work."
              />
            </div>
            <div className="col-span-1">
              <PerformanceCard
                image="https://storage.googleapis.com/cdn-roundtechsquare/aligned-rewards/icons/employee-engagement.png"
                title="Employee Engagement"
                description="Foster involvement, satisfaction, and commitment among employees for a thriving workplace culture."
              />
            </div>
            <div className="col-span-1">
              <PerformanceCard
                image="https://storage.googleapis.com/cdn-roundtechsquare/aligned-rewards/icons/business-excellence.png"
                title="Business Excellence"
                description="Attain superior standards and performance, ensuring success and growth in your business endeavors."
              />
            </div>
          </div>
        </div>
      </motion.div>

      {/* FREQUENTLY ASKED QUESTIONS */}
      <FrequentlyAskedQuestions />

      {/* CALL TO ACTION */}
      <CallToAction />

      {/* CALENDLY MODAL */}
      <PopupModal
        open={calendlyModal}
        onModalClose={() => setCalendlyModal(!calendlyModal)}
        rootElement={document.getElementById("root")}
        url="https://calendly.com/contact-ucp8/30min"
      />
    </motion.section>
  );
};

export default TaskManagement;
