const blogsList = [
  {
    id: 9,
    coverImg:
      "https://storage.googleapis.com/cdn-roundtechsquare/aligned-rewards/blogs/remote-work/benefits-of-remote-work.jpeg",
    link: "/the-benefits-of-remote-work-how-to-maintain-productivity-and-balance",
    time: "10",
    title:
      "The Benefits of Remote Work: How to Maintain Productivity and Balance",
    description: "The trend of working at home is relatively new, but it..",
    profile: "hemail-adhiya.webp",
    name: "Hemali Adhiya",
    designation: "Content Specialist",
    label: "Employee Engagement",
  },
  {
    id: 8,
    coverImg:
      "https://storage.googleapis.com/cdn-roundtechsquare/aligned-rewards/blogs/leadership/leadership-cover.webp",
    link: "/7-top-leadership-qualities-for-modern-managers",
    time: "10",
    title: "7 Top Leadership Qualities for Modern Managers",
    description: "Today’s managers are required to deliver results for an..",
    profile: "hemail-adhiya.webp",
    name: "Hemali Adhiya",
    designation: "Content Specialist",
    label: "Employee Engagement",
  },
  {
    id: 7,
    coverImg:
      "https://storage.googleapis.com/cdn-roundtechsquare/aligned-rewards/blogs/effective-communication/effective-communication-image-01.webp",
    link: "/10-powerful-tips-for-effective-workplace-communication",
    time: "10",
    title: "10 Powerful Tips for Effective Workplace Communication",
    description: "Effective workplace communication is one of the most..",
    profile: "hemail-adhiya.webp",
    name: "Hemali Adhiya",
    designation: "Content Specialist",
    label: "Employee Engagement",
  },
  {
    id: 6,
    coverImg:
      "https://storage.googleapis.com/cdn-roundtechsquare/aligned-rewards/blogs/goals-management/goals-management-cover-image-02.webp",
    link: "/4-effective-goal-setting-techniques-for-enhanced-workplace-productivity",
    time: "8",
    title:
      "4 Effective Goal Setting Techniques for Enhanced Workplace Productivity",
    description:
      "This all-encompassing guide will explore various goal setting..",
    profile: "hemail-adhiya.webp",
    name: "Hemali Adhiya",
    designation: "Content Specialist",
    label: "Employee Engagement",
  },
  {
    id: 5,
    coverImg:
      "https://storage.googleapis.com/cdn-roundtechsquare/aligned-rewards/blogs/employee-engagement/employee-engagement-cover-image-02.webp",
    link: "/8-key-elements-of-employee-engagement-for-workplace-motivation",
    time: "15",
    title: "8 Key Elements of Employee Engagement for Workplace Motivation",
    description: "Discover 8 key ways to boost employee engagement! From....",
    profile: "hemail-adhiya.webp",
    name: "Hemali Adhiya",
    designation: "Content Specialist",
    label: "Employee Engagement",
  },
  {
    id: 4,
    coverImg:
      "https://storage.googleapis.com/cdn-roundtechsquare/aligned-rewards/blogs/goals-management/goals-management-cover-image.webp",
    link: "/how-do-you-set-goals-in-your-organization",
    time: "12",
    title: "How do you set goals in your organization?",
    description:
      "Goal-setting for employees builds bridges between individuals and entire organizations, as it helps to map...",
    profile: "hemail-adhiya.webp",
    name: "Hemali Adhiya",
    designation: "Content Specialist",
    label: "Program Management",
  },
  {
    id: 3,
    coverImg:
      "https://storage.googleapis.com/cdn-roundtechsquare/aligned-rewards/blogs/performance-management/performance-management-cover-image.webp",
    link: "/a-comprehensive-guide-to-revolutionizing-performance-management",
    time: "7",
    title: "A Comprehensive Guide to Revolutionizing 360° Performance Feedback",
    description: "Have you had up to your neck with traditional, time...",
    profile: "hemail-adhiya.webp",
    name: "Hemali Adhiya",
    designation: "Content Specialist",
    label: "Performance Management",
  },
  {
    id: 2,
    coverImg:
      "https://storage.googleapis.com/cdn-roundtechsquare/aligned-rewards/blogs/employee-engagement/employee-engagement-cover-image.webp",
    link: "/how-to-improve-employee-engagement-at-your-workplace",
    time: "10",
    title: "How to improve employee engagement at your workplace?",
    description: "In the modern day, when the business environment is...",
    profile: "hemail-adhiya.webp",
    name: "Hemali Adhiya",
    designation: "Content Specialist",
    label: "Employee Engagement",
  },
  {
    id: 1,
    coverImg:
      "https://storage.googleapis.com/cdn-roundtechsquare/aligned-rewards/blogs/rewarding-workplace/rewarding-workplace-cover-image.webp",
    link: "/how-to-cultivate-a-rewarding-workplace-with-employee-rewards",
    time: "15",
    title: "How to Cultivate a Rewarding Workplace with Employee Rewards?",
    description:
      "Organizations often look for me­thods that can align their...",
    profile: "hemail-adhiya.webp",
    name: "Hemali Adhiya",
    designation: "Content Specialist",
    label: "Employee Engagement",
  },
];

export default blogsList;
