import { useState } from "react";
import { Link } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer } from "react-toastify";
import { PopupModal } from "react-calendly";
import Typewriter from "typewriter-effect";

/* ICONS */

import { MdKeyboardArrowRight } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";

/* ANIMATION */
import { animation, containerAnimation, GCP_BUCKET_URL } from "config";

/* USER DEFINED COMPONENTS */
import {
  CallToAction,
  CounterComponent,
  FAQAccordion,
} from "components/homepage";

const Homepage = () => {
  const [calendlyModal, setCalendlyModal] = useState(false);
  const [accordion01, setAccordion01] = useState(false);
  const [accordion02, setAccordion02] = useState(false);
  const [accordion03, setAccordion03] = useState(false);
  const [accordion04, setAccordion04] = useState(false);
  const [accordion05, setAccordion05] = useState(false);

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* HERO BANNER IMAGE */}
      <motion.div variants={animation} className="block xl:hidden lg:hidden">
        <div className="container-full-width mx-auto">
          <img
            src={require("../assets/images/main-banner-mobile.jpg")}
            className="w-full h-auto object-contain"
            alt="Aligned Rewards"
          />
        </div>
      </motion.div>

      {/* HERO BANNER */}
      <motion.div
        variants={animation}
        className="p-0 relative h-auto xl:h-[90vh] lg:h-[90vh] py-10 xl:py-0 lg:py-0 bg-banner"
      >
        <div className="container mx-auto h-full flex items-center">
          <div className="">
            {/* TAGS */}
            <div className="bg-[#4289ff] rounded-md px-4 py-2 w-fit">
              <p className="text-[1.5vh] xl:text-base lg:text-base font-proxima-semibold text-white">
                Transforming employees into ambassadors of your vision
              </p>
            </div>
            <div className="w-full xl:w-2/5 lg:w-2/5 mt-5 xl:mt-10 lg:mt-10">
              <h1 className="w-full text-3xl xl:text-5xl lg:text-5xl font-tight-bold text-black leading-tight">
                An Employee Management Tool that will take care of
              </h1>
              <h1 className="text-3xl xl:text-5xl lg:text-5xl font-tight-bold text-brandColor leading-tight">
                <Typewriter
                  options={{
                    strings: [
                      "Employee Engagement",
                      "360° Performance Feedback",
                      "Employee Development",
                    ],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </h1>
              <h2 className="text-lg xl:text-xl lg:text-xl tracking-tight font-proxima-regular text-greyText mt-5">
                Aligned Rewards is your gateway to organizational success!
                Discover why top firms rely on Aligned Rewards for boosted
                employee engagement and performance feedback. Experience the
                exceptional at your workplace by setting the standard for
                excellence.
              </h2>
              <div className="mt-5 xl:mt-10 lg:mt-10">
                <div className="flex items-center gap-3">
                  <Button
                    className="shadow-none hover:shadow-none rounded-lg bg-brandColor normal-case text-base font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                  <Link to="/waitlist">
                    <Button className="shadow-none hover:shadow-none rounded-lg bg-footer normal-case text-base font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out">
                      Join the waitlist
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* BANNER */}
      <motion.div
        variants={animation}
        className="pt-5 xl:pt-14 lg:pt-14 hidden"
      >
        <div className="container mx-auto">
          {/* TAGS */}
          <div className="flex items-center justify-start xl:justify-center lg:justify-center gap-5">
            <div className="bg-[#4289ff] rounded-md px-4 py-2">
              <p className="text-base font-proxima-semibold text-white">
                Transforming employees into ambassadors of your vision
              </p>
            </div>
          </div>

          <div className="w-full xl:w-3/4 lg:w-3/4 mx-auto mt-5">
            <h1 className="w-full text-3xl xl:text-5xl lg:text-5xl font-tight-bold text-black leading-tight text-start xl:text-center lg:text-center mx-auto">
              An Employee Management Tool that will take care of
            </h1>
            <h1 className="text-3xl xl:text-5xl lg:text-5xl font-tight-bold text-brandColor leading-tight text-start xl:text-center lg:text-center mx-auto">
              <Typewriter
                options={{
                  strings: [
                    "Employee Engagement",
                    "360° Performance Feedback",
                    "Employee Development",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </h1>
            <h2 className="text-lg xl:text-xl lg:text-xl tracking-tight font-proxima-regular text-greyText text-start xl:text-center lg:text-center mt-5">
              Aligned Rewards is your gateway to organizational success!
              Discover why top firms rely on Aligned Rewards for boosted
              employee engagement and performance feedback. Experience the
              exceptional at your workplace by setting the standard for
              excellence.
            </h2>
            <div className="mt-5 xl:mt-10 lg:mt-10">
              <div className="flex items-center justify-start xl:justify-center lg:justify-center gap-3">
                <Button
                  className="shadow-none hover:shadow-none rounded-lg bg-brandColor normal-case text-base font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                  onClick={() => setCalendlyModal(!calendlyModal)}
                >
                  Schedule a Demo
                </Button>
                <Link to="/waitlist">
                  <Button className="shadow-none hover:shadow-none rounded-lg bg-footer normal-case text-base font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out">
                    Join the waitlist
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* IMAGE */}
        <div className="p-3 xl:p-10 lg:p-10 mt-2 xl:mt-0 lg:mt-0">
          <div className="rounded-xl bg-blue-50 p-5">
            <img
              src={`${GCP_BUCKET_URL}/main-hero-banner.png`}
              className="w-full h-auto rounded-xl object-cover -mt-5"
              alt="Aligned Rewards"
            />
          </div>
        </div>
      </motion.div>

      {/* FEATURES */}
      <motion.div variants={animation} className="py-5 xl:py-14 lg:py-14">
        <div className="container mx-auto">
          {/* HEADING */}
          <div className="w-full xl:w-2/5 lg:w-2/5">
            <h5 className="text-base font-proxima-semibold text-brandColor uppercase">
              Our features
            </h5>
            <h2 className="text-2xl xl:text-4xl lg:text-4xl font-tight-bold text-black leading-snug">
              More than one reason why you should join our tool
            </h2>
          </div>

          {/* FEATURES LIST */}
          <div className="mt-5 xl:mt-10 lg:mt-10">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5 xl:gap-10 lg:gap-10">
              {/* EMPLOYEE ENGAGEMENT */}
              <div className="col-span-2">
                <div className="w-full h-auto xl:h-featureBox lg:h-featureBox bg-white rounded-2xl border border-greyBorder shadow-md block xl:flex lg:flex items-center p-5 overflow-hidden">
                  <div className="w-full xl:w-1/2 lg:w-1/2 px-0 xl:px-5 lg:px-5">
                    <div className="pb-3 border-b border-greyBorder">
                      <h5 className="text-xl xl:text-2xl lg:text-2xl font-proxima-bold tracking-tight text-black">
                        Employee Engagement
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText">
                        Align and inspire your team
                      </p>
                    </div>
                    <div className="py-3">
                      <p className="text-base text-black font-proxima-semibold leading-relaxed">
                        Elevate with a peer-based award and recognition program
                        with Aligned Rewards.
                      </p>
                      <p className="mt-5 text-base font-proxima-regular text-greyText">
                        Strengthen employee engagement and track real-time
                        feedback.
                      </p>
                      <div className="mt-5">
                        <div className="flex items-center gap-3">
                          <FaCheckCircle className="w-4 h-4 text-green-500" />
                          <p className="text-base font-proxima-regular text-greyText">
                            Rewards and Recognition
                          </p>
                        </div>
                        <div className="flex items-center gap-3 mt-2">
                          <FaCheckCircle className="w-4 h-4 text-green-500" />
                          <p className="text-base font-proxima-regular text-greyText">
                            Instant Feedback Mechanism
                          </p>
                        </div>
                        <div className="flex items-center gap-3 mt-2">
                          <FaCheckCircle className="w-4 h-4 text-green-500" />
                          <p className="text-base font-proxima-regular text-greyText">
                            Interactive Leaderboard
                          </p>
                        </div>
                      </div>
                      <Link to="/employee-engagement">
                        <Button className="mt-5 shadow-none hover:shadow-none bg-brandColor text-sm font-proxima-semibold text-white rounded-md normal-case px-5 py-[1vh] flex items-center gap-1 justify-center hover:bg-opacity-80 transition-all duration-300 ease-in-out">
                          Learn more
                          <MdKeyboardArrowRight className="w-5 h-5 text-white" />
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div className="w-full xl:w-1/2 lg:w-1/2 h-full overflow-hidden">
                    <div className="w-full h-full rounded-xl bg-gradient-to-br from-blue-100 to-white relative">
                      <div className="w-videoFeature h-auto relative xl:absolute lg:absolute top-10 left-6">
                        <img
                          src={`${GCP_BUCKET_URL}/homepage/employee-engagement.png`}
                          className="object-cover w-full h-full"
                          alt="Employee Engagement"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* PERFORMANCE FEEDBACK SOFTWARE */}
              <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                <div className="w-full h-featureBox bg-white rounded-2xl border border-greyBorder shadow-md pt-5 xl:pt-8 lg:pt-8 overflow-hidden relative">
                  <div className="px-5 xl:px-10 lg:px-10">
                    <div className="pb-3 border-b border-greyBorder">
                      <h5 className="text-2xl font-proxima-bold tracking-tight text-black">
                        360° Performance Feedback
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText">
                        Build a high-performance team
                      </p>
                    </div>
                    <div className="pt-3">
                      <p className="text-base font-proxima-regular text-greyText">
                        Boost employee performance with our customizable
                        Performance Feedback feature.
                      </p>
                      <Link to="/performance-management">
                        <Button className="mt-3 shadow-none hover:shadow-none bg-brandColor text-sm font-proxima-semibold text-white rounded-md normal-case px-5 py-[1vh] flex items-center gap-1 justify-center hover:bg-opacity-80 transition-all duration-300 ease-in-out">
                          Learn more
                          <MdKeyboardArrowRight className="w-5 h-5 text-white" />
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div className="px-5 mt-8">
                    <img
                      src={`${GCP_BUCKET_URL}/homepage/performance-management.png`}
                      className="object-cover w-full h-auto rounded-t-xl"
                      alt="Performance Feedback"
                    />
                  </div>
                </div>
              </div>

              {/* TASK MANAGEMENT SOFTWARE */}
              <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                <div className="w-full h-featureBox bg-white rounded-2xl border border-greyBorder shadow-md pt-5 xl:pt-8 lg:pt-8 overflow-hidden relative">
                  <div className="px-5 xl:px-10 lg:px-10">
                    <div className="pb-3 border-b border-greyBorder">
                      <h5 className="text-2xl font-proxima-bold tracking-tight text-black">
                        Task Management
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText">
                        Manage your tasks effectively
                      </p>
                    </div>
                    <div className="pt-3">
                      <p className="text-base font-proxima-regular text-greyText">
                        Make your team’s work more efficient with our intuitive
                        Task Management features.
                      </p>
                      <Link to="/tasks-management">
                        <Button className="mt-3 shadow-none hover:shadow-none bg-brandColor text-sm font-proxima-semibold text-white rounded-md normal-case px-5 py-[1vh] flex items-center gap-1 justify-center hover:bg-opacity-80 transition-all duration-300 ease-in-out">
                          Learn more
                          <MdKeyboardArrowRight className="w-5 h-5 text-white" />
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div className="px-5 mt-8">
                    <img
                      src={`${GCP_BUCKET_URL}/homepage/task-management.png`}
                      className="object-cover w-full h-auto rounded-t-xl"
                      alt="Task Management"
                    />
                  </div>
                </div>
              </div>

              {/* OKR MANAGEMENT SOFTWARE */}
              <div className="col-span-2">
                <div className="w-full h-auto xl:h-featureBox lg:h-featureBox bg-white rounded-2xl border border-greyBorder shadow-md block xl:flex lg:flex items-center p-5 overflow-hidden">
                  <div className="w-full xl:w-1/2 lg:w-1/2 px-0 xl:px-5 lg:px-5">
                    <div className="pb-3 border-b border-greyBorder">
                      <h5 className="text-xl xl:text-2xl lg:text-2xl font-proxima-bold tracking-tight text-black">
                        Goal Management
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText">
                        Strategy execution made simple and easy
                      </p>
                    </div>
                    <div className="py-3">
                      <p className="text-base text-black font-proxima-semibold leading-relaxed">
                        Close the strategy-execution gap with Goal Alignment,
                        ensuring your plans translate effectively into action.
                      </p>
                      <p className="mt-5 text-base font-proxima-regular text-greyText">
                        Take advantage of this powerful goal-setting framework
                        from Aligned Rewards with benefits like:
                      </p>
                      <div className="mt-5">
                        <div className="flex items-center gap-3 mt-2">
                          <FaCheckCircle className="w-4 h-4 text-green-500" />
                          <p className="text-base font-proxima-regular text-greyText">
                            Faster adaptation and execution
                          </p>
                        </div>
                        <div className="flex items-center gap-3 mt-2">
                          <FaCheckCircle className="w-4 h-4 text-green-500" />
                          <p className="text-base font-proxima-regular text-greyText">
                            Guided weekly check-ins
                          </p>
                        </div>
                      </div>
                      <Link to="/goal-management">
                        <Button className="mt-5 shadow-none hover:shadow-none bg-brandColor text-sm font-proxima-semibold text-white rounded-md normal-case px-5 py-[1vh] flex items-center gap-1 justify-center hover:bg-opacity-80 transition-all duration-300 ease-in-out">
                          Learn more
                          <MdKeyboardArrowRight className="w-5 h-5 text-white" />
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div className="w-full xl:w-1/2 lg:w-1/2 h-full overflow-hidden">
                    <div className="w-full h-full rounded-xl bg-gradient-to-br from-blue-100 to-white relative">
                      <div className="w-videoFeature h-auto relative xl:absolute lg:absolute top-10 left-6">
                        <img
                          src={`${GCP_BUCKET_URL}/homepage/okr-management.png`}
                          className="object-cover w-full h-full"
                          alt="OKR Management"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* COUNTERS */}
      <motion.div variants={animation} className="pb-5 xl:pb-10 lg:pb-10">
        <div className="container mx-auto">
          <div className="bg-footer bg-opacity-5 border border-footer border-opacity-10 rounded-xl py-5 xl:py-10 lg:py-10 px-5 xl:px-12 lg:px-12">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-10 xl:gap-20 lg:gap-20">
              <div className="col-span-1">
                <CounterComponent
                  title="Enhance Performance Tracking "
                  description="Efficiently monitor employee performance with dynamic inbuilt tools, allowing accurate assessment and targeted feedback for continual improvement. "
                />
              </div>
              <div className="col-span-1">
                <CounterComponent
                  title="Reduce Administrative Burden"
                  description="Organize onboarding processes to reduce administrative workload by 30%, allowing for greater focus on strategic management initiatives."
                />
              </div>
              <div className="col-span-1">
                <CounterComponent
                  title="Amplify Task Organization"
                  description="Simplify task management processes for intensified productivity and collaboration, ensuring clear directives and skillful task completion."
                />
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* FREQUENTLY ASKED QUESTIONS */}
      <motion.div
        variants={animation}
        className="py-5 xl:py-14 lg:py-14 bg-brandColor bg-opacity-5"
      >
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 xl:gap-20 lg:gap-20 items-start">
            {/* FAQS */}
            <div className="col-span-1">
              <div className="p-0">
                <h2 className="text-2xl xl:text-4xl lg:text-4xl font-tight-bold text-black">
                  Frequently Asked Questions
                </h2>
                <div className="w-full xl:w-4/5 lg:w-4/5">
                  <h5 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight mt-2">
                    Find answers to common questions independently, avoiding the
                    need for direct communication.
                  </h5>
                </div>
              </div>
              <div className="mt-5 xl:mt-8 lg:mt-8">
                <FAQAccordion
                  state={accordion01}
                  onClick={() => setAccordion01(!accordion01)}
                  title="Why does employee engagement matter?"
                  description="Employee engagement is the commitment an employee makes towards an organization, meaning being fully present in terms of work, dedication, and attention. It is important because it contributes to a positive work environment, higher productivity levels, and lower employee turnover rates."
                />
                <FAQAccordion
                  state={accordion02}
                  onClick={() => setAccordion02(!accordion02)}
                  title="How does artificial intelligence support performance feedback?"
                  description="Artificial intelligence in performance feedback enables data-driven decision-making, automates mundane tasks, and enhances predictive analytics, leading to more efficient talent management processes."
                />
                <FAQAccordion
                  state={accordion03}
                  onClick={() => setAccordion03(!accordion03)}
                  title="How does performance feedback support the top line of the organization?"
                  description="Performance feedback boosts employee performance and satisfaction, leading to increased productivity, better customer experiences, and higher revenues and profits for the organization."
                />
                <FAQAccordion
                  state={accordion04}
                  onClick={() => setAccordion04(!accordion04)}
                  title="What are the significant components of performance feedback?"
                  description="The major features of talent management include recruitment, onboarding, learning and development, performance feedback, succession planning, and employee engagement."
                />
                <FAQAccordion
                  state={accordion05}
                  onClick={() => setAccordion05(!accordion05)}
                  title="What are some powerful talent management strategies?"
                  description="Talent management strategies include encouraging a culture of continuous learning, offering personalized development plans, conducting regular performance evaluations, and providing opportunities for career advancement."
                />
              </div>
            </div>

            {/* STOCK IMAGE */}
            <div className="col-span-1">
              <img
                src={`${GCP_BUCKET_URL}/faqs-banner.webp`}
                className="w-full h-[30vh] xl:h-faqBanner lg:h-faqBanner object-cover rounded-xl shadow-md"
                alt="Frequently Asked Questions"
              />
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALL TO ACTION */}
      <CallToAction />

      {/* CALENDLY MODAL */}
      <PopupModal
        open={calendlyModal}
        onModalClose={() => setCalendlyModal(!calendlyModal)}
        rootElement={document.getElementById("root")}
        url="https://calendly.com/contact-ucp8/30min"
      />
    </motion.section>
  );
};

export default Homepage;
