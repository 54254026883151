import { useState } from "react";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer } from "react-toastify";
import { PopupModal } from "react-calendly";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* ANIMATION */
import { animation, containerAnimation } from "config";

/* USER DEFINED COMPONENTS */
import {
  FrequentlyAskedQuestions,
  CallToAction,
  CounterComponent,
} from "components/homepage";

/* CONFIG */
import { GCP_BUCKET_URL } from "config";

const AboutUs = () => {
  const [calendlyModal, setCalendlyModal] = useState(false);

  /* PRODUCTIVITY CARD */
  const PerformanceCard = (props) => {
    return (
      <div className="bg-white border border-greyBorder rounded-lg shadow-md p-8">
        <img src={props.image} className="w-16 h-16" alt={props.title} />
        <div className="mt-4">
          <h5 className="text-xl font-proxima-bold text-black">
            {props.title}
          </h5>
          <p className="text-base font-proxima-regular text-greyText mt-1">
            {props.description}
          </p>
        </div>
      </div>
    );
  };

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* BANNER */}
      <motion.div variants={animation} className="py-5 xl:py-14 lg:py-14">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-20">
            <div className="col-span-1">
              <div className="w-full">
                <h1 className="text-3xl xl:text-4xl lg:text-4xl font-tight-bold text-black leading-snug">
                  Empowering Organizations Through Engagement and Goal-setting
                </h1>
                <h2 className="mt-5 text-lg xl:text-xl lg:text-xl font-proxima-regular text-greyText tracking-tight">
                  At Aligned Rewards, we are committed to transforming the way
                  organizations boost productivity and engage employees. We
                  believe that every person in a company, regardless of their
                  role, is essential to its success.
                </h2>
                <div className="mt-5 xl:mt-8 lg:mt-8">
                  <div className="flex items-center gap-3">
                    <Button
                      className="shadow-none hover:shadow-none rounded-lg bg-brandColor normal-case text-base font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                      onClick={() => setCalendlyModal(!calendlyModal)}
                    >
                      Schedule a Demo
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0">
              <img
                src={`${GCP_BUCKET_URL}/about-us-banner.png`}
                className="w-full h-auto"
                alt="About Aligned Rewards"
              />
            </div>
          </div>
        </div>
      </motion.div>

      {/* COUNTERS */}
      <motion.div variants={animation} className="mt-5 xl:mt-10 lg:mt-10">
        <div className="bg-footer bg-opacity-5 py-10">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-10 xl:gap-20 lg:gap-20">
              <div className="col-span-1">
                <CounterComponent
                  title="Enhance Performance Tracking "
                  description="Efficiently monitor employee performance with dynamic inbuilt tools, allowing accurate assessment and targeted feedback for continual improvement. "
                />
              </div>
              <div className="col-span-1">
                <CounterComponent
                  title="Reduce Administrative Burden"
                  description="Organize onboarding processes to reduce administrative workload by 30%, allowing for greater focus on strategic management initiatives."
                />
              </div>
              <div className="col-span-1">
                <CounterComponent
                  title="Amplify Task Organization"
                  description="Simplify task management processes for intensified productivity and collaboration, ensuring clear directives and skillful task completion."
                />
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* INSPIRATION */}
      <motion.div variants={animation} className="py-5 xl:py-14 lg:py-14">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-5 xl:gap-10 lg:gap-10 items-center">
            <div className="col-span-1 flex items-center justify-center">
              <img
                src={`${GCP_BUCKET_URL}/about-us/about-inspiration.webp`}
                className="w-full h-[35vh] rounded-lg object-cover"
                alt="What inspired us?"
              />
            </div>
            <div className="col-span-1 flex items-center justify-center">
              <div className="w-full xl:w-11/12 lg:w-11/12">
                <h2 className="text-2xl xl:text-3xl lg:text-3xl font-tight-bold text-black leading-snug">
                  What inspired us?
                </h2>
                <h5 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText mt-3">
                  We know it's hard whe­n people don't fee­l excited about their
                  jobs. That's why our te­am took what we've learne­d about how
                  people act at work. We wante­d to make something to help
                  e­veryone fee­l involved, strong, and great in their company.
                  Our de­ep knowledge of the workplace­ feelings and our wish
                  for good business cultures guided us.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* GOOD WORKPLACE */}
      <motion.div
        variants={animation}
        className="py-5 xl:py-14 lg:py-14 bg-brandColor bg-opacity-5"
      >
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-5 xl:gap-10 lg:gap-10 items-center">
            <div className="col-span-1">
              <div className="w-full xl:w-11/12 lg:w-11/12">
                <h2 className="text-2xl xl:text-3xl lg:text-3xl font-tight-bold text-black leading-snug">
                  What do we envision?
                </h2>
                <h5 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText mt-3">
                  Aligned Re­wards envisions a workplace where e­veryone can be
                  the­ir best self. We work hard to build a conne­cted place
                  using the principles of positive­ thinking. By working
                  together, appreciating e­ach other, and growing personally, we
                  be­lieve organizations can use e­veryone's talents and
                  achie­ve amazing success.
                </h5>
              </div>
            </div>
            <div className="col-span-1 flex items-center justify-center">
              <img
                src={`${GCP_BUCKET_URL}/about-us/about-good-workplace.webp`}
                className="w-full h-[35vh] rounded-lg object-cover"
                alt="What do we envision?"
              />
            </div>
          </div>
        </div>
      </motion.div>

      {/* ETHICS AND VALUES */}
      <motion.div variants={animation} className="py-5 xl:py-14 lg:py-14">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-5 xl:gap-10 lg:gap-10 items-center">
            <div className="col-span-1 flex items-center justify-center">
              <img
                src={`${GCP_BUCKET_URL}/about-us/about-ethics.webp`}
                className="w-full h-[35vh] rounded-lg object-cover"
                alt="How Do We Uphold our Ethics at Aligned Rewards"
              />
            </div>
            <div className="col-span-1 flex items-center justify-center">
              <div className="w-full xl:w-11/12 lg:w-11/12">
                <h2 className="text-2xl xl:text-3xl lg:text-3xl font-tight-bold text-black leading-snug">
                  How do we uphold our ethics at Aligned Rewards
                </h2>
                <h5 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText mt-3">
                  Ethics and values are at the heart of everything we do at
                  Aligned Rewards. Our core values are deeply rooted in the
                  principles of employee empowerment, transparency, and
                  integrity. We are committed to upholding the highest standards
                  of ethical conduct in all aspects of our business, from
                  product development to customer relations.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* INNOVATION */}
      <motion.div
        variants={animation}
        className="py-5 xl:py-14 lg:py-14 bg-brandColor bg-opacity-5"
      >
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-5 xl:gap-10 lg:gap-10 items-center">
            <div className="col-span-1">
              <div className="w-full xl:w-11/12 lg:w-11/12">
                <h2 className="text-2xl xl:text-3xl lg:text-3xl font-tight-bold text-black leading-snug">
                  Who drives our innovation? Our Skilled Team!
                </h2>
                <h5 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText mt-3">
                  Behind Aligned Rewards is a dedicated team driven by a shared
                  goal to transform organizational management. Our exceptional
                  team's values and uncompromising commitment have been crucial
                  in establishing the foundation for our innovative employee
                  management tool. Together, we are dedicated to creating a tool
                  that will not only streamline operations but also cultivate a
                  culture of excellence, collaboration, and continuous growth
                  within organizations.
                </h5>
              </div>
            </div>
            <div className="col-span-1 flex items-center justify-center">
              <img
                src={`${GCP_BUCKET_URL}/about-us/about-innovation.webp`}
                className="w-full h-[35vh] rounded-lg object-cover"
                alt="What do we envision?"
              />
            </div>
          </div>
        </div>
      </motion.div>

      {/* OPTIONS */}
      <motion.div variants={animation} className="py-5 xl:py-14 lg:py-14">
        <div className="container mx-auto">
          {/* HEADING */}
          <div className="w-full xl:w-1/2 lg:w-1/2">
            <h5 className="text-base font-proxima-semibold text-brandColor uppercase">
              ALIGNED REWARDS
            </h5>
            <h2 className="text-2xl xl:text-4xl lg:text-4xl font-tight-bold text-black leading-snug mt-2">
              Boost Team Performance using our 360° Performance Feedback
              Software
            </h2>
          </div>

          {/* CONTENT */}
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5 xl:gap-10 lg:gap-10 mt-5 xl:mt-10 lg:mt-10">
            <div className="col-span-1">
              <PerformanceCard
                image="https://storage.googleapis.com/cdn-roundtechsquare/aligned-rewards/icons/boost-productivity.png"
                title="Boost Productivity"
                description="Maximize efficiency and output to achieve greater productivity in your work."
              />
            </div>
            <div className="col-span-1">
              <PerformanceCard
                image="https://storage.googleapis.com/cdn-roundtechsquare/aligned-rewards/icons/employee-engagement.png"
                title="Employee Engagement"
                description="Foster involvement, satisfaction, and commitment among employees for a thriving workplace culture."
              />
            </div>
            <div className="col-span-1">
              <PerformanceCard
                image="https://storage.googleapis.com/cdn-roundtechsquare/aligned-rewards/icons/business-excellence.png"
                title="Business Excellence"
                description="Attain superior standards and performance, ensuring success and growth in your business endeavors."
              />
            </div>
          </div>
        </div>
      </motion.div>

      {/* FREQUENTLY ASKED QUESTIONS */}
      <FrequentlyAskedQuestions />

      {/* CALL TO ACTION */}
      <CallToAction />

      {/* CALENDLY MODAL */}
      <PopupModal
        open={calendlyModal}
        onModalClose={() => setCalendlyModal(!calendlyModal)}
        rootElement={document.getElementById("root")}
        url="https://calendly.com/contact-ucp8/30min"
      />
    </motion.section>
  );
};

export default AboutUs;
