import React, { useState } from "react";
import { Link } from "react-router-dom";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import { PopupModal } from "react-calendly";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* CONFIG */
import { animation, containerAnimation } from "config";

/* ICONS */
import { MdOutlineArrowOutward } from "react-icons/md";
import { BsCalendar2DateFill } from "react-icons/bs";

/* FORM VALIDATIONS */
import * as yup from "yup";
import { useFormik } from "formik";

/* API */
import { newsletterAPI } from "service/api";

/* VALIDATION SCHEMA */
const waitlistValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .min(1, "Email Address is required")
    .required("Email Address is required."),
});

/* TAGS */
const tags = [
  {
    id: 1,
    name: "Task Management",
    link: "/blogs/task-management",
  },
  {
    id: 2,
    name: "Employee Engagement",
    link: "/blogs/employee-engagement",
  },
  {
    id: 3,
    name: "Performance Management",
    link: "/blogs/performance-management",
  },
  {
    id: 4,
    name: "Change Management",
    link: "/blogs/change-management",
  },
  {
    id: 5,
    name: "OKR Management",
    link: "/blogs/okr-management",
  },
  {
    id: 6,
    name: "Project Planning",
    link: "/blogs/project-planning",
  },
  {
    id: 7,
    name: "Project Management",
    link: "/blogs/project-management",
  },
  {
    id: 8,
    name: "Team collaboration",
    link: "/blogs/team-collaboration",
  },
];

const LeadershipQualitites = () => {
  const [calendlyModal, setCalendlyModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  /* FORM HANDLING */
  const formHandling = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: waitlistValidation,
    onSubmit: (values) => {
      const obj = {
        email: values.email,
      };
      setFormLoading(true);
      newsletterAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(
              "Congratulations! 🎉 You have subscribed to our newsletter."
            );
          } else {
            toast.error(
              "We were not able to capture your details. Please try again."
            );
          }
          setFormLoading(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formHandling.resetForm();
          setFormLoading(false);
        });
    },
  });

  /* SMALL TAGS BUTTON */
  const SmallTagsButton = (props) => {
    return (
      <Link to={props.link}>
        <Button className="shadow-none hover:shadow-none bg-greyBg hover:bg-logoOrange rounded-md text-sm font-proxima-regular text-greyText hover:text-white normal-case font-medium px-4 py-2 transition-all duration-300 ease-in-out">
          {props.label}
        </Button>
      </Link>
    );
  };

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* CONTENT */}
      <motion.div variants={animation} className="py-5 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
            {/* CONTENT */}
            <div className="col-span-2 border-r-0 xl:border-r lg:border-r border-greyBorder">
              <div className="pr-0 xl:pr-8 lg:pr-8">
                <h1 className="mt-5 text-xl xl:text-3xl lg:text-3xl font-tight-bold text-black leading-snug">
                  7 Top Leadership Qualities for Modern Managers
                </h1>

                <div className="mt-5">
                  <p className="mt-3 text-base font-proxima-regular text-greyText leading-relaxed">
                    Today’s managers are required to deliver results for an
                    organization while also motivating their subordinates,
                    leading through organizational change, and creating a
                    healthy organizational climate. For one to be classified in
                    this category, he or she has to display certain qualities
                    that make this leader unique. In this article, let's talk
                    about what we consider to be seven key leadership skills
                    that managers require in the contemporary workplace.
                  </p>

                  <div className="mt-5">
                    <h2 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      1. Visionary Thinking
                    </h2>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Today's managers must be able to self-organize their work
                      while having a clear understanding of the team's and the
                      organization's future orientation. Strategic thinking is a
                      planning process that includes creating an organization's
                      long-term vision and determining its long-term
                      possibilities and problems. A visionary leader can inspire
                      others to join them in achieving an objective by acting at
                      a high level.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      As an illustration, Elon Musk, the CEO of SpaceX and Tesla
                      at the moment, is regarded as one of the generation's
                      visionary leaders. His teams are inspired to make
                      exceptional progress in the fields of space research and
                      renewable energy by his audacious and imaginative goals.
                    </p>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-semibold text-black">
                        How can one cultivate this attribute?
                      </h5>
                      <ol className="mt-3 ml-8 list-decimal">
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Set aside time on a regular basis to plan and think
                          critically and deliberately.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Make sure everyone on your team understands your
                          vision, and talk about it frequently.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Encourage creativity and resist the need to follow
                          traditional methods that might not support your goals.
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div className="mt-10">
                    <h2 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      2. Emotional Intelligence
                    </h2>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      The ability to control one's own emotions and recognize
                      both one's own and other people's sentiments is known as
                      emotional intelligence. Modern managers with high
                      emotional intelligence are better at communicating and
                      handling interpersonal conflict, and they also exhibit an
                      environment in the workplace that is more productive. They
                      are aware of their own emotions, perceive their coworkers'
                      feelings, and observe how the team interacts with one
                      another.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Example: Satya Nadella, the current CEO of Microsoft, has
                      an extremely high EE rating. Furthermore, Gates has pushed
                      Microsoft to continue operating under its inclusive and
                      compassionate culture, which promotes collaboration and,
                      ultimately, creativity.
                    </p>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-semibold text-black">
                        How can one develop this quality?
                      </h5>
                      <ol className="mt-3 ml-8 list-decimal">
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Make sure you pay close attention and are receptive to
                          the opinions of your team.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Evaluate your own emotions and the ways in which they
                          are influencing the decisions you make.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Develop your empathy by placing yourself in their
                          shoes.
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div className="mt-10">
                    <h2 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      3. Adaptability
                    </h2>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      The dynamic nature of the corporate world means that
                      managers must always be prepared for new problems. A key
                      component of adaptability is flexibility, or the capacity
                      to change directions as necessary. This is due to the fact
                      that industry-related companies require knowledge of
                      market trends and a readiness to accept and adjust to
                      change.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Example: During the COVID-19 lockdown, businesses all
                      around the world were compelled to accept work-from-home
                      policies. Leaders who were frank and effective were those
                      who were willing to embrace change and were able to manage
                      their staff remotely.
                    </p>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-semibold text-black">
                        How can one develop this quality?
                      </h5>
                      <ol className="mt-3 ml-8 list-decimal">
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Keep up with industry developments and any new
                          technologies that are being created.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          To be more precise, they ought to be prepared to hear
                          the criticism and, if necessary, make adjustments.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Encourage others and yourself to be tenacious and
                          optimistic and to see every challenge as an
                          opportunity to improve.
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div className="mt-10">
                    <h2 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      4. Decisiveness
                    </h2>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      It is required of managers today to make accurate
                      decisions in circumstances with elevated or even
                      unforeseen risks. Information gathering, choice appraisal,
                      and decision-making without reservation are all necessary
                      components of decision-making. It involves, for example,
                      taking accountability for one's decisions and refraining
                      from making the same errors twice.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      As an illustration, consider Amazon's founder, Jeff Bezos,
                      who is well-known for exercising strong leadership. Bezos
                      has demonstrated by his deeds and decisions that he is
                      more than prepared to take calculated chances and make
                      audacious choices in order to support Amazon's expansion
                      and success.
                    </p>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-semibold text-black">
                        How can one develop this quality?
                      </h5>
                      <ol className="mt-3 ml-8 list-decimal">
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Create a decision model or framework to evaluate the
                          possibilities and outcomes of various decisions.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Don't overthink your choices; instead, have faith in
                          your judgment.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Always attempt to use your best judgment when making a
                          decision so that the following time you can do it with
                          confidence.
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div className="mt-10">
                    <h2 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      5. Communication Skills
                    </h2>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Effective communication is a crucial element in achieving
                      leadership objectives. Managers of today must interact
                      with their coworkers and subordinates, communicate, and
                      voice their opinions. Resolving conflicts and ensuring
                      that all parties are on board with an organization's
                      objectives are made easier with the help of credible
                      communication skills.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      For instance, Sheryl Sandberg, the COO of Facebook, is
                      renowned for her excellent communication skills. She is
                      compassionate and straightforward. She demonstrated
                      leadership qualities by being able to relate to the staff
                      members and by painting a clear image for them.
                    </p>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-semibold text-black">
                        How do you develop this quality?
                      </h5>
                      <ol className="mt-3 ml-8 list-decimal">
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Make sure that all messages are clear and concise and
                          that you communicate with all parties in a
                          professional manner.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Encourage openness and the provision of options for
                          team members to provide feedback.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Adapt your speaking style to your audience's demands
                          and the situation by keeping an eye on them.
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div className="mt-10">
                    <h2 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      6. Self-determination
                    </h2>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Delegating long-term duty and commitment, having
                      sufficient tools, and having decision-making authority are
                      all components of employee engagement. In the modern
                      workplace, managers aim to delegate decision-making in
                      order to foster creativity, motivation, and trust. An
                      employee who feels empowered will undoubtedly take on
                      greater responsibility for the company's success.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      As an illustration, consider Virgin Group owner Richard
                      Branson, who employs an empowering leadership style.
                      Virgin has developed an innovative culture because he
                      grants his staff members freedom and support for the ideas
                      they want to explore.
                    </p>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-semibold text-black">
                        How do you develop this quality?
                      </h5>
                      <ol className="mt-3 ml-8 list-decimal">
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Assign employees particular tasks and responsibilities
                          in a way that best utilizes their skills.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          To help the employees reach their objectives, all that
                          is needed is to provide the resources and motivation
                          they need.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Success on both an individual and a group level should
                          be rewarded.
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div className="mt-10">
                    <h2 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      7. Integrity
                    </h2>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Since honesty is the foundation of trust, it is a wise
                      place for a leader to start. Modern managers should uphold
                      the aforementioned values in all of their dealings and
                      strive to be morally and honestly upright. This quality
                      instills strong moral standards in the workforce, which
                      improves the company's overall responsibility and respect.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Mary Barra, the esteemed executive of General Motors, is
                      an example of someone with integrity. She has proven her
                      dedication to moral leadership and openness, which has
                      raised the degree of trust among staff members and other
                      stakeholders in the company.
                    </p>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-semibold text-black">
                        How do you develop this quality?
                      </h5>
                      <ol className="mt-3 ml-8 list-decimal">
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Be honest in your communication and behave with
                          integrity.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Be morally upright and accountable for the choices you
                          make.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Establish the tone and contribute to the development
                          of a positive organizational culture within your
                          business.
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div className="mt-10">
                    <h2 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Conclusion
                    </h2>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      For this reason, managers in the varied workplace of today
                      must have these seven traits of contemporary leadership.
                      Effective team leadership and organizational performance
                      require a variety of competencies, including high
                      emotional intelligence, flexibility and stability,
                      decision-making prowess, interpersonal skills, delegation,
                      and honesty. Therefore, in order to surpass leadership
                      criteria, modern managers might cultivate and hone these
                      attributes.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      By concentrating on these qualities, individuals who wish
                      to advance to a managerial role or improve their
                      managerial abilities can lay a solid foundation for
                      leadership in the modern world.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* SIDEBAR */}
            <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0">
              <div className="pl-0 xl:pl-8 lg:pl-8  h-fit sticky top-24">
                {/* NEWSLETTER */}
                <motion.div
                  variants={animation}
                  className="pb-8 border-b border-greyBorder"
                >
                  <div
                    variants={animation}
                    className="bg-greyBg bg-opacity-50 border border-greyBorder rounded-lg p-5 xl:p-8 lg:p-8"
                  >
                    <h5 className="text-2xl font-tight-bold">
                      Join Our Community
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3">
                      Join us so that we can send you updates on new features,
                      valuable tips, expert strategies and our exclusive offers
                      delivered directly to your inbox.
                    </p>
                    <form className="mt-5" onSubmit={formHandling.handleSubmit}>
                      <input
                        type="email"
                        placeholder="Type you email address"
                        name="newsletterEmail"
                        id="newsletterEmail"
                        value={formHandling.values.email}
                        onChange={formHandling.handleChange}
                        className={`w-full block bg-white px-4 py-3 rounded-lg text-sm font-proxima-semibold text-black border ${
                          formHandling.errors.email &&
                          formHandling.touched.email
                            ? "border-red-500"
                            : "border-greyBorder"
                        } border-greyBorder focus:outline-brandColor placeholder:font-proxima-regular`}
                      />
                      <Button
                        disabled={formLoading}
                        type={formLoading ? "button" : "submit"}
                        className="group mt-3 w-full flex items-center gap-1 justify-center shadow-none hover:shadow-none font-proxima-semibold text-white rounded-md bg-brandColor normal-case text-sm hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                      >
                        {formLoading ? "Please wait.." : "Subscribe"}
                        <MdOutlineArrowOutward className="w-4 h-4 text-white" />
                      </Button>
                    </form>
                  </div>
                </motion.div>

                {/* Schedule a Demo */}
                <motion.div variants={animation} className="pt-8">
                  <BsCalendar2DateFill className="w-10 h-10 text-black" />
                  <h5 className="mt-3 text-2xl font-tight-bold leading-snug">
                    Learn how Aligned Rewards can be useful for your
                    organization
                  </h5>
                  <p className="mt-3 text-base font-proxima-regular text-greyText">
                    Experience a guided walkthrough of all our features in this
                    exclusive video!
                  </p>
                  <Button
                    className="mt-5 shadow-none hover:shadow-none bg-brandColor text-white font-proxima-semibold text-sm normal-case"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </motion.div>

                {/* TAGS */}
                <motion.div variants={animation} className="pt-8 hidden">
                  <h5 className="text-xl xl:text-2xl lg:text-2xl font-tight-bold text-black tracking-tight">
                    Tags
                  </h5>
                  <div className="flex items-center flex-wrap gap-2 mt-5">
                    {tags.map((item) => (
                      <React.Fragment key={item.id}>
                        <SmallTagsButton label={item.name} link={item.link} />
                      </React.Fragment>
                    ))}
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALENDLY MODAL */}
      <PopupModal
        open={calendlyModal}
        onModalClose={() => setCalendlyModal(!calendlyModal)}
        rootElement={document.getElementById("root")}
        url="https://calendly.com/contact-ucp8/30min"
      />
    </motion.section>
  );
};

export default LeadershipQualitites;
