const pricingPlans = [
  {
    id: 1,
    name: "Standard",
    price: "2.99",
    features: [
      {
        id: 1,
        title: "Performance Appraisal",
      },
      {
        id: 2,
        title: "Real-Time Feedback",
      },
      {
        id: 3,
        title: "Cascading Goals",
      },
      {
        id: 4,
        title: "Talent Card",
      },
      {
        id: 5,
        title: "Surveys",
      },
      {
        id: 6,
        title: "Check-Ins",
      },
    ],
  },
  {
    id: 2,
    name: "Professional",
    price: "5.99",
    features: [
      {
        id: 1,
        title: "Performance Appraisal",
      },
      {
        id: 2,
        title: "Real-Time Feedback",
      },
      {
        id: 3,
        title: "Cascading Goals",
      },
      {
        id: 4,
        title: "Talent Card",
      },
      {
        id: 5,
        title: "Surveys",
      },
      {
        id: 6,
        title: "Check-Ins",
      },
      {
        id: 7,
        title: "Engagement and Rewards",
      },
    ],
  },
  {
    id: 3,
    name: "Pro Premium",
    price: "9.99",
    features: [
      {
        id: 1,
        title: "Performance Appraisal",
      },
      {
        id: 2,
        title: "Real-Time Feedback",
      },
      {
        id: 3,
        title: "Cascading Goals",
      },
      {
        id: 4,
        title: "Talent Card",
      },
      {
        id: 5,
        title: "Surveys",
      },
      {
        id: 6,
        title: "Check-Ins",
      },
      {
        id: 7,
        title: "Engagement and Rewards",
      },
      {
        id: 8,
        title: "Employee Surveys",
      },
      {
        id: 9,
        title: "Gamification Leaderboard",
      },
    ],
  },
];

export default pricingPlans;
