/* CONTAINER ANIMATION */
const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.12, delayChildren: 1 },
  },
};

/* DIV ANIMATION */
const animation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      type: "spring",
      damping: 12,
      delay: 1,
      stiffness: 100,
    },
  },
};

const GCP_BUCKET_URL =
  "https://storage.googleapis.com/cdn-roundtechsquare/aligned-rewards";

const SERVER_URL =
  "https://aligned-rewards-backend-nodejs-5yi35klcoa-uc.a.run.app/org/user";

export { containerAnimation, animation, GCP_BUCKET_URL, SERVER_URL };
