/* PACKAGES */
import { motion } from "framer-motion";

/* CONFIG */
import { containerAnimation, animation } from "config";

const PrivacyPolicy = () => {
  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* CONTENT */}
      <motion.div variants={animation} className="py-5 xl:py-14 lg:py-14">
        <div className="container mx-auto">
          <p className="text-base font-proxima-regular text-greyText leading-relaxed">
            At Aligned Rewards, accessible from{" "}
            <a
              href="www.alignedrewards.com"
              target="_blank"
              className="text-logo"
            >
              www.alignedrewards.com
            </a>{" "}
            by Roundtechsquare LLC, one of our main priorities is the privacy of
            our visitors. This Privacy Policy document contains types of
            information that is collected and recorded by Aligned Rewards and
            how we use it.
          </p>
          <p className="mt-2 text-base font-proxima-regular text-greyText leading-relaxed">
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </p>

          <ol className="ml-8 mt-5 list-decimal">
            <li className="text-base font-proxima-regular text-greyText leading-relaxed first:mt-0 mt-4">
              When you register with Aligned Rewards, information is needed such
              as your name, company name, email address.
            </li>
            <li className="text-base font-proxima-regular text-greyText leading-relaxed first:mt-0 mt-4">
              Aligned Rewards uses collected information for processing your
              order, billing and service improvement.
            </li>
            <li className="text-base font-proxima-regular text-greyText leading-relaxed first:mt-0 mt-4">
              Aligned Rewards will not sell, rent, exchange or share your
              personal information with any third parties without your
              permission.
            </li>
            <li className="text-base font-proxima-regular text-greyText leading-relaxed first:mt-0 mt-4">
              Aligned Rewards may share the collected information in order to
              (i) respond to investigations, court orders or legal process, (ii)
              to investigate, prevent or take action regarding illegal
              activities, suspected fraud, potential threats to the physical
              safety of any person, (iii) violations of Aligned Reward's terms
              of service, or (iv) as otherwise required by law.
              <ul className="ml-5 mt-4 list-disc">
                <li className="text-base font-proxima-regular text-greyText leading-relaxed first:mt-0 mt-3">
                  <span className="text-black font-proxima-semibold">
                    Data Retention:
                  </span>{" "}
                  We will retain your information for as long as your account is
                  active or as needed to provide you services. We will retain
                  and use your information as necessary to comply with our legal
                  obligations, resolve disputes, and enforce our agreements.
                </li>
                <li className="text-base font-proxima-regular text-greyText leading-relaxed first:mt-0 mt-3">
                  <span className="text-black font-proxima-semibold">
                    Accessing and Modifying your information:
                  </span>{" "}
                  Individuals wishing to view, update, delete or suplement their
                  personal data may do so by contacting us.
                </li>
              </ul>
            </li>
            <li className="text-base font-proxima-regular text-greyText leading-relaxed first:mt-0 mt-4">
              <span className="text-black font-proxima-semibold">
                How We Protect Your Information:
              </span>{" "}
              We strive to maintain commercially reasonable; physical,
              electronic, and procedural safeguards to secure the Information
              from loss, misuse and unauthorized access, disclosure, alteration,
              and destruction. Please keep in mind, however, that there is no
              way to make the transmission of electronic data entirely
              hack-proof.
            </li>
            <li className="text-base font-proxima-regular text-greyText leading-relaxed first:mt-0 mt-4">
              Aligned Rewards will send you a welcome email, as well as emails
              regarding relevant notifications. Administrators (Someone on the
              client side who manages all user management activities of their
              organization in Aligned Rewards) will at times receive Aligned
              Rewards service related announcements. All emails can be disabled
              by the recipient in their settings page.
            </li>
            <li className="text-base font-proxima-regular text-greyText leading-relaxed first:mt-0 mt-4">
              We collect statistical information about how both users and
              administrators collectively use the Services (“Aggregate
              Information”). Some of this information is derived from Personal
              Information. This statistical information is not Personal
              Information and cannot be tied back to you or your web browser.
            </li>
            <li className="text-base font-proxima-regular text-greyText leading-relaxed first:mt-0 mt-4">
              <span className="text-black font-proxima-semibold">
                Google Limited Use Policy
              </span>{" "}
              Aligned Rewards' use and transfer to any other app of information
              received from Google APIs will adhere to{" "}
              <a
                href="https://developers.google.com/terms/api-services-user-data-policy"
                target="_blank"
                rel="noreferrer"
                className="text-blue-500 underline"
              >
                Google API Services User Data Policy
              </a>
              , including the Limited Use requirements.”
            </li>
          </ol>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black">
              Compliance with Google API Terms of Service and Google User Data
              Policy
            </h5>
            <ol className="ml-8 mt-5 list-decimal">
              <li className="text-base font-proxima-regular text-greyText leading-relaxed first:mt-0 mt-4">
                <span className="text-black font-proxima-semibold">
                  Transparency:
                </span>{" "}
                Aligned Rewards will always ask for both the minimum scope and
                the minimum amount of data that is strictly necessary for
                providing our services. Aligned Rewards offers customers the
                ability to integrate with their Google Calendar. This
                integration will give users the ability to read, create, and
                update Google calendar items within the Aligned Rewards
                application. Our application and website do not share any data
                with third-party tools or AI models. The URL for this Privacy
                Policy is included in the OAuth client configuration for all
                Aligned Rewards applications that interact with Google User
                Data. Any changes to Aligned Rewards’s use of Google User Data
                will be added to this Privacy Policy and will require fresh user
                consent to this Policy.
              </li>
              <li className="text-base font-proxima-regular text-greyText leading-relaxed first:mt-0 mt-4">
                <span className="text-black font-proxima-semibold">
                  Relevant Permissions:
                </span>{" "}
                Integrating your Google Calendar with the Aligned Rewards
                application will grant the following permissions to Aligned
                Rewards:{" "}
                <span className="text-black font-proxima-semibold">
                  Read calendar items, Create calendar items and Update calendar
                  items
                </span>
              </li>
              <li className="text-base font-proxima-regular text-greyText leading-relaxed first:mt-0 mt-4">
                <span className="text-black font-proxima-semibold">
                  Allowed Use:
                </span>{" "}
                Aligned Rewards will only use restricted scope data to provide
                or improve user-facing features that are prominent from Aligned
                Reward's application.
              </li>
              <li className="text-base font-proxima-regular text-greyText leading-relaxed first:mt-0 mt-4">
                <span className="text-black font-proxima-semibold">
                  Allowed Transfer:
                </span>{" "}
                Aligned Rewards will only transfer restricted scope data to
                others if that transfer is (a) necessary to provide or improve
                user-facing features that are prominent from the requesting
                app’s user interface, (b) to comply with applicable laws, or (c)
                a part of a merger, acquisition or sale of assets of the
                developer.
              </li>
              <li className="text-base font-proxima-regular text-greyText leading-relaxed first:mt-0 mt-4">
                <span className="text-black font-proxima-semibold">
                  Advertising:
                </span>{" "}
                Aligned Rewards will never use or transfer restricted scope data
                to serve users advertisements. This includes personalized,
                re-targeted and interest-based advertising.
              </li>
              <li className="text-base font-proxima-regular text-greyText leading-relaxed first:mt-0 mt-4">
                <span className="text-black font-proxima-semibold">
                  Human Interaction:
                </span>{" "}
                Aligned Rewards does not allow developers or users to read
                restricted scope user data, including email, Google Drive, or
                Google Calendar information.
              </li>
            </ol>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black">Log Files</h5>
            <p className="mt-2 text-base font-proxima-regular text-greyText leading-relaxed">
              Aligned Rewards follows a standard procedure of using log files.
              These files log visitors when they visit websites. All hosting
              companies do this and a part of hosting services' analytics. The
              information collected by log files include internet protocol (IP)
              addresses, browser type, Internet Service Provider (ISP), date and
              time stamp, referring/exit pages, and possibly the number of
              clicks. These are not linked to any information that is personally
              identifiable. The purpose of the information is for analyzing
              trends, administering the site, tracking users' movement on the
              website, and gathering demographic information.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black">
              Children's Privacy
            </h5>
            <p className="mt-2 text-base font-proxima-regular text-greyText leading-relaxed">
              Our service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If you are a parent or guardian and you are
              aware that your child has provided us with Personal data, please
              contact us. If we become aware that we have collected personal
              data from anyone under the age of 13 without verification of
              parental consent, we take steps to remove that information from
              our servers.
            </p>
            <p className="mt-2 text-base font-proxima-regular text-greyText leading-relaxed">
              If we need to rely on consent as a legal basis for processing your
              information and your country requires consent from a parent, We
              may require parent's consent before we collect and use that
              information.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black">
              Links to Other Websites
            </h5>
            <p className="mt-2 text-base font-proxima-regular text-greyText leading-relaxed">
              Our service may contain links to other websites that are not
              operated by us. If You click on a third party link, you will be
              directed to that third party's site. We strongly advise you to
              review the privacy policy of every site you visit.
            </p>
            <p className="mt-2 text-base font-proxima-regular text-greyText leading-relaxed">
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black">
              Changes to this Privacy Policy
            </h5>
            <p className="mt-2 text-base font-proxima-regular text-greyText leading-relaxed">
              We may update our privacy policy from time to time. We will notify
              you of any changes by posting the new privacy policy on this page.
            </p>
            <p className="mt-2 text-base font-proxima-regular text-greyText leading-relaxed">
              You are advised to review this privacy policy periodically for any
              changes. Changes to this policy are effective when they are posted
              on this page.
            </p>
          </div>

          <div className="mt-5">
            <h5 className="text-xl font-proxima-bold text-black">Contact Us</h5>
            <p className="mt-2 text-base font-proxima-regular text-greyText leading-relaxed">
              If you have any questions about this Privacy Policy, You can
              contact us:
            </p>
            <p className="mt-2 text-base font-proxima-regular text-greyText leading-relaxed">
              By email:{" "}
              <a
                href="malto:contact@alignedrewards.com"
                target="_blank"
                className="text-brandColor"
                rel="noreferrer"
              >
                contact@alignedrewards.com
              </a>
            </p>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default PrivacyPolicy;
