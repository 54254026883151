import React, { useState } from "react";
import { Link } from "react-router-dom";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import { PopupModal } from "react-calendly";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* CONFIG */
import { animation, containerAnimation } from "config";

/* ICONS */
import { MdOutlineArrowOutward } from "react-icons/md";
import { BsCalendar2DateFill } from "react-icons/bs";

/* FORM VALIDATIONS */
import * as yup from "yup";
import { useFormik } from "formik";

/* API */
import { newsletterAPI } from "service/api";

/* VALIDATION SCHEMA */
const waitlistValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .min(1, "Email Address is required")
    .required("Email Address is required."),
});

/* TAGS */
const tags = [
  {
    id: 1,
    name: "Task Management",
    link: "/blogs/task-management",
  },
  {
    id: 2,
    name: "Employee Engagement",
    link: "/blogs/employee-engagement",
  },
  {
    id: 3,
    name: "Performance Management",
    link: "/blogs/performance-management",
  },
  {
    id: 4,
    name: "Change Management",
    link: "/blogs/change-management",
  },
  {
    id: 5,
    name: "OKR Management",
    link: "/blogs/okr-management",
  },
  {
    id: 6,
    name: "Project Planning",
    link: "/blogs/project-planning",
  },
  {
    id: 7,
    name: "Project Management",
    link: "/blogs/project-management",
  },
  {
    id: 8,
    name: "Team collaboration",
    link: "/blogs/team-collaboration",
  },
];

const EmployeeEngagementTwo = () => {
  const [calendlyModal, setCalendlyModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  /* FORM HANDLING */
  const formHandling = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: waitlistValidation,
    onSubmit: (values) => {
      const obj = {
        email: values.email,
      };
      setFormLoading(true);
      newsletterAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(
              "Congratulations! 🎉 You have subscribed to our newsletter."
            );
          } else {
            toast.error(
              "We were not able to capture your details. Please try again."
            );
          }
          setFormLoading(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formHandling.resetForm();
          setFormLoading(false);
        });
    },
  });

  /* SMALL TAGS BUTTON */
  const SmallTagsButton = (props) => {
    return (
      <Link to={props.link}>
        <Button className="shadow-none hover:shadow-none bg-greyBg hover:bg-logoOrange rounded-md text-sm font-proxima-regular text-greyText hover:text-white normal-case font-medium px-4 py-2 transition-all duration-300 ease-in-out">
          {props.label}
        </Button>
      </Link>
    );
  };

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* CONTENT */}
      <motion.div variants={animation} className="py-5 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
            {/* CONTENT */}
            <div className="col-span-2 border-r-0 xl:border-r lg:border-r border-greyBorder">
              <div className="pr-0 xl:pr-8 lg:pr-8">
                <h1 className="mt-5 text-xl xl:text-3xl lg:text-3xl font-tight-bold text-black leading-snug">
                  8 Key Elements of Employee Engagement for Workplace Motivation
                </h1>

                <div className="mt-5">
                  <p className="mt-3 text-base font-proxima-regular text-greyText leading-relaxed">
                    We recently talked about{" "}
                    <Link
                      to="/how-to-improve-employee-engagement-at-your-workplace"
                      className=" text-blue-500 font-proxima-bold"
                    >
                      How to improve employee engagement in your organization.
                    </Link>{" "}
                    That said, have you, as an entrepreneur, business owner,
                    employer, or human resource employee wondered about the
                    elements that make up employee engagement? There are 8 key
                    elements of employee engagement that combine for a motivated
                    workplace.
                  </p>

                  <p className="mt-3 text-base font-proxima-regular text-greyText leading-relaxed">
                    Gone are the days when employee engagement was simply
                    equated with job satisfaction. Now, we’re all profoundly
                    into the jobs we do, building a deeper connection with the
                    company’s vision and mission. Today, engaged employees go an
                    extra mile to contribute to their organization’s success.
                    However, achieving high levels of engagement is not easy and
                    requires a lot more than what’s on the surface.
                  </p>

                  <div className="mt-5">
                    <h2 className="text-lg xl:text-2xl lg:text-2xl font-tight-bold text-black">
                      Key Elements of Employee Engagement
                    </h2>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        1. Meaningful Work
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Meaningful work refers to the tasks and collaborations
                        that resonate with an individual’s proficiencies,
                        interests, and stances. Observingly, employees feel a
                        stronger connection with their work and workplace when
                        they are contributing to the organization's purpose and
                        goals. For example, a software developer might feel more
                        connected to their work if they worked on a product
                        feature that improved user experience since they know
                        that they are adding to a user's satisfaction.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Providing workers and perception into what precisely the
                        organization requires from them can support employees to
                        understand how their efforts matter. Offer employees
                        options to undertake a project they are enthusiastic
                        about or volunteer for a campaign that betters the
                        community to promote engagement.
                      </p>
                    </div>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        2. Autonomy
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Autonomy simply means giving every employer the power to
                        take complete ownership and responsibility for their
                        work. When an organization offers autonomy, an employee
                        cannot help but feel more trusted and responsible,
                        eventually leading to high productivity levels. Allowing
                        employees to choose their work methods or set their own
                        deadlines can promote a sense of responsibility and
                        accountability.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        A big example of this practice is Google's famous "20%
                        time" policy, according to which employees can dedicate
                        a portion of their workweek to personal projects of
                        their choice. It is because of this policy and high
                        performance levels that they created successful products
                        like Gmail and Google News.
                      </p>
                    </div>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        3. Growth and Development
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        As working individuals, we all are all constantly
                        looking to grow and advance in the roles we perform. But
                        is it the employee's responsibility alone to grow? Not
                        really, no. Organizations also play a role in supporting
                        employee growth. Some ways to do that is with mentorship
                        and training programs, upskilling options, setting up
                        webinars, etc. The goal, in the end, is the same- high
                        employee engagement.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Companies like Salesforce developed an employee
                        development program called Trailhead. Through the
                        platform, their employees can take up various courses
                        and certifications online. Ultimately, as an
                        organization, you cultivate a skilled workforce that
                        drives innovation and growth.
                      </p>
                    </div>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        4. Recognition and Appreciation
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        There's no employee in the world that wouldn't want to
                        be recognized and acknowledged for the good work they
                        do. Sadly, not every organization endorses such a work
                        culture. You have no idea of the kind of positive work
                        environment rewarding employees can bring. Rewards don't
                        always have to be in the form of gifts. Of course,
                        they're one of the options, but even something as simple
                        as recognizing their work in front of everyone, a
                        written thank you email, or naming the employee of the
                        week will also do wonders.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Boosting an employee's morale is very important so their
                        performance is far from taking a dip or remaining
                        stagnant and is always more towards an upward growth.
                        Additionally, the impact it will have on driving
                        employee engagement is outstanding!
                      </p>
                    </div>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        5. Workplace Relationships
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        How do you achieve that? Focus on conducting
                        team-building exercises, opening more sources of
                        communication, encouraging cross-departmental
                        collaboration, and the like. Regular team lunches or
                        social gatherings, for instance, can promote camaraderie
                        and build relationships among team members. Well-known
                        for its dynamic corporate culture, Zappos places a high
                        value upon fostering positive working connections. One
                        such initiative is "Zapponian for a Day," in which staff
                        members from various departments shadow one another to
                        learn more about their respective roles. Strong
                        interpersonal ties can help companies instill a sense of
                        loyalty and belonging in their workforce.
                      </p>
                    </div>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        6. Work-Life Balance
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Work-life balance is crucial for the optimal functioning
                        of the employee. Firstly, in this area the employer
                        provides remote work and the flexibility of the working
                        day. Secondly, work-life integration is promoted,
                        instead of separation. For example, forcing people to
                        check their emails during their vacation would wish to
                        save the day. Workers need to relax and forget about
                        their professional duties. Otherwise, they will burn
                        out, which is not beneficial to anyone. That is why if
                        you choose this path of advancement, you will be like
                        Buffer to build a remote culture. Its employees, located
                        everywhere in the world, have been enjoying a good
                        work-life balance. With Buffer Retreats, the company
                        helps employees travel and vacations; mental health
                        professionals have long sought to help workers in a
                        toxic environment. Thus, with a work-life balance, the
                        employer shows that he is genuinely concerned about his
                        subordinates’ well-being and that there is excellent
                        comfort in the workplace.
                      </p>
                    </div>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        7. Organizational Values Alignment
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Great employees are in for the long run when they see
                        that the company's goals are in line with their personal
                        principles and cultural identity. Organizations can
                        foster values integration by clearly expressing their
                        purpose, vision, and core values through actions and
                        every activity and thereby consider them for every
                        decision they make. In such a way, Patagonia’s focus on
                        environmental sustainability may help to cause employees
                        who find this important to them to find the meaning and
                        to find their purpose.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Through implementing schemes like the "Worn Wear"
                        program, which is addressing sustainability by giving
                        customers the possibility to recycle used clothes for
                        store credit, the company is not only reinforcing its
                        commitment to sustainability but also inspiring
                        employees to exhibit these values in their work and
                        personal lives.
                      </p>
                    </div>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        8. Trust in Leadership
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Confidence is the cornerstone upon which the solid
                        employee-employee link is erected. Leaders can create
                        trust through their actions. Managers have to be open,
                        honest, and consistent in what they do and say to gain
                        others’ trust. Communicating openly can be realized by
                        sharing explicit goals, challenges, and strategies with
                        employees. Employees will grasp the direction in the
                        organization and will be confident about the
                        leadership’s vision.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Southwest Airlines is famous for its direct and human
                        approach, leadership style under which executives are
                        actively interested in the problems of lower staff,
                        through the “Leadership Listening” program, for example,
                        the leaders are going to different airport stations to
                        listen to their workers’ worries and recommendations.
                        Through the building of this environment, where honesty
                        and interaction are dominant, companies are able to
                        create a culture of openness and cooperation, which in
                        turn positively impacts employees' engagement and
                        commitment.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        In conclusion, each of these elements plays a vital role
                        in fostering employee engagement and creating a positive
                        work environment where employees feel valued, motivated,
                        and empowered to contribute their best efforts. By
                        prioritizing meaningful work, autonomy, growth and
                        development, recognition and appreciation, workplace
                        relationships, work-life balance, organizational values
                        alignment, and trust in leadership, organizations can
                        unlock the full potential of their workforce and achieve
                        sustainable success in today's competitive landscape.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* SIDEBAR */}
            <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0">
              <div className="pl-0 xl:pl-8 lg:pl-8  h-fit sticky top-24">
                {/* NEWSLETTER */}
                <motion.div
                  variants={animation}
                  className="pb-8 border-b border-greyBorder"
                >
                  <div
                    variants={animation}
                    className="bg-greyBg bg-opacity-50 border border-greyBorder rounded-lg p-5 xl:p-8 lg:p-8"
                  >
                    <h5 className="text-2xl font-tight-bold">
                      Join Our Community
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3">
                      Join us so that we can send you updates on new features,
                      valuable tips, expert strategies and our exclusive offers
                      delivered directly to your inbox.
                    </p>
                    <form className="mt-5" onSubmit={formHandling.handleSubmit}>
                      <input
                        type="email"
                        placeholder="Type you email address"
                        name="newsletterEmail"
                        id="newsletterEmail"
                        value={formHandling.values.email}
                        onChange={formHandling.handleChange}
                        className={`w-full block bg-white px-4 py-3 rounded-lg text-sm font-proxima-semibold text-black border ${
                          formHandling.errors.email &&
                          formHandling.touched.email
                            ? "border-red-500"
                            : "border-greyBorder"
                        } border-greyBorder focus:outline-brandColor placeholder:font-proxima-regular`}
                      />
                      <Button
                        disabled={formLoading}
                        type={formLoading ? "button" : "submit"}
                        className="group mt-3 w-full flex items-center gap-1 justify-center shadow-none hover:shadow-none font-proxima-semibold text-white rounded-md bg-brandColor normal-case text-sm hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                      >
                        {formLoading ? "Please wait.." : "Subscribe"}
                        <MdOutlineArrowOutward className="w-4 h-4 text-white" />
                      </Button>
                    </form>
                  </div>
                </motion.div>

                {/* Schedule a Demo */}
                <motion.div variants={animation} className="pt-8">
                  <BsCalendar2DateFill className="w-10 h-10 text-black" />
                  <h5 className="mt-3 text-2xl font-tight-bold leading-snug">
                    Learn how Aligned Rewards can be useful for your
                    organization
                  </h5>
                  <p className="mt-3 text-base font-proxima-regular text-greyText">
                    Experience a guided walkthrough of all our features in this
                    exclusive video!
                  </p>
                  <Button
                    className="mt-5 shadow-none hover:shadow-none bg-brandColor text-white font-proxima-semibold text-sm normal-case"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </motion.div>

                {/* TAGS */}
                <motion.div variants={animation} className="pt-8 hidden">
                  <h5 className="text-xl xl:text-2xl lg:text-2xl font-tight-bold text-black tracking-tight">
                    Tags
                  </h5>
                  <div className="flex items-center flex-wrap gap-2 mt-5">
                    {tags.map((item) => (
                      <React.Fragment key={item.id}>
                        <SmallTagsButton label={item.name} link={item.link} />
                      </React.Fragment>
                    ))}
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALENDLY MODAL */}
      <PopupModal
        open={calendlyModal}
        onModalClose={() => setCalendlyModal(!calendlyModal)}
        rootElement={document.getElementById("root")}
        url="https://calendly.com/contact-ucp8/30min"
      />
    </motion.section>
  );
};

export default EmployeeEngagementTwo;
