import { useState } from "react";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer } from "react-toastify";
import { PopupModal } from "react-calendly";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* ANIMATION */
import { animation, containerAnimation, GCP_BUCKET_URL } from "config";

/* COMPONENTS */
import {
  CallToAction,
  CounterComponent,
  FrequentlyAskedQuestions,
} from "components/homepage";

/* ICONS */
import { FaCircleCheck } from "react-icons/fa6";

const EmployeeEngagementPage = () => {
  const [calendlyModal, setCalendlyModal] = useState(false);

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* BANNER */}
      <motion.div
        variants={animation}
        className="p-0 py-5 xl:p-5 lg:p-5 relative h-auto xl:h-featureBanner lg:h-featureBanner"
      >
        {/* LEFT SECTION */}
        <div className="container mx-auto h-full">
          <div className="h-full flex items-center">
            <div className="w-full xl:w-2/5 lg:w-2/5">
              <div className="bg-brandColor bg-opacity-10 rounded-full border border-brandColor w-fit px-4 py-1">
                <p className="text-sm font-proxima-semibold text-brandColor">
                  Employee Engagement
                </p>
              </div>
              <div className="mt-5">
                <h1 className="text-3xl xl:text-4xl lg:text-4xl font-tight-bold text-black leading-snug">
                  Boost morale, productivity, and collaboration with our
                  Employee Engagement Software
                </h1>
                <h2 className="mt-5 text-lg xl:text-xl lg:text-xl font-proxima-regular text-greyText tracking-tight">
                  Boost morale, productivity, and collaboration with our
                  Employee Engagement Software to elevate workplace
                  satisfaction, foster collaboration, and streamline
                  communication. Lead your team towards increased productivity
                  and a thriving organizational culture. Try it now!
                </h2>
                <div className="mt-5 xl:mt-8 lg:mt-8">
                  <div className="flex items-center gap-3">
                    <Button
                      className="shadow-none hover:shadow-none rounded-lg bg-brandColor normal-case text-base font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                      onClick={() => setCalendlyModal(!calendlyModal)}
                    >
                      Schedule a Demo
                    </Button>
                  </div>
                </div>
                <div className="mt-8">
                  <div className="flex items-center gap-2">
                    <FaCircleCheck className="w-5 h-5 text-green-500" />
                    <p className="text-base font-proxima-regular text-greyText">
                      30 day free trial
                    </p>
                  </div>
                  <div className="flex items-center gap-2 mt-3">
                    <FaCircleCheck className="w-5 h-5 text-green-500" />
                    <p className="text-base font-proxima-regular text-greyText">
                      No credit card required
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* RIGHT SECTION */}
        <div className="relative xl:absolute lg:absolute top-5 right-0 w-full xl:w-1/2 lg:w-1/2 h-full overflow-hidden pl-5 xl:pl-0 lg:pl-0">
          <div className="h-full bg-gradient-to-tr from-blue-100 to-white rounded-l-2xl ">
            <div className="w-[150vh] h-[40vh] xl:h-featureBanner lg:h-featureBanner relative xl:absolute lg:absolute top-0 xl:top-5 lg:top-5 left-0 xl:left-5 lg:left-5">
              <img
                src={`${GCP_BUCKET_URL}/rename-employee-development-02.png`}
                className="w-full h-[100vh] rounded-tl-xl"
                alt="Boost morale, productivity, and collaboration with our Employee Engagement Software"
              />
            </div>
          </div>
        </div>
      </motion.div>

      {/* COUNTERS */}
      <motion.div variants={animation} className="mt-5 xl:mt-10 lg:mt-10">
        <div className="bg-footer bg-opacity-5 py-10">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-10 xl:gap-20 lg:gap-20">
              <div className="col-span-1">
                <CounterComponent
                  title="Enhance Performance Tracking "
                  description="Efficiently monitor employee performance with dynamic inbuilt tools, allowing accurate assessment and targeted feedback for continual improvement. "
                />
              </div>
              <div className="col-span-1">
                <CounterComponent
                  title="Reduce Administrative Burden"
                  description="Organize onboarding processes to reduce administrative workload by 30%, allowing for greater focus on strategic management initiatives."
                />
              </div>
              <div className="col-span-1">
                <CounterComponent
                  title="Amplify Task Organization"
                  description="Simplify task management processes for intensified productivity and collaboration, ensuring clear directives and skillful task completion."
                />
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* FEATURE 01 */}
      <motion.div variants={animation} className="py-5 xl:py-14 lg:py-14">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-5 xl:gap-10 lg:gap-10 items-center">
            <div className="col-span-1 order-2 xl:order-1 lg:order-1">
              <div className="w-full h-[50vh] overflow-hidden shadow-md rounded-xl">
                <div className="w-full h-full rounded-xl bg-gradient-to-br from-blue-100 to-white relative">
                  <div className="w-[85vh] h-auto relative xl:absolute lg:absolute top-5 xl:top-14 lg:top-14 -left-5 xl:left-5 lg:left-5">
                    <img
                      src={`${GCP_BUCKET_URL}/employee-engagement/02.png`}
                      className="w-full h-[55vh] rounded-lg"
                      alt="Harmonize your teams with objectives for enhanced outcomes"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1 order-1 xl:order-2 lg:order-2 flex items-center justify-center">
              <div className="w-full xl:w-11/12 lg:w-11/12">
                <h2 className="text-2xl xl:text-3xl lg:text-3xl font-tight-bold text-black leading-snug">
                  Appreciate the talent in your team with Rewards Points
                </h2>
                <h5 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText mt-3">
                  Recognize your team's skills and efforts by awarding rewards
                  points, creating a culture of appreciation and motivating
                  excellence.
                </h5>
                <div className="mt-5">
                  <div className="flex items-start gap-3">
                    <FaCircleCheck className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText">
                      Establish a positive culture within your team by
                      recognizing their accomplishments by rewarding them with
                      points.
                    </p>
                  </div>
                  <div className="flex items-start gap-3 mt-3">
                    <FaCircleCheck className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText">
                      Spread positivity within the team by contributing to
                      elevated employee engagement by defining goals that come
                      with rewards.
                    </p>
                  </div>
                </div>
                <div className="mt-5 xl:mt-10 lg:mt-10">
                  <Button
                    className="shadow-none hover:shadow-none rounded-lg bg-brandColor normal-case text-base font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* FEATURE 02 */}
      <motion.div
        variants={animation}
        className="py-5 xl:py-14 lg:py-14 bg-footer bg-opacity-5"
      >
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-5 xl:gap-10 lg:gap-10 items-center">
            <div className="col-span-1 flex items-center justify-center">
              <div className="w-full xl:w-11/12 lg:w-11/12">
                <h2 className="text-2xl xl:text-3xl lg:text-3xl font-tight-bold text-black leading-snug">
                  Effortlessly Coordinate Team Meetings: Schedule Smarter,
                  Collaborate Better!
                </h2>
                <h5 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText mt-3">
                  Streamline meeting scheduling and enhance collaboration with
                  our intuitive tools, making team coordination effortless and
                  productivity soar.
                </h5>
                <div className="mt-5">
                  <div className="flex items-start gap-3">
                    <FaCircleCheck className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText">
                      Schedule meetings and make collaboration easy by
                      scheduling meetings on the tool that you can integrate
                      with multiple meeting platforms. Let coordination become
                      effortless and productivity soar.
                    </p>
                  </div>
                  <div className="flex items-start gap-3 mt-5">
                    <FaCircleCheck className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText">
                      Plan meeting agendas and review work with your team
                      members to track progress and detect potential roadblocks.
                    </p>
                  </div>
                </div>
                <div className="mt-5 xl:mt-10 lg:mt-10">
                  <Button
                    className="shadow-none hover:shadow-none rounded-lg bg-brandColor normal-case text-base font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="w-full h-[50vh] overflow-hidden shadow-md rounded-xl">
                <div className="w-full h-full rounded-xl bg-gradient-to-br from-blue-100 to-white relative">
                  <div className="w-[85vh] h-auto relative xl:absolute lg:absolute top-5 xl:top-14 lg:top-14 -left-5 xl:left-5 lg:left-5">
                    <img
                      src={`${GCP_BUCKET_URL}/employee-engagement/01.png`}
                      className="w-full h-[55vh] rounded-lg"
                      alt=" Get the overall statistics of your objectives and key results at one place"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* FEATURE 01 */}
      <motion.div variants={animation} className="py-5 xl:py-14 lg:py-14">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-5 xl:gap-10 lg:gap-10 items-center">
            <div className="col-span-1">
              <div className="w-full h-[50vh] overflow-hidden shadow-md rounded-xl">
                <div className="w-full h-full rounded-xl bg-gradient-to-br from-blue-100 to-white relative">
                  <div className="w-[85vh] h-auto relative xl:absolute lg:absolute top-5 xl:top-14 lg:top-14 left-5 xl:left-12 lg:left-12">
                    <img
                      src={`${GCP_BUCKET_URL}/okr-management/01.png`}
                      className="w-full h-[55vh] rounded-lg"
                      alt=" Get the overall statistics of your objectives and key results at one place"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1 flex items-center justify-center">
              <div className="w-full xl:w-11/12 lg:w-11/12">
                <h2 className="text-2xl xl:text-3xl lg:text-3xl font-tight-bold text-black leading-snug">
                  Fuel excellence with the Leader Board, sparking motivation and
                  driving high performance within your team
                </h2>
                <h5 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText mt-3">
                  Boost team motivation with the Leader Board and Engagement
                  Surveys feature, igniting healthy competition and driving
                  high-performance results.
                </h5>
                <div className="mt-5">
                  <div className="flex items-start gap-3">
                    <FaCircleCheck className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText">
                      Create a transparent culture where everyone is updated
                      with their team’s work and knows who is performing well in
                      their teams.
                    </p>
                  </div>
                  <div className="flex items-start gap-3 mt-5">
                    <FaCircleCheck className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText">
                      Appreciate the top performers at your organization to
                      encourage others to take their performance up a notch too.
                    </p>
                  </div>
                </div>
                <div className="mt-5 xl:mt-10 lg:mt-10">
                  <Button
                    className="shadow-none hover:shadow-none rounded-lg bg-brandColor normal-case text-base font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* FREQUENTLY ASKED QUESTIONS */}
      <FrequentlyAskedQuestions />

      {/* CALL TO ACTION */}
      <CallToAction />

      {/* CALENDLY MODAL */}
      <PopupModal
        open={calendlyModal}
        onModalClose={() => setCalendlyModal(!calendlyModal)}
        rootElement={document.getElementById("root")}
        url="https://calendly.com/contact-ucp8/30min"
      />
    </motion.section>
  );
};

export default EmployeeEngagementPage;
