/* ICONS */
import { PiTargetBold } from "react-icons/pi";
import { MdAnalytics, MdTask, MdHealthAndSafety } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { FaBlackTie, FaBuildingColumns, FaBuildingNgo } from "react-icons/fa6";
import { RiLightbulbFlashFill } from "react-icons/ri";

const footerNav = [
  {
    id: 1,
    title: "Product Features",
    list: [
      {
        id: 1,
        route: "/employee-engagement",
        name: "Employee Engagement",
      },
      {
        id: 2,
        route: "/performance-management",
        name: "360° Performance Feedback",
      },
      {
        id: 4,
        route: "/goal-management",
        name: "Goal Management",
      },
      {
        id: 3,
        route: "/tasks-management",
        name: "Task Management",
      },
    ],
  },
  {
    id: 2,
    title: "Company Info",
    list: [
      {
        id: 1,
        route: "/about-us",
        name: "About Us",
      },
      {
        id: 2,
        route: "/blogs",
        name: "Blogs",
      },
      {
        id: 3,
        route: "/waitlist",
        name: "Waitlist",
      },
      /* {
        id: 4,
        route: "/pricing",
        name: "Pricing Plans",
      }, */
    ],
  },
];

const platformOptions = [
  {
    id: 1,
    icon: FaUserTie,
    title: "Employee Engagement",
    description: "Inspire, manage and align your team seamlessly.",
    route: "/employee-engagement",
  },
  {
    id: 2,
    icon: MdAnalytics,
    title: "360° Performance Feedback",
    description: "Build a team of experts and extensive performance.",
    route: "/performance-management",
  },
  {
    id: 4,
    icon: PiTargetBold,
    title: "Goal Management",
    description: "Objective based goals for easy strategy execution.",
    route: "/goal-management",
  },
  {
    id: 3,
    icon: MdTask,
    title: "Tasks Management",
    description: "Increase your day-to-day productivity with easier tracking.",
    route: "/tasks-management",
  },
  /* {
    id: 5,
    icon: MdFeedback,
    title: "Real-time Feedback",
    description: "Power up your team with real time feedbacks.",
    route: "/real-time-feedback",
  }, */
];

const industriesOptions = [
  {
    id: 1,
    icon: FaBlackTie,
    title: "Professionals",
    description: "Performance optimization in the Professionals industry",
  },
  {
    id: 2,
    icon: FaBuildingColumns,
    title: "Financial Services",
    description: "Performance optimization in the Financial industry",
  },
  {
    id: 3,
    icon: MdHealthAndSafety,
    title: "Healthcare",
    description: "Performance optimization in the Healthcare industry",
  },
  {
    id: 4,
    icon: FaBuildingNgo,
    title: "Public Sector",
    description:
      "Performance optimization in the Non-profit and public industry.",
  },
  {
    id: 5,
    icon: RiLightbulbFlashFill,
    title: "Technology",
    description: "Performance optimization in the It and Technology industry",
  },
];

export { footerNav, platformOptions, industriesOptions };
