import React, { useState } from "react";
import { Link } from "react-router-dom";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import { PopupModal } from "react-calendly";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* CONFIG */
import { animation, containerAnimation, GCP_BUCKET_URL } from "config";

/* ICONS */
import { MdOutlineArrowOutward } from "react-icons/md";
import { BsCalendar2DateFill } from "react-icons/bs";

/* FORM VALIDATIONS */
import * as yup from "yup";
import { useFormik } from "formik";

/* API */
import { newsletterAPI } from "service/api";

/* VALIDATION SCHEMA */
const waitlistValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .min(1, "Email Address is required")
    .required("Email Address is required."),
});

/* TAGS */
const tags = [
  {
    id: 1,
    name: "Task Management",
    link: "/blogs/task-management",
  },
  {
    id: 2,
    name: "Employee Engagement",
    link: "/blogs/employee-engagement",
  },
  {
    id: 3,
    name: "Performance Management",
    link: "/blogs/performance-management",
  },
  {
    id: 4,
    name: "Change Management",
    link: "/blogs/change-management",
  },
  {
    id: 5,
    name: "OKR Management",
    link: "/blogs/okr-management",
  },
  {
    id: 6,
    name: "Project Planning",
    link: "/blogs/project-planning",
  },
  {
    id: 7,
    name: "Project Management",
    link: "/blogs/project-management",
  },
  {
    id: 8,
    name: "Team collaboration",
    link: "/blogs/team-collaboration",
  },
];

const GoalsManagement = () => {
  const [calendlyModal, setCalendlyModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  /* FORM HANDLING */
  const formHandling = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: waitlistValidation,
    onSubmit: (values) => {
      const obj = {
        email: values.email,
      };
      setFormLoading(true);
      newsletterAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(
              "Congratulations! 🎉 You have subscribed to our newsletter."
            );
          } else {
            toast.error(
              "We were not able to capture your details. Please try again."
            );
          }
          setFormLoading(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formHandling.resetForm();
          setFormLoading(false);
        });
    },
  });

  /* SMALL TAGS BUTTON */
  const SmallTagsButton = (props) => {
    return (
      <Link to={props.link}>
        <Button className="shadow-none hover:shadow-none bg-greyBg hover:bg-logoOrange rounded-md text-sm font-proxima-regular text-greyText hover:text-white normal-case font-medium px-4 py-2 transition-all duration-300 ease-in-out">
          {props.label}
        </Button>
      </Link>
    );
  };

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* CONTENT */}
      <motion.div variants={animation} className="py-5 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
            {/* CONTENT */}
            <div className="col-span-2 border-r-0 xl:border-r lg:border-r border-greyBorder">
              <div className="pr-0 xl:pr-8 lg:pr-8">
                <img
                  src={`${GCP_BUCKET_URL}/blogs/goals-management/goals-management-inner-banner.webp`}
                  className="rounded-xl object-cover h-[20vh] xl:h-[30vh] lg:h-[30vh] w-full shadow"
                  alt="How to Master Goal Setting in Your Organization"
                />

                <div className="mt-5">
                  <h1 className="mt-5 text-xl xl:text-3xl lg:text-3xl font-tight-bold text-black">
                    How to Master Goal Setting in Your Organization?
                  </h1>
                  <p className="mt-3 text-base font-proxima-regular text-greyText leading-relaxed">
                    Goal-setting for employees builds bridges between
                    individuals and entire organizations, as it helps to map the
                    road for team as well as individual success. This
                    comprehensive article will detail several goal-setting
                    techniques, explain the framework of SMART goals, explore
                    goal-setting theory, and also discuss how Aligned Rewards
                    interventions can turn organizational goal-setting efforts
                    on overdrive. It's time to start designing SMART goals,
                    promoting a culture through which high performance is
                    encouraged, and using accountability and recognition to
                    translate goal-setting into organizational success.
                  </p>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Understanding the Goal-Setting Theory
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Goal-setting theory, which was founded by E. Locke and G.
                      Latham, particularly stresses the importance of the
                      presence of goals that are defined and demanding as a way
                      to increase motivation and performance. This theory posits
                      that tasks that are profound and tough surpass those that
                      are vague and simple, performance-wise. Workers will gain
                      motivation when the goals are set at a high level yet not
                      too difficult to achieve, otherwise giving them a reason
                      to persevere and work to the best. Rewards Aligning Games
                      theory uses the principles of goal setting and offers a
                      goal-tracking platform that makes people take action that
                      leads to results.
                    </p>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      SMART Goals in a Gist
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      SMART goals form the core of the successive goal-setting
                      process because they provide a practical way of defining
                      targets that are highly specific, measurable, achievable,
                      relevant, and time-bound. At Aligned Rewards, we embrace
                      the SMART criteria as the foundation of our goal-setting
                      approach, ensuring that goals are:At Aligned Rewards, we
                      embrace the SMART criteria as the foundation of our
                      goal-setting approach, ensuring that goals are:
                    </p>
                    <ol className="mt-3 ml-8 list-decimal">
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-semibold text-black">
                          Specific:
                        </span>{" "}
                        The slogan was definitely straight-forward, clear,
                        point-to-point, and concise, and at the same time, it
                        was not open to any confusion.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Measurable:
                        </span>{" "}
                        Measurable, attributable, and amenable to scientific
                        evaluation; as such, progress is trackable and
                        objective.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Achievable:
                        </span>{" "}
                        Based on the assumption that the available time and
                        resources are the most important determinants of
                        viability and success, this plan presents a realistic
                        and attainable plan that does not exceed the given
                        timeframe or resource constraints.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Relevant:
                        </span>{" "}
                        Tailored to the organization's goals and staff members™
                        positions, the capabilities of the individual and the
                        team can be utilized to create a higher level of impact.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Time-bound:
                        </span>{" "}
                        By extending to much shorter deadline periods with the
                        addition of clear goals or achievements, the burning
                        candle is ignited in the participants.
                      </li>
                    </ol>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Through the implementation of the SMART criteria,
                      organizations will be expecting something completely
                      different: measurable, actionable, relevant, time-defined,
                      and achievable goals that will serve as the leading force
                      of accountability and performance.
                    </p>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Exploring Other Goal-Setting Methods
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      In addition to the SMART goal framework, several other
                      goal-setting methods can be employed to enhance
                      effectiveness and efficiency.
                    </p>
                    <ol className="mt-3 ml-8 list-decimal">
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-semibold text-black">
                          Management by Objectives (MBO):
                        </span>{" "}
                        This technique is about defining goals jointly with both
                        the managers and subordinates to get each objective in
                        sync with organizational objectives among the employees
                        of the organization. Aligned Rewards enables process
                        management through the features of goal agreements and
                        tracking achievements towards objectives.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Outcome-Oriented Goal Setting:
                        </span>{" "}
                        Emphasizes the finished objectives or results instead of
                        what activities would be accomplished. Through
                        identifying explicit results as the endpoint and
                        recording the steps towards achieving them, employees
                        are ensured direction and clarity in outcome
                        realization.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Stretch Goals:
                        </span>{" "}
                        Advocating for self-set goals that are ambitious and
                        difficult, maybe even stretching the employees beyond
                        their comfort zones, challenges the employees to
                        identify outstanding results. A connected reward
                        empowers stretch goal-setting with instruments for
                        performance monitoring and encouragement when milestones
                        are reached.
                      </li>
                    </ol>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Benefits of Effective Goal Setting
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Effective goal-setting offers numerous benefits for both
                      individuals and organizations.Effective goal-setting
                      offers numerous benefits for both individuals and
                      organizations.
                    </p>
                    <ol className="mt-3 ml-8 list-decimal">
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-semibold text-black">
                          Clarity and Focus:
                        </span>{" "}
                        Of course, with clearly formulated goals, you have
                        guidelines that are pretty clear. This, in turn, helps
                        your employees focus on achieving great goals.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Motivation and Engagement:
                        </span>{" "}
                        Captivating goals uncovers innate enthusiasm and
                        stimulates intrinsic engagement, which imbues workers
                        with a sense of pride and obelisk passion.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Accountability and Performance:
                        </span>{" "}
                        Setting objectives will retain accountability as well as
                        responsibility, and in turn, it will result in
                        performance and outcomes.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Collaboration and Alignment:
                        </span>{" "}
                        Common objectives enhance application, correspondence,
                        and team effectiveness, which eventually lead to team
                        success.
                      </li>
                    </ol>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Example of Goal Setting for Employees
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Let's consider an example of goal-setting for employees in
                      a sales department:
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="text-black font-proxima-semibold">
                        Objective:
                      </span>{" "}
                      The goal is to increase sales by 20% in the next three
                      months.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="text-black font-proxima-semibold">
                        Key Result 1:
                      </span>{" "}
                      Lead to $X additional customer attributions.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="text-black font-proxima-semibold">
                        Key Result 2:
                      </span>{" "}
                      For targeting the existing client base that invested more
                      than X%, upsell the main product line to improve the
                      average deal size.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="text-black font-proxima-semibold">
                        Key Result 3:
                      </span>{" "}
                      Reduce the elapsed time in the sales cycle by X days.
                    </p>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Aligning Goals with Aligned Rewards
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Aligned Rewards gives a simple interface that supports
                      goal setting, tracking, and rewarding goals in your
                      continuous pursuits. We deliver functionality, similar to
                      the alignment of goals, as well as monitoring how someone
                      is performing and tracking their progress, in order to
                      help organizations boost performance results. Aligned
                      Rewards uses goal alignment with organizational priorities
                      and acknowledging attainments to enhance the effect of an
                      organization's goal-setting performance.
                    </p>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Team Goals, Monitoring, and Adjustments
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Besides the less complicated goals of individuals, there
                      might be team goals of organizations to have cooperation
                      and spend less on individual unit success. Aligned Rewards
                      enables the setting up and tracking of targets for the
                      team, as well as giving the account manager a clear
                      picture of how the progression is going. The existence of
                      similar objectives and the ability to monitor the team's
                      collective performance can bring teams closer together and
                      enable the team members to execute their specific roles,
                      combining their competencies in order to achieve a more
                      desirable outcome. This may also include changing the
                      overall goals or the goal timeline if the circumstances or
                      attention focus on something different.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Effective employee goal setting is a critical component of
                      organizational success, driving performance, engagement,
                      and alignment. By leveraging goal-setting methods,
                      embracing the SMART framework, and utilizing platforms
                      like Aligned Rewards, organizations can empower employees
                      to reach their full potential and contribute to overall
                      success. With a clear focus on setting, monitoring, and
                      adjusting goals, organizations can drive performance
                      outcomes and achieve remarkable results.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* SIDEBAR */}
            <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0">
              <div className="pl-0 xl:pl-8 lg:pl-8  h-fit sticky top-24">
                {/* NEWSLETTER */}
                {/* NEWSLETTER */}
                <motion.div
                  variants={animation}
                  className="pb-8 border-b border-greyBorder"
                >
                  <div
                    variants={animation}
                    className="bg-greyBg bg-opacity-50 border border-greyBorder rounded-lg p-5 xl:p-8 lg:p-8"
                  >
                    <h5 className="text-2xl font-tight-bold">
                      Join Our Community
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3">
                      Join us so that we can send you updates on new features,
                      valuable tips, expert strategies and our exclusive offers
                      delivered directly to your inbox.
                    </p>
                    <form className="mt-5" onSubmit={formHandling.handleSubmit}>
                      <input
                        type="email"
                        placeholder="Type you email address"
                        name="newsletterEmail"
                        id="newsletterEmail"
                        value={formHandling.values.email}
                        onChange={formHandling.handleChange}
                        className={`w-full block bg-white px-4 py-3 rounded-lg text-sm font-proxima-semibold text-black border ${
                          formHandling.errors.email &&
                          formHandling.touched.email
                            ? "border-red-500"
                            : "border-greyBorder"
                        } border-greyBorder focus:outline-brandColor placeholder:font-proxima-regular`}
                      />
                      <Button
                        disabled={formLoading}
                        type={formLoading ? "button" : "submit"}
                        className="group mt-3 w-full flex items-center gap-1 justify-center shadow-none hover:shadow-none font-proxima-semibold text-white rounded-md bg-brandColor normal-case text-sm hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                      >
                        {formLoading ? "Please wait.." : "Subscribe"}
                        <MdOutlineArrowOutward className="w-4 h-4 text-white" />
                      </Button>
                    </form>
                  </div>
                </motion.div>

                {/* Schedule a Demo */}
                <motion.div variants={animation} className="pt-8">
                  <BsCalendar2DateFill className="w-10 h-10 text-black" />
                  <h5 className="mt-3 text-2xl font-tight-bold leading-snug">
                    Learn how Aligned Rewards can be useful for your
                    organization
                  </h5>
                  <p className="mt-3 text-base font-proxima-regular text-greyText">
                    Experience a guided walkthrough of all our features in this
                    exclusive video!
                  </p>
                  <Button
                    className="mt-5 shadow-none hover:shadow-none bg-brandColor text-white font-proxima-semibold text-sm normal-case"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </motion.div>

                {/* TAGS */}
                <motion.div variants={animation} className="pt-8 hidden">
                  <h5 className="text-xl xl:text-2xl lg:text-2xl font-tight-bold text-black tracking-tight">
                    Tags
                  </h5>
                  <div className="flex items-center flex-wrap gap-2 mt-5">
                    {tags.map((item) => (
                      <React.Fragment key={item.id}>
                        <SmallTagsButton label={item.name} link={item.link} />
                      </React.Fragment>
                    ))}
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALENDLY MODAL */}
      <PopupModal
        open={calendlyModal}
        onModalClose={() => setCalendlyModal(!calendlyModal)}
        rootElement={document.getElementById("root")}
        url="https://calendly.com/contact-ucp8/30min"
      />
    </motion.section>
  );
};

export default GoalsManagement;
