import React, { useState } from "react";
import { Link } from "react-router-dom";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import { PopupModal } from "react-calendly";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* CONFIG */
import { animation, containerAnimation } from "config";

/* ICONS */
import { MdOutlineArrowOutward } from "react-icons/md";
import { BsCalendar2DateFill } from "react-icons/bs";

/* FORM VALIDATIONS */
import * as yup from "yup";
import { useFormik } from "formik";

/* API */
import { newsletterAPI } from "service/api";

/* VALIDATION SCHEMA */
const waitlistValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .min(1, "Email Address is required")
    .required("Email Address is required."),
});

/* TAGS */
const tags = [
  {
    id: 1,
    name: "Task Management",
    link: "/blogs/task-management",
  },
  {
    id: 2,
    name: "Employee Engagement",
    link: "/blogs/employee-engagement",
  },
  {
    id: 3,
    name: "Performance Management",
    link: "/blogs/performance-management",
  },
  {
    id: 4,
    name: "Change Management",
    link: "/blogs/change-management",
  },
  {
    id: 5,
    name: "Goal Management",
    link: "/blogs/okr-management",
  },
  {
    id: 6,
    name: "Project Planning",
    link: "/blogs/project-planning",
  },
  {
    id: 7,
    name: "Project Management",
    link: "/blogs/project-management",
  },
  {
    id: 8,
    name: "Team collaboration",
    link: "/blogs/team-collaboration",
  },
];

const WorkplaceCommunication = () => {
  const [calendlyModal, setCalendlyModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  /* FORM HANDLING */
  const formHandling = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: waitlistValidation,
    onSubmit: (values) => {
      const obj = {
        email: values.email,
      };
      setFormLoading(true);
      newsletterAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(
              "Congratulations! 🎉 You have subscribed to our newsletter."
            );
          } else {
            toast.error(
              "We were not able to capture your details. Please try again."
            );
          }
          setFormLoading(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formHandling.resetForm();
          setFormLoading(false);
        });
    },
  });

  /* SMALL TAGS BUTTON */
  const SmallTagsButton = (props) => {
    return (
      <Link to={props.link}>
        <Button className="shadow-none hover:shadow-none bg-greyBg hover:bg-logoOrange rounded-md text-sm font-proxima-regular text-greyText hover:text-white normal-case font-medium px-4 py-2 transition-all duration-300 ease-in-out">
          {props.label}
        </Button>
      </Link>
    );
  };

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* CONTENT */}
      <motion.div variants={animation} className="py-5 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
            {/* CONTENT */}
            <div className="col-span-2 border-r-0 xl:border-r lg:border-r border-greyBorder">
              <div className="pr-0 xl:pr-8 lg:pr-8">
                <h1 className="mt-5 text-xl xl:text-3xl lg:text-3xl font-tight-bold text-black leading-snug">
                  10 Powerful Tips for Effective Workplace Communication
                </h1>

                <div className="mt-5">
                  <p className="mt-3 text-base font-proxima-regular text-greyText leading-relaxed">
                    Effective workplace communication is one of the most crucial
                    factors for carrying out organizational development
                    smoothly. Besides, it enables a great understanding of the
                    company’s goals and objectives, as well as the roles and
                    responsibilities that each employee in the organization has
                    to play, as well as the creation of unity, harmony, and an
                    overall good company culture. Effective workplace
                    communication plays a vital role in interpersonal relations
                    among various employees in an organization by enhancing
                    organizational goals, solving organizational conflicts, and
                    creating constructive and effective organizational teams. In
                    this blog, we are presenting 10 major strategies to improve
                    the communication system of your company for the betterment
                    of your employees’ unity and productivity.
                  </p>

                  <div className="mt-5">
                    <h2 className="text-lg xl:text-2xl lg:text-2xl font-tight-bold text-black">
                      Tips for Effective Workplace Communication
                    </h2>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        1. Encourage Open Dialogue
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Open workplace communication can be described as the
                        building block of functional workplace relations, and
                        organizations must ensure it is cultivated. Concerning
                        the approach towards reporting, it should be understood
                        that the employees of an organization should feel free
                        to contribute their ideas, report their concerns, and
                        provide feedback with no option for persecution.
                        Open-mindedness can also be fostered by employers
                        through the formation of a talking schedule, open Sesame
                        policies, and continual listening from employers.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span>{" "}
                        Another way to boost people’s communication skills at
                        work is to start daily, at least weekly, or monthly
                        meetings called ‘town hall’ where people can ask
                        questions and bring up what they think is important.
                      </p>
                    </div>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        2. Know or Choose More Precise Words
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Perhaps the clarity of messages is a critical element in
                        workplace communication. The same interpretation can be
                        given to phrases like clarity and accuracy, which mean
                        that communication should be precise to avoid confusion.
                        Refrain from using hard-to-understand words or phrases,
                        including items, terms, or anything else that a normal
                        person may not readily understand.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span> Hone
                        the task descriptions to ensure that the direction is
                        clear concerning what needs to be done, who is supposed
                        to do it, and by when. Rather than using statements
                        like, “The report should be done shortly,” you should
                        use phrases such as, “Can you please submit the
                        financial report by Friday?
                      </p>
                    </div>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        3. Leverage Technology
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        The information age is a high-velocity environment. It's
                        becoming evident by the day that relying on technology
                        can significantly improve organizational communication.
                        Instant communication tools, such as messaging through
                        Slack, Microsoft Teams, video conferencing apps like
                        Zoom, etc., make collaboration easier. Some platforms
                        facilitate the communication conducted by employees,
                        whether they are working with their colleagues or from
                        home.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span> The
                        organization of a project with the help of handy
                        applications such as Trello or Asana can also enable
                        project teams to monitor developments and updates on
                        certain undertakings that are ongoing in the workplace,
                        enhancing communication.
                      </p>
                    </div>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        4. Provide Constructive Feedback
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        One of the things to note when it comes to feedback is
                        that it is vital if an organization wants to grow. So,
                        missing feedback when trying to achieve effective
                        workplace communication is a big miss. However, it must
                        be delivered constructively, as this is very essential
                        for the success of any meeting. Management should stick
                        to giving behaviorally anchored and mapped (BAM)
                        feedback to ensure that the employees know what is
                        expected of them and what they are doing wrong.
                        Employees should also agree to be corrected and should
                        take correction as a privilege or benefit.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span>{" "}
                        Instead of making a negative comment like, “Your
                        presentation was bad,” you should use a constructive one
                        focusing on improvement, which could be, “You should
                        have used more data to back up your information and also
                        worked on your delivery.”
                      </p>
                    </div>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        5. Promote Active listening
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Listening is one of the most important aspects of
                        interpersonal communication within the flow of work. It
                        means listening to what is being said to the extent of
                        comprehending it, as well as being in a position to
                        answer appropriately and retain what has been said. To
                        avoid misunderstandings, both employers and employees
                        should pay regard to the practice of active listening.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span> In
                        managing responses to the speaker, make it a practice to
                        recap all that has been said before one can contribute.
                        That way, you are showing that you are listening, and
                        you are confirming to him or her that you understood
                        what they said.
                      </p>
                    </div>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        6. Encourage Team Collaboration
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Effective collaboration skills can be fostered through
                        teamwork in order to develop workplace communication
                        skills in the workplace. If using the same working
                        environment in a group to complete assignments, then it
                        becomes possible for employees to understand how to
                        relate well with one another, exchange knowledge, and
                        find solutions collectively. To achieve the goal of
                        building good teamwork, employers should consider
                        providing forums for group assignments and brainstorming
                        meetings, as well as team-building exercises.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span>{" "}
                        People should also ensure they hold what can be referred
                        to as working bees, where several employees are supposed
                        to work towards accomplishing a specific task together.
                        This could enhance understanding and cohesion within the
                        team since everyone can walk a couple of hundred yards
                        to the other side of a bridge.
                      </p>
                    </div>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        7. Set Clear Expectations
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        In any organization, good communication begins with
                        defining expectations in the workplace. Employers also
                        need to make sure that their employees understand what
                        is expected of them in terms of performance, conduct,
                        and interaction. It is important to set clear
                        expectations so that no confusion occurs and everyone is
                        on the same page.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span> If
                        you begin a new project, make sure to state the goals
                        and scope of the project, the timeline, and the specific
                        responsibilities of the team members. This clarity will
                        prevent ambiguity in communication and make sure that
                        people are on the same page.
                      </p>
                    </div>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        8. Use Body Language Effectively
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Another type of effective workplace communication that
                        takes place is nonverbal communication, including body
                        language, facial expressions, gestures, etc.
                        Self-monitoring and the ability to notice and assess the
                        nonverbal behaviors of others can aid in the avoidance
                        of misunderstandings.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span>{" "}
                        During conversations, it is important to make eye
                        contact, as it demonstrates attentiveness and
                        confidence. Make sure that your body posture and your
                        facial expressions reflect your feelings and attitudes.
                      </p>
                    </div>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        9. Address Conflicts Promptly
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        It must be noted that conflicts are very common in any
                        organization, but how they are managed has a great
                        impact on the communication process. Act fast and
                        professionally over the issues that may bring conflict
                        and affect relations between team members.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span> In
                        case of a conflict between the team members, ensure that
                        you arrange a meeting between the two parties so that
                        you can allow them to air their grievances and possibly
                        solve the issue themselves. Support a problem-solving
                        orientation and courtesy.
                      </p>
                    </div>

                    <div className="mt-5">
                      <h3 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                        10. Promote a Positive Communication Environment
                      </h3>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Finally, it is essential to create a healthy
                        communication culture in the long run. This includes
                        encouraging people to listen to and accept others in
                        areas such as race, gender, and sexual orientation.
                        Ensure that everyone in the team practices good
                        communication by acknowledging the good practices
                        demonstrated.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span>{" "}
                        Develop an appreciation campaign through which people
                        can recommend fellow workers who have great
                        interpersonal skills. This not only applies pressure to
                        individuals but also defines the importance of good
                        communication at the workplace.
                      </p>
                    </div>

                    <div className="mt-5">
                      <p className="text-base font-proxima-regular text-greyText leading-relaxed">
                        Implementing these 10 powerful tips can significantly
                        improve workplace communication, leading to a more
                        cohesive, productive, and harmonious work environment.
                        Remember, effective workplace communication is a
                        continuous process that requires effort and commitment
                        from both employees and employers. By fostering open
                        dialogue, leveraging technology, providing constructive
                        feedback, and promoting a positive communication
                        culture, organizations can achieve greater success and
                        satisfaction among their teams.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText leading-relaxed mt-3">
                        Ready to enhance your organization's communication and
                        achieve your goals? Aligning rewards with effective
                        workplace communication practices can further motivate
                        your team and drive success.{" "}
                        <Link
                          to="waitlist"
                          className=" text-blue-500 font-proxima-bold"
                        >
                          Get on the waitlist
                        </Link>{" "}
                        of Aligned Rewards today to transform your
                        organization’s vision into reality!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* SIDEBAR */}
            <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0">
              <div className="pl-0 xl:pl-8 lg:pl-8  h-fit sticky top-24">
                {/* NEWSLETTER */}
                <motion.div
                  variants={animation}
                  className="pb-8 border-b border-greyBorder"
                >
                  <div
                    variants={animation}
                    className="bg-greyBg bg-opacity-50 border border-greyBorder rounded-lg p-5 xl:p-8 lg:p-8"
                  >
                    <h5 className="text-2xl font-tight-bold">
                      Join Our Community
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3">
                      Join us so that we can send you updates on new features,
                      valuable tips, expert strategies and our exclusive offers
                      delivered directly to your inbox.
                    </p>
                    <form className="mt-5" onSubmit={formHandling.handleSubmit}>
                      <input
                        type="email"
                        placeholder="Type you email address"
                        name="newsletterEmail"
                        id="newsletterEmail"
                        value={formHandling.values.email}
                        onChange={formHandling.handleChange}
                        className={`w-full block bg-white px-4 py-3 rounded-lg text-sm font-proxima-semibold text-black border ${
                          formHandling.errors.email &&
                          formHandling.touched.email
                            ? "border-red-500"
                            : "border-greyBorder"
                        } border-greyBorder focus:outline-brandColor placeholder:font-proxima-regular`}
                      />
                      <Button
                        disabled={formLoading}
                        type={formLoading ? "button" : "submit"}
                        className="group mt-3 w-full flex items-center gap-1 justify-center shadow-none hover:shadow-none font-proxima-semibold text-white rounded-md bg-brandColor normal-case text-sm hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                      >
                        {formLoading ? "Please wait.." : "Subscribe"}
                        <MdOutlineArrowOutward className="w-4 h-4 text-white" />
                      </Button>
                    </form>
                  </div>
                </motion.div>

                {/* Schedule a Demo */}
                <motion.div variants={animation} className="pt-8">
                  <BsCalendar2DateFill className="w-10 h-10 text-black" />
                  <h5 className="mt-3 text-2xl font-tight-bold leading-snug">
                    Learn how Aligned Rewards can be useful for your
                    organization
                  </h5>
                  <p className="mt-3 text-base font-proxima-regular text-greyText">
                    Experience a guided walkthrough of all our features in this
                    exclusive video!
                  </p>
                  <Button
                    className="mt-5 shadow-none hover:shadow-none bg-brandColor text-white font-proxima-semibold text-sm normal-case"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </motion.div>

                {/* TAGS */}
                <motion.div variants={animation} className="pt-8 hidden">
                  <h5 className="text-xl xl:text-2xl lg:text-2xl font-tight-bold text-black tracking-tight">
                    Tags
                  </h5>
                  <div className="flex items-center flex-wrap gap-2 mt-5">
                    {tags.map((item) => (
                      <React.Fragment key={item.id}>
                        <SmallTagsButton label={item.name} link={item.link} />
                      </React.Fragment>
                    ))}
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALENDLY MODAL */}
      <PopupModal
        open={calendlyModal}
        onModalClose={() => setCalendlyModal(!calendlyModal)}
        rootElement={document.getElementById("root")}
        url="https://calendly.com/contact-ucp8/30min"
      />
    </motion.section>
  );
};

export default WorkplaceCommunication;
