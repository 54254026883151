import React, { useState } from "react";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import { PopupModal } from "react-calendly";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* CONFIG */
import { animation, containerAnimation, GCP_BUCKET_URL } from "config";

/* ICONS */
import { MdOutlineArrowOutward } from "react-icons/md";
import { BsCalendar2DateFill } from "react-icons/bs";

/* FORM VALIDATIONS */
import * as yup from "yup";
import { useFormik } from "formik";

/* API */
import { newsletterAPI } from "service/api";

/* VALIDATION SCHEMA */
const waitlistValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .min(1, "Email Address is required")
    .required("Email Address is required."),
});

const RewardingWorkplace = () => {
  const [calendlyModal, setCalendlyModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  /* FORM HANDLING */
  const formHandling = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: waitlistValidation,
    onSubmit: (values) => {
      const obj = {
        email: values.email,
      };
      setFormLoading(true);
      newsletterAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(
              "Congratulations! 🎉 You have subscribed to our newsletter."
            );
          } else {
            toast.error(
              "We were not able to capture your details. Please try again."
            );
          }
          setFormLoading(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formHandling.resetForm();
          setFormLoading(false);
        });
    },
  });

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* CONTENT */}
      <motion.div variants={animation} className="py-5 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
            {/* CONTENT */}
            <div className="col-span-2 border-r-0 xl:border-r lg:border-r border-greyBorder">
              <div className="pr-0 xl:pr-8 lg:pr-8">
                <img
                  src={`${GCP_BUCKET_URL}/blogs/rewarding-workplace/rewarding-workplace-banner-image.webp`}
                  className="rounded-xl object-cover h-[20vh] xl:h-[30vh] lg:h-[30vh] w-full shadow"
                  alt="How to Cultivate a Rewarding Workplace with Employee Rewards?"
                />

                <div className="mt-5">
                  <h1 className="mt-5 text-xl xl:text-3xl lg:text-3xl font-tight-bold text-black">
                    How to Cultivate a Rewarding Workplace with Employee
                    Rewards?
                  </h1>
                  <p className="mt-3 text-base font-proxima-regular text-greyText leading-relaxed">
                    The employers of today have no choice but to create an
                    environment that boasts of highly engaged employees, since
                    low employee engagement leads to high employee turnover
                    rates. This exhaustive guideline discusses effective
                    techniques to build and practice a culture of recognition
                    and appreciation, highlights the significance of rewards and
                    recognition, illustrates examples of employee rewards, and
                    explores the six kinds of employee rewards.
                  </p>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      The Importance of a Rewarding Workplace
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      An effective working environment is a must for any
                      company's success to ensure a reasonable level of
                      employees' contentment. Besides salary and benefits. An
                      organization should also ensure their employees feel
                      respected, valued, and are prepared to give their best all
                      to the organization. When employees realize that the
                      company they work for appreciates the efforts they make,
                      they feel motivated and dedicate their work to the company
                      goals. Setting the stage for a phenomenal workplace
                      fosters loyalty, decreases employee turnover, and lifts
                      everyone's spirits. Improved performance and success are
                      the inevitable result of that. It is important for
                      organizations to prioritize employee rewards and
                      recognition. This will assist organizations in creating a
                      culture of positivity in the workplace, where employees
                      feel empowered and inspired to give their best performance
                      every minute of a day.
                    </p>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Strategies for Creating a Rewarding Workplace
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      SMART goals form the core of the successive goal-setting
                      process because they provide a practical way of defining
                      targets that are highly specific, measurable, achievable,
                      relevant, and time-bound. At Aligned Rewards, we embrace
                      the SMART criteria as the foundation of our goal-setting
                      approach, ensuring that goals are:At Aligned Rewards, we
                      embrace the SMART criteria as the foundation of our
                      goal-setting approach, ensuring that goals are:
                    </p>

                    <div className="ml-8">
                      <div className="mt-5">
                        <h5 className="text-base font-tight-bold text-black">
                          1. Establish Clear Goals and Expectations
                        </h5>
                        <p className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                          Setting achievable and result-oriented targets avoids
                          confusion and motivates employees. Individual and team
                          objectives that match the organizational goals help
                          employees in understanding how their input matters
                          towards the success of the organization. With the help
                          of our Aligned Rewards employee reward tool, we have
                          the capacity to facilitate objectives setting as well
                          as tracking the achievement of those objectives across
                          all levels of the organization while keeping
                          transparency and alignment in check.
                        </p>
                      </div>
                      <div className="mt-5">
                        <h5 className="text-base font-tight-bold text-black">
                          2. Create an Ambiance of Expressing Love and
                          Thankfulness
                        </h5>
                        <p className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                          A continuous practice of rewards is a great driving
                          force that can improve as well as uplift employee
                          engagement. Adopting a system of rewards and
                          recognition for both company-wide and individual
                          success not only acknowledges colleagues, but also
                          enhances peer-to-peer recognition, encourages
                          performance, and reinforces desired behaviors.
                        </p>
                      </div>
                      <div className="mt-5">
                        <h5 className="text-base font-tight-bold text-black">
                          3. Conference may offer opportunities for learning new
                          skills
                        </h5>
                        <p className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                          Development of employees indicates the organization's
                          dedication to the employees' future and their growth
                          in the workplace, having a positive impact on their
                          career prospects. Through training sessions, mentoring
                          the employees themselves and having the career paths
                          fitting to the organization, employees can take
                          advantage of opportunities to sharpen their skills,
                          improve their knowledge and make advancements in their
                          career.
                        </p>
                      </div>
                      <div className="mt-5">
                        <h5 className="text-base font-tight-bold text-black">
                          4. Promote Work-Life Balance
                        </h5>
                        <p className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                          Balancing more work and life is important because
                          bounded burnout can be afforded and employees health
                          is left unseen. Arrangements of flexible work hours,
                          health programs and good policies illustrate the
                          management's conviction towards their employees'
                          physical, emotional and psychological prosperities.
                        </p>
                      </div>
                      <div className="mt-5">
                        <h5 className="text-base font-tight-bold text-black">
                          5. Emphasize Transparent Communication
                        </h5>
                        <p className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                          Free and open communication creates the bases of
                          delegation, collaboration, and employee appreciation
                          in an organization. Distributing regular
                          notifications, considering the comments and solving
                          the problems soon enough are the signs of transparency
                          and respect for the employer's opinion. Aligned
                          Rewards is supported with communication solutions that
                          provide smooth cooperativeness and openness regarding
                          the workflow between teams.
                        </p>
                      </div>
                      <div className="mt-5">
                        <h5 className="text-base font-tight-bold text-black">
                          6. Recognize and Reward Performance
                        </h5>
                        <p className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                          Recognizing and rewarding exceptional performance
                          motivates employees to strive for excellence. Whether
                          through monetary incentives, non-monetary rewards, or
                          public recognition, acknowledging contributions
                          demonstrates appreciation and encourages continued
                          success.
                        </p>
                      </div>
                    </div>

                    <div className="mt-5">
                      <p className="text-base font-proxima-regular text-greyText leading-relaxed">
                        Aligned Rewards offers a variety of reward options,
                        including monetary bonuses, gift cards, and personalized
                        awards, to celebrate achievements and recognize
                        outstanding performance.
                      </p>
                    </div>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Employee Rewards Examples
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      The following represents some employee reward examples,
                      which you can model into your own get-started-kit when you
                      want to have a happy and motivated workforce.
                    </p>
                    <ol className="mt-3 ml-8 list-decimal">
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-semibold text-black">
                          Monetary Rewards:
                        </span>{" "}
                        The cash bonuses, pay raises and profit-sharing rewards
                        among others is one of the significant monetary
                        remunerations that appreciates, recognizes, and
                        acknowledges employees' victories and endeavors.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Non-Monetary Rewards:
                        </span>{" "}
                        Prepare a gift for the team, say by way of extra paid
                        off time, more flexible work drops, and personalized
                        gifts that show you appreciate them.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Public Recognition:
                        </span>{" "}
                        Bring the recognition into the open by involving the
                        team gatherings, newsletters, or company-wide
                        announcements in order to pinpoint an employee's
                        achievement via the means of team meetings, newsletters
                        or company-wide announcements.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Career Advancement Opportunities:
                        </span>{" "}
                        Provide incentives, cross-functional move and
                        personalized career programs as the indicators of
                        staff's potential to make them grow apart from their
                        day-to-day tasks.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Peer-to-Peer Recognition:
                        </span>{" "}
                        Have employees convey their appreciation and sense of
                        gratitude for their colleague among others at the
                        office. This way, they can acknowledge their
                        contribution leading to peer-to-peer recognition.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Performance-Based Incentives:
                        </span>{" "}
                        Performance appetizers like sales commissions,
                        performance bonuses, milestone rewards etc. give impetus
                        to personnel to attain certain objectives and create
                        outputs.
                      </li>
                    </ol>
                    <p className="text-base font-proxima-regular text-greyText mt-5 leading-relaxed">
                      Therefore, creating a workplace that promotes a positive
                      working atmosphere is the key tool to boost employees'
                      engagement, productivity, and the accomplishments of the
                      organization. Places of work will achieve differently
                      through meaningful recognition and rewards programs
                      because employees know they are valued and empowered. We
                      have created Aligned Rewards, which is a holistic reward
                      and recognition scheme, providing users with customizable
                      options that can be changed to boost employee engagement.
                      Through monetary value to non-monetary perks our platform
                      helps to evaluate and recognize employees in ways that are
                      meaningful to their values and goals. Organizations can
                      make a headway in motivating and retaining their employees
                      by putting an emphasis on employee rewards and recognition
                      which will in turn boost morale and increase productivity.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Make employee success your key priority and build an
                      office where everyone feels important and special together
                      with Aligned Rewards, the only employee reward tool and
                      employee engagement tool you need today!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* SIDEBAR */}
            <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0">
              <div className="pl-0 xl:pl-8 lg:pl-8  h-fit sticky top-24">
                {/* NEWSLETTER */}
                {/* NEWSLETTER */}
                <motion.div
                  variants={animation}
                  className="pb-8 border-b border-greyBorder"
                >
                  <div
                    variants={animation}
                    className="bg-greyBg bg-opacity-50 border border-greyBorder rounded-lg p-5 xl:p-8 lg:p-8"
                  >
                    <h5 className="text-2xl font-tight-bold">
                      Join Our Community
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3">
                      Join us so that we can send you updates on new features,
                      valuable tips, expert strategies and our exclusive offers
                      delivered directly to your inbox.
                    </p>
                    <form className="mt-5" onSubmit={formHandling.handleSubmit}>
                      <input
                        type="email"
                        placeholder="Type you email address"
                        name="newsletterEmail"
                        id="newsletterEmail"
                        value={formHandling.values.email}
                        onChange={formHandling.handleChange}
                        className={`w-full block bg-white px-4 py-3 rounded-lg text-sm font-proxima-semibold text-black border ${
                          formHandling.errors.email &&
                          formHandling.touched.email
                            ? "border-red-500"
                            : "border-greyBorder"
                        } border-greyBorder focus:outline-brandColor placeholder:font-proxima-regular`}
                      />
                      <Button
                        disabled={formLoading}
                        type={formLoading ? "button" : "submit"}
                        className="group mt-3 w-full flex items-center gap-1 justify-center shadow-none hover:shadow-none font-proxima-semibold text-white rounded-md bg-brandColor normal-case text-sm hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                      >
                        {formLoading ? "Please wait.." : "Subscribe"}
                        <MdOutlineArrowOutward className="w-4 h-4 text-white" />
                      </Button>
                    </form>
                  </div>
                </motion.div>

                {/* Schedule a Demo */}
                <motion.div variants={animation} className="pt-8">
                  <BsCalendar2DateFill className="w-10 h-10 text-black" />
                  <h5 className="mt-3 text-2xl font-tight-bold leading-snug">
                    Learn how Aligned Rewards can be useful for your
                    organization
                  </h5>
                  <p className="mt-3 text-base font-proxima-regular text-greyText">
                    Experience a guided walkthrough of all our features in this
                    exclusive video!
                  </p>
                  <Button
                    className="mt-5 shadow-none hover:shadow-none bg-brandColor text-white font-proxima-semibold text-sm normal-case"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALENDLY MODAL */}
      <PopupModal
        open={calendlyModal}
        onModalClose={() => setCalendlyModal(!calendlyModal)}
        rootElement={document.getElementById("root")}
        url="https://calendly.com/contact-ucp8/30min"
      />
    </motion.section>
  );
};

export default RewardingWorkplace;
