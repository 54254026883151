import { useState } from "react";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer } from "react-toastify";
import { PopupModal } from "react-calendly";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* ANIMATION */
import { animation, containerAnimation, GCP_BUCKET_URL } from "config";

/* COMPONENTS */
import {
  CallToAction,
  CounterComponent,
  FrequentlyAskedQuestions,
} from "components/homepage";

/* ICONS */
import { FaCircleCheck } from "react-icons/fa6";

const OKRManagement = () => {
  const [calendlyModal, setCalendlyModal] = useState(false);

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* BANNER */}
      <motion.div
        variants={animation}
        className="p-0 py-5 xl:p-5 lg:p-5 relative h-auto xl:h-featureBanner lg:h-featureBanner"
      >
        {/* LEFT SECTION */}
        <div className="container mx-auto h-full">
          <div className="h-full flex items-center">
            <div className="w-full xl:w-2/5 lg:w-2/5">
              <div className="bg-brandColor bg-opacity-10 rounded-full border border-brandColor w-fit px-4 py-1">
                <p className="text-sm font-proxima-semibold text-brandColor">
                  Goal Management
                </p>
              </div>
              <div className="mt-5">
                <h1 className="text-3xl xl:text-4xl lg:text-4xl font-tight-bold text-black leading-snug">
                  Drive success with objectives and key results using Goal
                  Management
                </h1>
                <h2 className="mt-5 text-lg xl:text-xl lg:text-xl font-proxima-regular text-greyText tracking-tight">
                  Whether you're a large corporation or a budding startup,
                  Aligned Rewards is the solution you need to swiftly execute
                  your strategies, regardless of scale, accelerating your path
                  to success.
                </h2>
                <div className="mt-5 xl:mt-8 lg:mt-8">
                  <div className="flex items-center gap-3">
                    <Button
                      className="shadow-none hover:shadow-none rounded-lg bg-brandColor normal-case text-base font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                      onClick={() => setCalendlyModal(!calendlyModal)}
                    >
                      Schedule a Demo
                    </Button>
                  </div>
                </div>
                <div className="mt-8">
                  <div className="flex items-center gap-2">
                    <FaCircleCheck className="w-5 h-5 text-green-500" />
                    <p className="text-base font-proxima-regular text-greyText">
                      30 day free trial
                    </p>
                  </div>
                  <div className="flex items-center gap-2 mt-3">
                    <FaCircleCheck className="w-5 h-5 text-green-500" />
                    <p className="text-base font-proxima-regular text-greyText">
                      No credit card required
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* RIGHT SECTION */}
        <div className="relative xl:absolute lg:absolute top-5 right-0 w-full xl:w-1/2 lg:w-1/2 h-full overflow-hidden pl-5 xl:pl-0 lg:pl-0">
          <div className="h-full bg-gradient-to-tr from-blue-100 to-white rounded-l-2xl ">
            <div className="w-[150vh] h-[40vh] xl:h-featureBanner lg:h-featureBanner relative xl:absolute lg:absolute top-0 xl:top-5 lg:top-5 left-0 xl:left-5 lg:left-5">
              <img
                src={`${GCP_BUCKET_URL}/rename-okr-development.png`}
                className="w-full h-[80vh]"
                alt="OKR Management with Aligned Rewards"
              />
            </div>
          </div>
        </div>
      </motion.div>

      {/* COUNTERS */}
      <motion.div variants={animation} className="mt-5 xl:mt-10 lg:mt-10">
        <div className="bg-footer bg-opacity-5 py-10">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-10 xl:gap-20 lg:gap-20">
              <div className="col-span-1">
                <CounterComponent
                  title="Enhance Performance Tracking "
                  description="Efficiently monitor employee performance with dynamic inbuilt tools, allowing accurate assessment and targeted feedback for continual improvement. "
                />
              </div>
              <div className="col-span-1">
                <CounterComponent
                  title="Reduce Administrative Burden"
                  description="Organize onboarding processes to reduce administrative workload by 30%, allowing for greater focus on strategic management initiatives."
                />
              </div>
              <div className="col-span-1">
                <CounterComponent
                  title="Amplify Task Organization"
                  description="Simplify task management processes for intensified productivity and collaboration, ensuring clear directives and skillful task completion."
                />
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* STATISTICS - LEFT IMAGE */}
      <motion.div variants={animation} className="py-5 xl:py-14 lg:py-14">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-5 xl:gap-10 lg:gap-10 items-center">
            <div className="col-span-1">
              <div className="w-full h-[50vh] overflow-hidden shadow-md rounded-xl">
                <div className="w-full h-full rounded-xl bg-gradient-to-br from-blue-100 to-white relative">
                  <div className="w-[85vh] h-auto relative xl:absolute lg:absolute top-5 xl:top-14 lg:top-14 -left-5 xl:left-5 lg:left-5">
                    <img
                      src={`${GCP_BUCKET_URL}/okr-management/01.png`}
                      className="w-full h-[55vh] rounded-lg"
                      alt=" Get the overall statistics of your objectives and key results at one place"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1 flex items-center justify-center">
              <div className="w-full xl:w-11/12 lg:w-11/12">
                <h2 className="text-2xl xl:text-3xl lg:text-3xl font-tight-bold text-black leading-snug">
                  Get the overall statistics of your objectives and key results
                  in one place
                </h2>
                <h5 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText mt-3">
                  Access comprehensive statistics for your objectives and key
                  results conveniently centralized in one location
                </h5>
                <div className="mt-5">
                  <div className="flex items-start gap-3">
                    <FaCircleCheck className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText">
                      Get the combined score of all your important key results.
                      It gives you a general idea of how well you're doing
                      overall.
                    </p>
                  </div>
                  <div className="flex items-start gap-3 mt-5">
                    <FaCircleCheck className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText">
                      Gain insights into how much of the time period has already
                      passed for the task/project along with the summary
                      statistics of the objectives and key results.
                    </p>
                  </div>
                </div>
                <div className="mt-5 xl:mt-10 lg:mt-10">
                  <Button
                    className="shadow-none hover:shadow-none rounded-lg bg-brandColor normal-case text-base font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* ALIGNMENT - RIGHT IMAGE */}
      <motion.div
        variants={animation}
        className="py-5 xl:py-14 lg:py-14 bg-footer bg-opacity-5"
      >
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-5 xl:gap-10 lg:gap-10 items-center">
            <div className="col-span-1 order-2 xl:order-1 lg:order-1">
              <div className="w-full xl:w-11/12 lg:w-11/12">
                <h2 className="text-2xl xl:text-3xl lg:text-3xl font-tight-bold text-black leading-snug">
                  Harmonize your teams with the objectives for enhanced outcomes
                </h2>
                <h5 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText mt-3">
                  Maximize results by aligning team efforts with objectives,
                  fostering synergy for improved performance and success.
                </h5>
                <div className="mt-5">
                  <div className="flex items-start gap-3">
                    <FaCircleCheck className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText">
                      Enable your organization to synchronize their objectives
                      with company goals through a dynamic alignment feature.
                    </p>
                  </div>
                  <div className="flex items-start gap-3 mt-3">
                    <FaCircleCheck className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText">
                      Achieve optimal results by facilitating a clear
                      understanding of the company's goals and individuals'
                      contributions towards achieving them.
                    </p>
                  </div>
                </div>
                <div className="mt-5 xl:mt-10 lg:mt-10">
                  <Button
                    className="shadow-none hover:shadow-none rounded-lg bg-brandColor normal-case text-base font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-span-1 order-1 xl:order-2 lg:order-2">
              <div className="w-full h-[50vh] overflow-hidden shadow-md rounded-xl">
                <div className="w-full h-full rounded-xl bg-gradient-to-br from-blue-100 to-white relative">
                  <div className="w-[85vh] h-auto relative xl:absolute lg:absolute top-5 xl:top-14 lg:top-14 -left-5 xl:left-5 lg:left-5">
                    <img
                      src={`${GCP_BUCKET_URL}/okr-management/02.png`}
                      className="w-full h-[55vh] rounded-lg"
                      alt="Harmonize your teams with objectives for enhanced outcomes"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* KEY RESULTS - LEFT IMAGE */}
      <motion.div variants={animation} className="py-5 xl:py-14 lg:py-14">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-5 xl:gap-10 lg:gap-10 items-center">
            <div className="col-span-1">
              <div className="w-full h-[50vh] overflow-hidden shadow-md rounded-xl">
                <div className="w-full h-full rounded-xl bg-gradient-to-br from-blue-100 to-white relative">
                  <div className="w-[85vh] h-auto relative xl:absolute lg:absolute top-5 xl:top-14 lg:top-14 -left-5 xl:left-5 lg:left-5">
                    <img
                      src={`${GCP_BUCKET_URL}/okr-management/03.png`}
                      className="w-full h-[55vh] rounded-lg"
                      alt="Harmonize your teams with objectives for enhanced outcomes"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1 flex items-center justify-center">
              <div className="w-full xl:w-11/12 lg:w-11/12">
                <h2 className="text-2xl xl:text-3xl lg:text-3xl font-tight-bold text-black leading-snug">
                  Various kinds of key results to monitor different types of
                  objectives
                </h2>
                <h5 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText mt-3">
                  Stay on target with your objectives and make progress towards
                  improved productivity, performance, and employee engagement
                  with different measures to track various goals effectively,
                  ensuring you're on target with your objectives and making
                  progress in the right direction.
                </h5>
                <div className="mt-5">
                  <div className="flex items-start gap-3">
                    <FaCircleCheck className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText">
                      Utilize the measurable key result type for quantifiable
                      outcomes and the trackable key result types for those
                      objectives that can't be measured.
                    </p>
                  </div>
                  <div className="flex items-start gap-3 mt-5">
                    <FaCircleCheck className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                    <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText">
                      With our user-friendly interface, you can add the key
                      results easily and track its progress.
                    </p>
                  </div>
                </div>
                <div className="mt-5 xl:mt-10 lg:mt-10">
                  <Button
                    className="shadow-none hover:shadow-none rounded-lg bg-brandColor normal-case text-base font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* FREQUENTLY ASKED QUESTIONS */}
      <FrequentlyAskedQuestions />

      {/* CALL TO ACTION */}
      <CallToAction />

      {/* CALENDLY MODAL */}
      <PopupModal
        open={calendlyModal}
        onModalClose={() => setCalendlyModal(!calendlyModal)}
        rootElement={document.getElementById("root")}
        url="https://calendly.com/contact-ucp8/30min"
      />
    </motion.section>
  );
};

export default OKRManagement;
