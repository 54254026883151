import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Collapse,
} from "@material-tailwind/react";

/* PACKAGES*/
import { motion } from "framer-motion";

/* ANIMATION DATA */
import { animation } from "config";

/* ICONS */
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { TbMenu } from "react-icons/tb";

/* SAMPLE DATA */
import { platformOptions } from "data/layout";

/* CONFIG */
import { GCP_BUCKET_URL } from "config";

const Header = () => {
  const [platformMenu, setPlatformMenu] = useState(false);

  /* SCROLL POSITION */
  const [scrollPosition, setScrollPosition] = useState(0);

  /* HANDLE SCROLL */
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  /* HEADER OPTION */
  const Option = (props) => {
    return (
      <Link
        to={props.route}
        className="block p-3 hover:bg-greyBg rounded-lg group transition-all duration-300 ease-in-out cursor-pointer"
      >
        <div className="flex items-start gap-4">
          <div className="w-10 h-10 bg-brandColor bg-opacity-15 border border-brandColor border-opacity-50 rounded-md flex items-center justify-center flex-shrink-0">
            <props.icon className="w-5 h-5 text-black shrink-0 transition-all duration-300 ease-in-out" />
          </div>
          <div>
            <h5 className="text-base font-proxima-semibold text-black group-hover:text-logoOrange transition-all duration-300 ease-in-out">
              {props.title}
            </h5>
            <p className="text-xs font-proxima-regular text-gray-600 leading-normal mt-1">
              {props.description}
            </p>
          </div>
        </div>
      </Link>
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <motion.header
      variants={animation}
      className={`bg-white shadow-header w-full ${
        scrollPosition > 50 ? "fixed slide-in" : "relative"
      } z-50`}
    >
      {/* BOTTOM HEADER */}
      <div className="py-3 bg-white">
        <div className="container mx-auto">
          <div className="grid grid-cols-4 items-center">
            {/* LOGO */}
            <div className="col-span-1">
              <Link to="/">
                <img
                  src={`${GCP_BUCKET_URL}/aligned-rewards-logo.png`}
                  className="w-auto h-12"
                  alt="Aligned Rewards"
                />
              </Link>
            </div>

            {/* NAVIGATION LINKS */}
            <div className="col-span-2">
              <div className="flex items-center justify-center gap-10">
                <Link
                  to="/"
                  className="block text-base font-proxima-semibold text-black custom-underline"
                >
                  Home
                </Link>

                {/* PLATFORM */}
                <Menu
                  animate={{
                    mount: { y: 0 },
                    unmount: { y: 25 },
                  }}
                  open={platformMenu}
                  handler={() => setPlatformMenu(!platformMenu)}
                >
                  <MenuHandler>
                    <div className="flex items-center gap-1 cursor-pointer">
                      <p className="block text-base font-proxima-semibold text-black custom-underline">
                        Solutions
                      </p>
                      {platformMenu ? (
                        <IoIosArrowUp className="w-3 h-3 text-black" />
                      ) : (
                        <IoIosArrowDown className="w-3 h-3 text-black" />
                      )}
                    </div>
                  </MenuHandler>
                  <MenuList className="w-headerMenu bg-white border border-greyBorder shadow-header rounded-b-2xl rounded-t-none right-0 mt-5 mx-auto">
                    <MenuItem className="outline-none p-0 hover:bg-transparent active:bg-transparent focus:bg-transparent cursor-default">
                      <div className="grid grid-cols-4 gap-5">
                        {platformOptions.map((data) => (
                          <div className="col-span-1" key={data.id}>
                            <Option
                              icon={data.icon}
                              title={data.title}
                              description={data.description}
                              route={data.route}
                            />
                          </div>
                        ))}
                      </div>
                    </MenuItem>
                  </MenuList>
                </Menu>

                {/* INDUSTRIES */}
                {/* <Menu
                  animate={{
                    mount: { y: 0 },
                    unmount: { y: 25 },
                  }}
                  open={industriesMenu}
                  handler={() => setIndustriesMenu(!industriesMenu)}
                >
                  <MenuHandler>
                    <div className="flex items-center gap-1 cursor-pointer">
                      <p className="block text-base font-proxima-semibold text-black custom-underline">
                        Industries
                      </p>
                      {industriesMenu ? (
                        <IoIosArrowUp className="w-3 h-3 text-black" />
                      ) : (
                        <IoIosArrowDown className="w-3 h-3 text-black" />
                      )}
                    </div>
                  </MenuHandler>
                  <MenuList className="w-headerMenu bg-white border border-greyBorder shadow-header rounded-b-2xl rounded-t-none right-10 mt-5 mx-auto">
                    <MenuItem className="outline-none p-0 hover:bg-transparent active:bg-transparent focus:bg-transparent cursor-default">
                      <div className="grid grid-cols-5 gap-5">
                        {industriesOptions.map((data) => (
                          <div className="col-span-1" key={data.id}>
                            <Option
                              icon={data.icon}
                              title={data.title}
                              description={data.description}
                            />
                          </div>
                        ))}
                      </div>
                    </MenuItem>
                  </MenuList>
                </Menu> */}

                {/* <Link
                  to="/pricing"
                  className="block text-base font-proxima-semibold text-black custom-underline"
                >
                  Pricing
                </Link> */}
                <Link
                  to="/blogs"
                  className="block text-base font-proxima-semibold text-black custom-underline"
                >
                  Blogs
                </Link>
                <Link
                  to="/about-us"
                  className="block text-base font-proxima-semibold text-black custom-underline"
                >
                  About Us
                </Link>
              </div>
            </div>

            {/* CALL TO ACTION BUTTONS */}
            <div className="col-span-1">
              <div className="flex items-center justify-end gap-3">
                <Link to="/waitlist">
                  <Button className="shadow-none hover:shadow-none rounded-lg bg-footer normal-case text-sm font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out">
                    Join the waitlist
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.header>
  );
};

const ResponsiveHeader = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [platformMenu, setPlatformMenu] = useState(false);

  /* HEADER OPTION */
  const Option = (props) => {
    return (
      <Link
        to={props.route}
        onClick={() => setMobileMenu(!mobileMenu)}
        className="block p-3 bg-greyBg rounded-lg group transition-all duration-300 ease-in-out cursor-pointer"
      >
        <div className="flex items-start gap-4">
          <div className="w-10 h-10 bg-brandColor bg-opacity-15 border border-brandColor border-opacity-50 rounded-md flex items-center justify-center flex-shrink-0">
            <props.icon className="w-5 h-5 text-black shrink-0 transition-all duration-300 ease-in-out" />
          </div>
          <div>
            <h5 className="text-base font-proxima-semibold text-black group-hover:text-logoOrange transition-all duration-300 ease-in-out">
              {props.title}
            </h5>
            <p className="text-xs font-proxima-regular text-gray-600 leading-normal">
              {props.description}
            </p>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <motion.header className="bg-white py-2 shadow-header z-50">
      <div className="container mx-auto">
        <div className="flex items-center justify-between">
          <Link to="/">
            <img
              src={`${GCP_BUCKET_URL}/aligned-rewards-logo.png`}
              className="w-auto h-12"
              alt="Aligned Rewards"
            />
          </Link>

          {/* MOBILE MENU BUTTON */}
          <Button
            className="shadow-none hover:shadow-none p-2 rounded-full bg-white border border-greyText"
            onClick={() => setMobileMenu(!mobileMenu)}
          >
            <TbMenu className="w-5 h-5 text-greyText" />
          </Button>
        </div>
      </div>

      {/* MOBILE MENU */}
      <Collapse
        open={mobileMenu}
        placement="bottom"
        animate={{
          mount: { opacity: 1, y: 0 },
          unmount: { opacity: 0, y: 25 },
        }}
        className="mt-2"
      >
        <div className="p-5 border-t border-greyBorder">
          <Link
            to="/"
            className="block text-base font-proxima-semibold text-black custom-underline mt-5 xl:mt-0 lg:mt-0"
            onClick={() => setMobileMenu(!mobileMenu)}
          >
            Home
          </Link>

          <div className="flex items-center gap-2 mt-5">
            <p
              className="block text-base font-proxima-semibold text-black cursor-pointer"
              onClick={() => setPlatformMenu(!platformMenu)}
            >
              Platform
            </p>
            {platformMenu ? (
              <IoIosArrowUp className="w-3 h-3 text-black" />
            ) : (
              <IoIosArrowDown className="w-3 h-3 text-black" />
            )}
          </div>

          <Collapse open={platformMenu}>
            <div className="p-0">
              {platformOptions.map((data) => (
                <div className="mt-2" key={data.id}>
                  <Option
                    icon={data.icon}
                    title={data.title}
                    description={data.description}
                    route={data.route}
                  />
                </div>
              ))}
            </div>
          </Collapse>

          {/* <div className="flex items-center gap-2 mt-5">
            <p
              className="block text-base font-proxima-semibold text-black cursor-pointer"
              onClick={() => setIndustriesMenu(!industriesMenu)}
            >
              Industries
            </p>
            {industriesMenu ? (
              <IoIosArrowUp className="w-3 h-3 text-black" />
            ) : (
              <IoIosArrowDown className="w-3 h-3 text-black" />
            )}
          </div> */}

          {/* <Collapse open={industriesMenu}>
            <div className="p-0">
              {industriesOptions.map((data) => (
                <div className="mt-2" key={data.id}>
                  <Option
                    icon={data.icon}
                    title={data.title}
                    description={data.description}
                  />
                </div>
              ))}
            </div>
          </Collapse> */}

          {/* <Link
            to="/pricing"
            className="block text-base font-proxima-semibold text-black custom-underline mt-5 xl:mt-0 lg:mt-0"
            onClick={() => setMobileMenu(!mobileMenu)}
          >
            Pricing
          </Link> */}
          <Link
            to="/blogs"
            className="block text-base font-proxima-semibold text-black custom-underline mt-5 xl:mt-0 lg:mt-0"
            onClick={() => setMobileMenu(!mobileMenu)}
          >
            Blogs
          </Link>
          <Link
            to="/about-us"
            className="block text-base font-proxima-semibold text-black custom-underline mt-5 xl:mt-0 lg:mt-0"
            onClick={() => setMobileMenu(!mobileMenu)}
          >
            About Us
          </Link>

          <Link to="/waitlist">
            <Button className="mt-5 xl:mt-0 lg:mt-0 shadow-none hover:shadow-none rounded-lg bg-footer normal-case text-sm font-proxima-semibold text-white hover:bg-opacity-80 transition-all duration-300 ease-in-out">
              Join the waitlist
            </Button>
          </Link>
        </div>
      </Collapse>
    </motion.header>
  );
};

export { Header, ResponsiveHeader };
