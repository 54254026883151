import request from "./request";

/* WAITLIST API */
export const waitListAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/waiting-list", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* NEWSLETTER API */
export const newsletterAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/newsletter", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
