import React, { useState } from "react";
import { Link } from "react-router-dom";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import { PopupModal } from "react-calendly";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* CONFIG */
import { animation, containerAnimation, GCP_BUCKET_URL } from "config";

/* ICONS */
import { MdOutlineArrowOutward } from "react-icons/md";
import { BsCalendar2DateFill } from "react-icons/bs";

/* FORM VALIDATIONS */
import * as yup from "yup";
import { useFormik } from "formik";

/* API */
import { newsletterAPI } from "service/api";

/* VALIDATION SCHEMA */
const waitlistValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .min(1, "Email Address is required")
    .required("Email Address is required."),
});

/* TAGS */
const tags = [
  {
    id: 1,
    name: "Task Management",
    link: "/blogs/task-management",
  },
  {
    id: 2,
    name: "Employee Engagement",
    link: "/blogs/employee-engagement",
  },
  {
    id: 3,
    name: "Performance Management",
    link: "/blogs/performance-management",
  },
  {
    id: 4,
    name: "Change Management",
    link: "/blogs/change-management",
  },
  {
    id: 5,
    name: "OKR Management",
    link: "/blogs/okr-management",
  },
  {
    id: 6,
    name: "Project Planning",
    link: "/blogs/project-planning",
  },
  {
    id: 7,
    name: "Project Management",
    link: "/blogs/project-management",
  },
  {
    id: 8,
    name: "Team collaboration",
    link: "/blogs/team-collaboration",
  },
];

const EmployeeEngagement = () => {
  const [calendlyModal, setCalendlyModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  /* FORM HANDLING */
  const formHandling = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: waitlistValidation,
    onSubmit: (values) => {
      const obj = {
        email: values.email,
      };
      setFormLoading(true);
      newsletterAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(
              "Congratulations! 🎉 You have subscribed to our newsletter."
            );
          } else {
            toast.error(
              "We were not able to capture your details. Please try again."
            );
          }
          setFormLoading(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formHandling.resetForm();
          setFormLoading(false);
        });
    },
  });

  /* SMALL TAGS BUTTON */
  const SmallTagsButton = (props) => {
    return (
      <Link to={props.link}>
        <Button className="shadow-none hover:shadow-none bg-greyBg hover:bg-logoOrange rounded-md text-sm font-proxima-regular text-greyText hover:text-white normal-case font-medium px-4 py-2 transition-all duration-300 ease-in-out">
          {props.label}
        </Button>
      </Link>
    );
  };

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* CONTENT */}
      <motion.div variants={animation} className="py-5 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
            {/* CONTENT */}
            <div className="col-span-2 border-r-0 xl:border-r lg:border-r border-greyBorder">
              <div className="pr-0 xl:pr-8 lg:pr-8">
                <img
                  src={`${GCP_BUCKET_URL}/blogs/employee-engagement/employee-engagement-inner-banner.webp`}
                  className="rounded-xl object-cover h-[20vh] xl:h-[30vh] lg:h-[30vh] w-full shadow"
                  alt="Proven Strategies to Boost Employee Engagement"
                />

                <div className="mt-5">
                  <h1 className="mt-5 text-xl xl:text-3xl lg:text-3xl font-tight-bold text-black">
                    Proven Strategies to Boost Employee Engagement
                  </h1>
                  <p className="mt-3 text-base font-proxima-regular text-greyText leading-relaxed">
                    In the modern day, when the business environment is more
                    dynamic and competition fierce, companies increasingly
                    understand that the engagement of employees is a vital part
                    of effective management aimed at the development of the
                    company. An engaged workforce of this caliber not only
                    intensifies its productivity and creativity but also
                    increases the organization’s commitment to going after its
                    aims. In this article, we will discover the bottom line of
                    employee engagement and its myriad effects on the
                    performance level of the company, including the strategies
                    to create a supportive environment where the workforce will
                    feel more engaged.
                  </p>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      What is Employee Engagement?
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Employee engagement does not imply being just pleased with
                      the job; rather, it includes the personal commitment and
                      emotional connection that employees have to their job,
                      colleagues, and the company. Along with the engaged staff,
                      the ones who involve themselves completely in the success
                      of the organization, are motivated to give their 10x
                      efforts, and are aligned with the company’s core values
                      and objectives are part of the company’s success. They are
                      more content, efficient, and reliable, all of which are
                      factors leading to successful results and contribute to
                      the organization's success.
                    </p>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      The Impact of Employee Engagement on Goals and Revenue
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      To illustrate the profound impact of employee engagement,
                      let's examine the contrasting experiences of a less
                      engaged employee versus a highly engaged employee:
                    </p>
                    <div className="mt-3">
                      <h5 className="text-base xl:text-lg lg:text-lg font-tight-bold text-black">
                        Less Engaged Employee:
                      </h5>
                      <ol className="mt-3 ml-8 list-decimal">
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Trying to make sense of their assignments and achieve
                          the desired goal, nothing gets in their way, and they
                          are overly engaged, which often results in a mediocre
                          performance.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Feels a lack of identification with the organization’s
                          goals, which in turn makes it impossible to get
                          involved and work for its promotion.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Their lower productivity and innovation signatures
                          degrade the organization's performance, and as a
                          result, it ceases to achieve its goals and boost
                          revenue.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Might experience a higher employee's turnover and
                          absence rate, which will result in further
                          dissatisfaction with working conditions and a decrease
                          in productivity.
                        </li>
                      </ol>
                    </div>
                    <div className="mt-3">
                      <h5 className="text-base xl:text-lg lg:text-lg font-tight-bold text-black">
                        Highly Engaged Employee:
                      </h5>
                      <ol className="mt-3 ml-8 list-decimal">
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          With a strong sense of responsibility towards job
                          satisfaction and motivation, this individual will
                          spend more than is traditionally required to
                          contribute to the organization's success.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          It happens when employees put their own goals, values,
                          and beliefs in the same row as those of the
                          organization, as a result of which they obtain a
                          really deep sense of commitment and dedication to the
                          organization.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          Displays higher self-efficacy, which translates into
                          greater productivity, creativity, and innovation,
                          hence better delivery and performance.
                        </li>
                        <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                          It helps in the development of good interpersonal
                          relations in the workplace and ensures healthy team
                          engagement, which further leads to the development of
                          a good work culture.
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Employee Engagement Strategies for an Improved Workplace
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Engaging employees surely produces benefits that are all
                      too obvious; nevertheless, creating a working environment
                      that supports employee engagement is laborious and
                      requires targeted initiatives on the part of
                      organizational management as well. Here are some proven
                      strategies to enhance employee engagement in the
                      workplace:
                    </p>
                    <ol className="mt-3 ml-8 list-decimal">
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-semibold text-black">
                          Effective Communication:
                        </span>{" "}
                        Set up open and meaningful communication paths so that
                        the staff members are kept aware of the company’s news,
                        records, and goals. Generate dialogue and responses with
                        a view to cultivating an atmosphere of teamwork.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Recognition and Rewards:
                        </span>{" "}
                        As well as making good employees happier and more
                        motivated, a culture of appreciation for their
                        contributions and efforts is necessary to build. Build a
                        formal recognition system that serves to mark
                        achievements, significant milestones, and heroic deeds.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Opportunities for Growth:
                        </span>{" "}
                        Devise ways to enhance meaningful, innovative training
                        and career growth. Enable and help employees perceive
                        the value of self-improvement, knowledge-gaining, and
                        the aim for career development within the company.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Work-Life Balance:
                        </span>{" "}
                        Create work-life solidarity by providing flexible work
                        windows, wellness programs, and resources to help
                        employees achieve health equity. Have the insight that
                        the key to avoiding morale and job dissatisfaction is to
                        achieve a balance between life at work and at home.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Meaningful Work:
                        </span>{" "}
                        Encourage employees to be aware of the impact of their
                        job and understand how they contribute to achieving the
                        organization's objectives. Adjust tasks, duties, and
                        abilities to employee skills, interests, and values to
                        induce naturalness and satisfaction in their roles.
                      </li>
                    </ol>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      The Benefits of a Highly Engaged Workforce
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Employee involvement is not only a source of personal
                      satisfaction and the urge to stay loyal to a company, but
                      it also contributes to other greater net benefits.
                      Organizations that prioritize employee engagement
                      experience a wide range of benefits, including:
                    </p>
                    <ol className="mt-3 ml-8 list-decimal">
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-semibold text-black">
                          Higher Employee Retention:
                        </span>{" "}
                        An employee whose engagement is higher is most likely to
                        remain with the organization. Ultimately, the
                        organization saves on the expenses that come with
                        turnover, and organizational structure is eliminated.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Increased Productivity:
                        </span>{" "}
                        Engaged employees generally have strong feelings of
                        self-efficacy and motivation, which in turn result in
                        greater productivity and accomplishment.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Enhanced Customer Satisfaction:
                        </span>{" "}
                        Employees that are more passively engaged deliver
                        outstanding customer service and create more bonds with
                        customers, which is an ingredient of success in business
                        through long-term relationships.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Innovation and Creativity:
                        </span>{" "}
                        Employees engaged in the work they do are more creative,
                        proactive, and offer fresh ideas and ways to deal with
                        issues, so the organization is not only getting feedback
                        but also creative methods of running business.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Improved Financial Performance:
                        </span>{" "}
                        Businesses with thriving workforces that are more
                        engaged also record high growth in their revenues,
                        profits, and shareholder value compared to those whose
                        workforce engagement is low.
                      </li>
                    </ol>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Introducing Employee Engagement: Your Partner in Employee
                      Engagement
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Aligned Rewards is an all-encompassing software that works
                      to cultivate a culture of teamwork, facilitate
                      objective-setting processes, and make sure that employees
                      achieve the set objectives. It is also built to create
                      motivation and enhance productivity in the workplace.
                      Organizations can enhance the overall employee commitment
                      level by implementing software with advantages such as
                      performance evaluation, goal management, and employee
                      recognition.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      To sum up, employee engagement is as key as squeezing the
                      staff of large companies or expanding the workforce, i.e.,
                      all these organizations must maintain employee engagement
                      to survive in the highly competitive business environment.
                      Through the use of employee participation enhancement
                      strategies coupled with the merit of Aligned Rewards
                      tools, companies can get out the best of their workforce,
                      which can also drive the company to greater achievements.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* SIDEBAR */}
            <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0">
              <div className="pl-0 xl:pl-8 lg:pl-8  h-fit sticky top-24">
                {/* NEWSLETTER */}
                {/* NEWSLETTER */}
                <motion.div
                  variants={animation}
                  className="pb-8 border-b border-greyBorder"
                >
                  <div
                    variants={animation}
                    className="bg-greyBg bg-opacity-50 border border-greyBorder rounded-lg p-5 xl:p-8 lg:p-8"
                  >
                    <h5 className="text-2xl font-tight-bold">
                      Join Our Community
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3">
                      Join us so that we can send you updates on new features,
                      valuable tips, expert strategies and our exclusive offers
                      delivered directly to your inbox.
                    </p>
                    <form className="mt-5" onSubmit={formHandling.handleSubmit}>
                      <input
                        type="email"
                        placeholder="Type you email address"
                        name="newsletterEmail"
                        id="newsletterEmail"
                        value={formHandling.values.email}
                        onChange={formHandling.handleChange}
                        className={`w-full block bg-white px-4 py-3 rounded-lg text-sm font-proxima-semibold text-black border ${
                          formHandling.errors.email &&
                          formHandling.touched.email
                            ? "border-red-500"
                            : "border-greyBorder"
                        } border-greyBorder focus:outline-brandColor placeholder:font-proxima-regular`}
                      />
                      <Button
                        disabled={formLoading}
                        type={formLoading ? "button" : "submit"}
                        className="group mt-3 w-full flex items-center gap-1 justify-center shadow-none hover:shadow-none font-proxima-semibold text-white rounded-md bg-brandColor normal-case text-sm hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                      >
                        {formLoading ? "Please wait.." : "Subscribe"}
                        <MdOutlineArrowOutward className="w-4 h-4 text-white" />
                      </Button>
                    </form>
                  </div>
                </motion.div>

                {/* Schedule a Demo */}
                <motion.div variants={animation} className="pt-8">
                  <BsCalendar2DateFill className="w-10 h-10 text-black" />
                  <h5 className="mt-3 text-2xl font-tight-bold leading-snug">
                    Learn how Aligned Rewards can be useful for your
                    organization
                  </h5>
                  <p className="mt-3 text-base font-proxima-regular text-greyText">
                    Experience a guided walkthrough of all our features in this
                    exclusive video!
                  </p>
                  <Button
                    className="mt-5 shadow-none hover:shadow-none bg-brandColor text-white font-proxima-semibold text-sm normal-case"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </motion.div>

                {/* TAGS */}
                <motion.div variants={animation} className="pt-8 hidden">
                  <h5 className="text-xl xl:text-2xl lg:text-2xl font-tight-bold text-black tracking-tight">
                    Tags
                  </h5>
                  <div className="flex items-center flex-wrap gap-2 mt-5">
                    {tags.map((item) => (
                      <React.Fragment key={item.id}>
                        <SmallTagsButton label={item.name} link={item.link} />
                      </React.Fragment>
                    ))}
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALENDLY MODAL */}
      <PopupModal
        open={calendlyModal}
        onModalClose={() => setCalendlyModal(!calendlyModal)}
        rootElement={document.getElementById("root")}
        url="https://calendly.com/contact-ucp8/30min"
      />
    </motion.section>
  );
};

export default EmployeeEngagement;
