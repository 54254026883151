import React, { useState } from "react";
import { Link } from "react-router-dom";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import { PopupModal } from "react-calendly";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* CONFIG */
import { animation, containerAnimation } from "config";

/* ICONS */
import { MdOutlineArrowOutward } from "react-icons/md";
import { BsCalendar2DateFill } from "react-icons/bs";

/* FORM VALIDATIONS */
import * as yup from "yup";
import { useFormik } from "formik";

/* API */
import { newsletterAPI } from "service/api";

/* VALIDATION SCHEMA */
const waitlistValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .min(1, "Email Address is required")
    .required("Email Address is required."),
});

/* TAGS */
const tags = [
  {
    id: 1,
    name: "Task Management",
    link: "/blogs/task-management",
  },
  {
    id: 2,
    name: "Employee Engagement",
    link: "/blogs/employee-engagement",
  },
  {
    id: 3,
    name: "Performance Management",
    link: "/blogs/performance-management",
  },
  {
    id: 4,
    name: "Change Management",
    link: "/blogs/change-management",
  },
  {
    id: 5,
    name: "OKR Management",
    link: "/blogs/okr-management",
  },
  {
    id: 6,
    name: "Project Planning",
    link: "/blogs/project-planning",
  },
  {
    id: 7,
    name: "Project Management",
    link: "/blogs/project-management",
  },
  {
    id: 8,
    name: "Team collaboration",
    link: "/blogs/team-collaboration",
  },
];

const GoalManagementTwo = () => {
  const [calendlyModal, setCalendlyModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  /* FORM HANDLING */
  const formHandling = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: waitlistValidation,
    onSubmit: (values) => {
      const obj = {
        email: values.email,
      };
      setFormLoading(true);
      newsletterAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(
              "Congratulations! 🎉 You have subscribed to our newsletter."
            );
          } else {
            toast.error(
              "We were not able to capture your details. Please try again."
            );
          }
          setFormLoading(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formHandling.resetForm();
          setFormLoading(false);
        });
    },
  });

  /* SMALL TAGS BUTTON */
  const SmallTagsButton = (props) => {
    return (
      <Link to={props.link}>
        <Button className="shadow-none hover:shadow-none bg-greyBg hover:bg-logoOrange rounded-md text-sm font-proxima-regular text-greyText hover:text-white normal-case font-medium px-4 py-2 transition-all duration-300 ease-in-out">
          {props.label}
        </Button>
      </Link>
    );
  };

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* CONTENT */}
      <motion.div variants={animation} className="py-5 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
            {/* CONTENT */}
            <div className="col-span-2 border-r-0 xl:border-r lg:border-r border-greyBorder">
              <div className="pr-0 xl:pr-8 lg:pr-8">
                <h1 className="mt-5 text-xl xl:text-3xl lg:text-3xl font-tight-bold text-black leading-snug">
                  4 Effective Goal Setting Techniques for Enhanced Workplace
                  Productivity
                </h1>

                <div className="mt-5">
                  <p className="mt-3 text-base font-proxima-regular text-greyText leading-relaxed">
                    Goals are the landmarks that determine the way towards the
                    goal. Whether it is an increase in productivity, attaining
                    strategic objectives, or any other improvement in
                    innovation, paying attention to detailed objectives is the
                    only way to move ahead if your goal is favorable and concise
                    progress. In the fast-paced environment of business, where
                    the competition is tough and the demands are quite rigorous,
                    companies ought to use some effective goal-setting
                    strategies to accomplish this task. This all-encompassing
                    guide will explore various goal setting techniques and how
                    they can be used to grow teams and a business as a whole.
                  </p>

                  <div className="mt-5">
                    <h2 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      1. Setting SMART Goals
                    </h2>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      One of the most commonly applied and successful
                      goal-setting types is the SMART method. SMART includes
                      components like specific, measurable, achievable,
                      relevant, and time-bound. This approach allows for the
                      setting of objectives that are clear, achievable, and
                      aligned with the organization’s mission.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="font-proxima-bold">Specific</span> Goals
                      should be defined and exact as much as possible, not
                      leaving anything up to interpretation. In addition,
                      instead of having a general goal such as "improve sales,"
                      a more precise one will be "have 15% sales growth monthly.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="font-proxima-bold">Measurable:</span>{" "}
                      Goals need to be quantifiable for clear and transparent
                      progress to be tracked and measured. To get a metric on
                      revenue, customer satisfaction scores, or project
                      completion rates is a way to track the performance of the
                      teams.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="font-proxima-bold">Achievable:</span>{" "}
                      Goals must be set based on realizable resources and
                      budgets that are available. Setting up for huge goals can
                      bring frustration and a loss of motivation. It's crucial
                      to find the appropriate level of demand between difficult
                      and attainable goals.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="font-proxima-bold">Relevant:</span> Goals
                      should relate to the overall goals of the organization and
                      long-term strategies. They need to be aligned with the
                      overarching objectives and show how they address the areas
                      of promised change or growth.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="font-proxima-bold">Time bound:</span> The
                      timeline or time frame of goals should be defined. Having
                      deadlines provides a sense of urgency and responsibility
                      so that the completion of tasks needed will be faster.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="font-proxima-bold">Example:</span> In
                      this case, a marketing team may have NYLT, or "Increase
                      website traffic by 20% within the next six months by
                      implementing SEO optimization strategies and content
                      marketing initiatives.
                    </p>
                  </div>

                  <div className="mt-10">
                    <h2 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      2. OKR (Objectives and Key Results)
                    </h2>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      After being introduced by Intel and implemented by Google,
                      OKR (Objectives and Key Results) is an effective goal
                      setting technique now commonly applied by corporations all
                      over the world. OKR stands for objectives and key results.
                      This process is designed to include the setting of
                      aspirational but realistic goals and tracking progress
                      through critical results. Here's how it works:
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="font-proxima-bold">Objectives: </span>{" "}
                      Objectives are audacious and tend to take the form of what
                      needs to be achieved rather than how it will be achieved.
                      That is their role—to share direction and meaning,
                      allowing the team to achieve a shared vision.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="font-proxima-bold">Key Results:</span>{" "}
                      The key results are the core, measurable metrics that show
                      us how well we are doing to reach the objectives. These
                      are the points of reference or markers, indicating whether
                      the organization is getting closer or farther from its
                      goals.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="font-proxima-bold">Example:</span> The
                      purpose of an e-commerce company may be "to increase the
                      number of repeat customers and their loyalty." " A key
                      outcome could be "customer satisfaction score boosted by
                      10 points decreased by 15%," and "achieve Net Promoter
                      Score (NPS) 8 or above.
                    </p>
                  </div>

                  <div className="mt-10">
                    <h2 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      3. Pareto Principle (80/20 Rule)
                    </h2>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      The Pareto Principle, also popularly called the 80/20
                      Rule, suggests that 20% causes bring about the majority of
                      the effects. This principle becomes true for the setting
                      of targets, which is geared towards taking advantage of
                      the activities that are very productive and more likely to
                      lead to the desired results.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="font-proxima-bold">
                        Identify high-impact activities:{" "}
                      </span>{" "}
                      Analyze the data and determine the top 20 percent of
                      activities that contribute to the most outcomes or
                      results.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="font-proxima-bold">
                        Allocate resources wisely:
                      </span>{" "}
                      Identify resources, time, and energy and utilize them for
                      the most influential activities by ensuring that there are
                      no unnecessary activities.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="font-proxima-bold">
                        Continuously evaluate and adjust:{" "}
                      </span>
                      Track performance on a periodic basis and adapt methods
                      according to the data and experience of all the players
                      involved in the process to make the process most
                      effective.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="font-proxima-bold">Example: </span>A
                      software development team may be able to pinpoint software
                      features of the kind that occupy 20% of an entire software
                      account and account for as much as 80% of user engagement.
                      The team may achieve this goal of driving high user
                      satisfaction and product success by concentrating on the
                      practical implementation of these impactful ingredients
                      and allocating the resources properly.
                    </p>
                  </div>

                  <div className="mt-10">
                    <h2 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      4. Agile Goal Setting Technique
                    </h2>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      With ever-changing and unpredictable business conditions,
                      the agile goal-setting type provides flexibility and
                      adaptability to the extent that organizations can respond
                      instantly to minor or major changes as well as
                      uncertainties. An agile method of goal setting technique
                      engages the teamwork process, experimentation, and
                      continuous improvement. Here's how it works:
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="font-proxima-bold">
                        Set short-term goals:{" "}
                      </span>{" "}
                      Divide the long-term objectives into goals that are
                      achievable within short time frames, preferably two to
                      four weeks.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="font-proxima-bold">Embrace Change:</span>{" "}
                      Embrace change and uncertainties as an inherent factor of
                      the business process, adjusting the goals and strategy
                      according to the results of feedback, the dynamics of the
                      market, and new opportunities.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="font-proxima-bold">
                        Foster Collaboration:{" "}
                      </span>
                      Make sure the interdepartmental communication is effective
                      by making the people collaborate and share the same goals.
                      This can be achieved through knowledge and information
                      sharing, which is in the interest of everyone.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <span className="font-proxima-bold">Example: </span>The
                      digital marketing team tends to set SMART (Specific,
                      Measurable, Attainable, Relevant, and Time-Bound) biweekly
                      goals to release and test the newest marketing
                      physiognomies. Their daily activity involves tracking
                      campaign performance, obtaining feedback, and modifying
                      strategies every time based on the current data in order
                      to fine-tune the campaign and ensure the maximum return on
                      investment.
                    </p>
                  </div>

                  <div className="mt-10">
                    <h2 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Aligned Rewards to Achieve Goals
                    </h2>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      In conclusion, it is very important to include one of
                      these good goal-setting techniques to not only increase
                      productivity efficiency but to also maintain
                      organizational success. In addition to establishing goals,
                      doing it alone is not sufficient. For employees, teams,
                      and companies to experience the real power of team
                      empowerment and motivation, organizations must reward and
                      motivate employees in close relation to the achievement of
                      company objectives.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Sign up on{" "}
                      <Link to="/" className="text-blue-500 font-proxima-bold">
                        www.alignedrewards.com
                      </Link>{" "}
                      to make goal setting a practice, and enable your teams to
                      accomplish your company's goals!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* SIDEBAR */}
            <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0">
              <div className="pl-0 xl:pl-8 lg:pl-8  h-fit sticky top-24">
                {/* NEWSLETTER */}
                <motion.div
                  variants={animation}
                  className="pb-8 border-b border-greyBorder"
                >
                  <div
                    variants={animation}
                    className="bg-greyBg bg-opacity-50 border border-greyBorder rounded-lg p-5 xl:p-8 lg:p-8"
                  >
                    <h5 className="text-2xl font-tight-bold">
                      Join Our Community
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3">
                      Join us so that we can send you updates on new features,
                      valuable tips, expert strategies and our exclusive offers
                      delivered directly to your inbox.
                    </p>
                    <form className="mt-5" onSubmit={formHandling.handleSubmit}>
                      <input
                        type="email"
                        placeholder="Type you email address"
                        name="newsletterEmail"
                        id="newsletterEmail"
                        value={formHandling.values.email}
                        onChange={formHandling.handleChange}
                        className={`w-full block bg-white px-4 py-3 rounded-lg text-sm font-proxima-semibold text-black border ${
                          formHandling.errors.email &&
                          formHandling.touched.email
                            ? "border-red-500"
                            : "border-greyBorder"
                        } border-greyBorder focus:outline-brandColor placeholder:font-proxima-regular`}
                      />
                      <Button
                        disabled={formLoading}
                        type={formLoading ? "button" : "submit"}
                        className="group mt-3 w-full flex items-center gap-1 justify-center shadow-none hover:shadow-none font-proxima-semibold text-white rounded-md bg-brandColor normal-case text-sm hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                      >
                        {formLoading ? "Please wait.." : "Subscribe"}
                        <MdOutlineArrowOutward className="w-4 h-4 text-white" />
                      </Button>
                    </form>
                  </div>
                </motion.div>

                {/* Schedule a Demo */}
                <motion.div variants={animation} className="pt-8">
                  <BsCalendar2DateFill className="w-10 h-10 text-black" />
                  <h5 className="mt-3 text-2xl font-tight-bold leading-snug">
                    Learn how Aligned Rewards can be useful for your
                    organization
                  </h5>
                  <p className="mt-3 text-base font-proxima-regular text-greyText">
                    Experience a guided walkthrough of all our features in this
                    exclusive video!
                  </p>
                  <Button
                    className="mt-5 shadow-none hover:shadow-none bg-brandColor text-white font-proxima-semibold text-sm normal-case"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </motion.div>

                {/* TAGS */}
                <motion.div variants={animation} className="pt-8 hidden">
                  <h5 className="text-xl xl:text-2xl lg:text-2xl font-tight-bold text-black tracking-tight">
                    Tags
                  </h5>
                  <div className="flex items-center flex-wrap gap-2 mt-5">
                    {tags.map((item) => (
                      <React.Fragment key={item.id}>
                        <SmallTagsButton label={item.name} link={item.link} />
                      </React.Fragment>
                    ))}
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALENDLY MODAL */}
      <PopupModal
        open={calendlyModal}
        onModalClose={() => setCalendlyModal(!calendlyModal)}
        rootElement={document.getElementById("root")}
        url="https://calendly.com/contact-ucp8/30min"
      />
    </motion.section>
  );
};

export default GoalManagementTwo;
