/* eslint-disable no-unused-vars */
import { Link } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* ICONS */
import { IoSearch } from "react-icons/io5";
import { GoArrowUpRight } from "react-icons/go";
import { MdAccessTimeFilled } from "react-icons/md";

/* DATA */
import { GCP_BUCKET_URL } from "config";

const Searchbar = (props) => {
  return (
    <div className="bg-greyBg rounded-md px-4 py-2 relative">
      <input
        type={props.type}
        name={props.name}
        id={props.id}
        placeholder="Search blogs"
        value={props.value}
        onChange={props.onChange}
        className="outline-none bg-transparent p-0 focus:outline-none text-sm font-proxima-regular text-black"
      />

      <Button className="!absolute top-1 right-1 shadow-none hover:shadow-none rounded-full p-2 bg-transparent hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out font-proxima-regular">
        <IoSearch className="w-4 h-4 text-greyText" />
      </Button>
    </div>
  );
};

const CategoryBox = (props) => {
  return (
    <Link to={props.link} className="group">
      <div className="w-full bg-white group-hover:bg-brandColor group-hover:bg-opacity-10 border border-greyBorder group-hover:border-brandColor rounded-xl py-3 px-5 flex items-center justify-between transition-all duration-300 ease-in-out">
        <div className="pr-5">
          <h5 className="text-base font-proxima-semibold text-black">
            {props.categoryName}
          </h5>
        </div>
        <Button className="shadow-none hover:shadow-none bg-white group-hover:bg-brandColor group-hover:text-white text-greyText rounded-full border group-hover:border-brandColor border-greyBorder p-2 transition-all duration-300 ease-in-out">
          <GoArrowUpRight className="w-5 h-5" />
        </Button>
      </div>
    </Link>
  );
};

const BlogBox = (props) => {
  /* BLOG TAGS */
  const BlogsTags = (props) => {
    return (
      <div className="px-3 py-1 rounded bg-brandColor w-fit">
        <p className="text-sm font-proxima-semibold text-white">
          {props.label}
        </p>
      </div>
    );
  };

  return (
    <Link to={props.link}>
      <div className="bg-white hover:bg-brandColor hover:bg-opacity-10 rounded-lg cursor-pointer p-3 transition-all duration-300 ease-in-out">
        <div className="relative">
          <img
            src={props.coverImg}
            className="w-full h-[25vh] object-cover rounded-md"
            alt={props.title}
          />
          {/* TIME TO READ */}
          {/* <div className="bg-white w-fit rounded px-2 py-1 flex items-center gap-1 absolute top-2 right-2">
            <MdAccessTimeFilled className="w-4 h-4 text-black" />
            <p className="text-sm font-proxima-semibold text-black">
              {props.time} min
            </p>
          </div> */}
        </div>
        <div className="mt-5">
          {/* <BlogsTags label={props.label} /> */}
          <h5 className="mt-3 text-xl font-tight-bold leading-tight">
            {props.title}
          </h5>
          <p className="mt-2 text-base font-proxima-regular text-greyText">
            {props.description}
          </p>
          <div className="mt-5 flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="w-8 h-8 rounded-full">
                <img
                  src={`${GCP_BUCKET_URL}/blogs/authors/${props.profile}`}
                  className="w-full h-full object-cover rounded-full"
                  alt={props.name}
                />
              </div>
              <div>
                <p className="text-sm font-proxima-semibold text-black">
                  {props.name}
                </p>
                <p className="text-xs font-proxima-regular text-greyText">
                  {props.designation}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export { Searchbar, CategoryBox, BlogBox };
