/* REACT IMPORTS */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

/* PACKAGES */
import { AnimatePresence } from "framer-motion";

/* STYLES */
import "styles/global.css";

/* LAYOUT */
import Layout from "layout";

/* PAGES */
import Homepage from "pages";
import Blogs from "pages/blogs";
import WaitingList from "pages/waitlist";
import Pricing from "pages/pricing";
import AboutUs from "pages/about-us";

/* PLATFORM PAGES */
import OKRManagement from "pages/platform/goal-management";
import PerformanceManagement from "pages/platform/performance-management";
import TaskManagement from "pages/platform/task-management";
import EmployeeEngagementPage from "pages/platform/employee-engagement";

/* BLOGS */
import WhatIsOKR from "pages/articles/what-is-okr";
import EmployeeEngagement from "pages/articles/how-to-improve-employee-engagement-at-your-workplace";
import EmployeeEngagementTwo from "pages/articles/8-key-elements-of-employee-engagement-for-workplace-motivation";
import PerformanceManagementBlog from "pages/articles/a-comprehensive-guide-to-revolutionizing-performance-management";
import GoalsManagement from "pages/articles/how-do-you-set-goals-in-your-organization";
import GoalManagementTwo from "pages/articles/4-effective-goal-setting-techniques-for-enhanced-workplace-productivity";
import RewardingWorkplace from "pages/articles/how-to-cultivate-a-rewarding-workplace-with-employee-rewards";
import WorkplaceCommunication from "pages/articles/10-powerful-tips-for-effective-workplace-communication";
import LeadershipQualitites from "pages/articles/7-top-leadership-qualities-for-modern-managers";
import BenefitsRemote from "pages/articles/the-benefits-of-remote-work-how-to-maintain-productivity-and-balance";
import PrivacyPolicy from "pages/privacy-policy";

/* SCROLL TO TOP */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRoutes = () => {
  return (
    <div className="app">
      <AnimatePresence>
        <Router>
          <ScrollToTop />
          <Layout>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/waitlist" element={<WaitingList />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/about-us" element={<AboutUs />} />

              {/* PLATFORM */}
              <Route path="/goal-management" element={<OKRManagement />} />
              <Route
                path="/performance-management"
                element={<PerformanceManagement />}
              />
              <Route path="/tasks-management" element={<TaskManagement />} />
              <Route
                path="/employee-engagement"
                element={<EmployeeEngagementPage />}
              />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />

              {/* BLOGS */}
              <Route path="/what-is-okr" element={<WhatIsOKR />} />
              <Route
                path="/how-to-improve-employee-engagement-at-your-workplace"
                element={<EmployeeEngagement />}
              />
              <Route
                path="/a-comprehensive-guide-to-revolutionizing-performance-management"
                element={<PerformanceManagementBlog />}
              />
              <Route
                path="/how-do-you-set-goals-in-your-organization"
                element={<GoalsManagement />}
              />
              <Route
                path="/how-to-cultivate-a-rewarding-workplace-with-employee-rewards"
                element={<RewardingWorkplace />}
              />
              <Route
                path="/8-key-elements-of-employee-engagement-for-workplace-motivation"
                element={<EmployeeEngagementTwo />}
              />
              <Route
                path="/4-effective-goal-setting-techniques-for-enhanced-workplace-productivity"
                element={<GoalManagementTwo />}
              />
              <Route
                path="/10-powerful-tips-for-effective-workplace-communication"
                element={<WorkplaceCommunication />}
              />
              <Route
                path="/7-top-leadership-qualities-for-modern-managers"
                element={<LeadershipQualitites />}
              />
              <Route
                path="/the-benefits-of-remote-work-how-to-maintain-productivity-and-balance"
                element={<BenefitsRemote />}
              />
            </Routes>
          </Layout>
        </Router>
      </AnimatePresence>
    </div>
  );
};

export default AppRoutes;
