import React, { useState } from "react";
import { Link } from "react-router-dom";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import { PopupModal } from "react-calendly";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* CONFIG */
import { animation, containerAnimation, GCP_BUCKET_URL } from "config";

/* ICONS */
import { MdOutlineArrowOutward } from "react-icons/md";
import { BsCalendar2DateFill } from "react-icons/bs";

/* FORM VALIDATIONS */
import * as yup from "yup";
import { useFormik } from "formik";

/* API */
import { newsletterAPI } from "service/api";

/* VALIDATION SCHEMA */
const waitlistValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .min(1, "Email Address is required")
    .required("Email Address is required."),
});

/* TAGS */
const tags = [
  {
    id: 1,
    name: "Task Management",
    link: "/blogs/task-management",
  },
  {
    id: 2,
    name: "Employee Engagement",
    link: "/blogs/employee-engagement",
  },
  {
    id: 3,
    name: "Performance Management",
    link: "/blogs/performance-management",
  },
  {
    id: 4,
    name: "Change Management",
    link: "/blogs/change-management",
  },
  {
    id: 5,
    name: "OKR Management",
    link: "/blogs/okr-management",
  },
  {
    id: 6,
    name: "Project Planning",
    link: "/blogs/project-planning",
  },
  {
    id: 7,
    name: "Project Management",
    link: "/blogs/project-management",
  },
  {
    id: 8,
    name: "Team collaboration",
    link: "/blogs/team-collaboration",
  },
];

const WhatIsOKR = () => {
  const [calendlyModal, setCalendlyModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  /* FORM HANDLING */
  const formHandling = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: waitlistValidation,
    onSubmit: (values) => {
      const obj = {
        email: values.email,
      };
      setFormLoading(true);
      newsletterAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(
              "Congratulations! 🎉 You have subscribed to our newsletter."
            );
          } else {
            toast.error(
              "We were not able to capture your details. Please try again."
            );
          }
          setFormLoading(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formHandling.resetForm();
          setFormLoading(false);
        });
    },
  });

  /* SMALL TAGS BUTTON */
  const SmallTagsButton = (props) => {
    return (
      <Link to={props.link}>
        <Button className="shadow-none hover:shadow-none bg-greyBg hover:bg-logoOrange rounded-md text-sm font-proxima-regular text-greyText hover:text-white normal-case font-medium px-4 py-2 transition-all duration-300 ease-in-out">
          {props.label}
        </Button>
      </Link>
    );
  };

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* CONTENT */}
      <motion.div variants={animation} className="py-5 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
            {/* CONTENT */}
            <div className="col-span-2 border-r-0 xl:border-r lg:border-r border-greyBorder">
              <div className="pr-0 xl:pr-8 lg:pr-8">
                <img
                  src={`${GCP_BUCKET_URL}/blogs/what-is-okr/okr-banner.webp`}
                  className="rounded-xl object-cover h-[20vh] xl:h-[30vh] lg:h-[30vh] w-full shadow"
                  alt="What is OKR"
                />

                <div className="mt-5">
                  <h1 className="mt-5 text-xl xl:text-3xl lg:text-3xl font-tight-bold text-black">
                    A Comprehensive Guide to the OKR Framework
                  </h1>
                  <p className="mt-3 text-base font-proxima-regular text-greyText leading-relaxed">
                    Organizations often look for me­thods that can align their
                    teams and drive significant progre­ss. One such approach is
                    OKR, which stands for Objectives and Ke­y Results. In this
                    comprehensive guide, inspired by insights from "Measure What
                    Matters" by John Doerr, we'll delve deep into the essence of
                    OKR, its significance in driving remarkable growth, and how
                    our OKR software, Aligned Rewards, can propel your
                    organization towards unprecedented success.
                  </p>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Defining OKR: Understanding the Framework
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      John Doerr, in his book "Measure What Matters," emphasizes
                      the importance of setting clear goals and measurable
                      targets as the foundation of effective goal-setting. At
                      its core, the OKR (Objectives and Key Results) method was
                      pioneered by Intel and later adopted by companies like
                      Google. Objectives are ambitious, qualitative goals that
                      an organization aims to achieve, while Key Results are
                      specific, measurable outcomes that indicate progress
                      towards those objectives.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      By incorporating Doerr's insights, organizations can align
                      their efforts and focus on driving tangible results. This
                      clarity of purpose, as advocated by Doerr, fosters
                      accountability, transparency, and alignment across teams,
                      ultimately driving organizational performance and success.
                    </p>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      How do OKRs Drive 10x Growth?
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      John Doerr's philosophy highlights the power of OKRs
                      (Objectives and Key Results) in driving incredible growth.
                      By setting ambitious goals that push boundaries and
                      defining measurable steps to track progress, organizations
                      can unlock their full potential.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      OKRs, as Doerr recommends, inspire teams to think big,
                      prioritize strategic initiatives, and tenaciously pursue
                      excellence. They encourage innovation, experimentation,
                      and flexibility, nurturing a culture of continuous
                      improvement and learning. With OKRs, organizations can not
                      only achieve gradual progress but also make significant
                      breakthroughs and achieve 10x growth.
                    </p>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      The Power of OKR Software: Find Success with Aligned
                      Rewards
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Objectives and Key Results (OKRs) have great potential to
                      drive growth, but managing them across different teams and
                      departments can be challenging. This is where our OKR
                      software, Aligned Rewards, can help. Drawing on insights
                      from leading experts, our software makes it easy for
                      organizations to implement and manage OKRs.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      From setting and sharing objectives to tracking key
                      results and analyzing performance, our OKR software
                      streamlines the entire process. This saves time and
                      resources while maximizing the impact. With real-time
                      visibility into progress, actionable insights, and
                      collaboration tools, our software helps organizations
                      achieve their growth goals faster and more effectively
                      than ever before.
                    </p>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Examples of OKR Objectives and Key Results
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Let's look at some­ real-life example­s of how OKRs work.
                    </p>

                    {/* OBJECTIVE 01 */}
                    <div className="mt-3 bg-greyBg bg-opacity-70 border border-greyBorder p-5 rounded-lg">
                      <h5 className="text-base xl:text-lg lg:text-lg font-tight-bold text-black">
                        Objective: Boost customer satisfaction
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        This goal aims to enhance the overall customer
                        experience, leading to higher retention, positive
                        word-of-mouth referrals, and ultimately, sustainable
                        business growth.
                      </p>
                      <p className="text-base font-proxima-semibold text-black mt-3 leading-relaxed">
                        Key Result 1: Achieve a Net Promoter Score (NPS) of 70
                        or higher.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                        The Net Promoter Score (NPS) is a widely used metric for
                        evaluating customer loyalty and satisfaction. A score of
                        70 or higher is considered excellent and indicates a
                        highly satisfied customer base.
                      </p>
                      <p className="text-base font-proxima-semibold text-black mt-3 leading-relaxed">
                        Key Re­sult 2: Cut customer service re­sponse time by
                        20%
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                        Responding to custome­r questions and problems quickly
                        is crucial for kee­ping them satisfied. By reducing
                        custome­r service response­ time by 20%, the company can
                        improve its support proce­sses, minimize the time­
                        customers have to wait, and show that it cares about
                        the­ir needs.
                      </p>
                    </div>

                    {/* OBJECTIVE 02 */}
                    <div className="mt-5 bg-greyBg bg-opacity-70 border border-greyBorder p-5 rounded-lg">
                      <h5 className="text-base xl:text-lg lg:text-lg font-tight-bold text-black">
                        Objective: Launch a new product successfully
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        The goal is to successfully launch a new product. This
                        requires careful planning, execution, and positioning in
                        the market. The objective is to hit key milestones to
                        ensure a smooth and impactful launch. This will drive
                        adoption and generate excitement among target customers.
                        By setting clear objectives and key results, the
                        organization can align teams and allocate resources
                        effectively.
                      </p>
                      <p className="text-base font-proxima-semibold text-black mt-3 leading-relaxed">
                        Key Result 1: Achieve 10,000 pre-orders before the
                        launch date
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                        One key result is to achieve 10,000 pre-orders before
                        the launch date. Pre-orders indicate customer interest
                        and demand for the new product. Reaching this target
                        will build momentum and validate market demand.
                        Achieving this milestone lays the foundation for a
                        successful product launch.
                      </p>
                      <p className="text-base font-proxima-semibold text-black mt-3 leading-relaxed">
                        Key Re­sult 2: Secure media cove­rage in at least three­
                        top-tier publications
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                        Building exciteme­nt and buzz around a new product
                        launch requires smart marke­ting and publicity work.
                        Securing coverage in major publications he­lps amplify
                        the reach and visibility of the product, conne­cting it
                        with potential customers and industry expe­rts. By
                        aiming for coverage in at least thre­e top publications,
                        the organization can increase­ brand awareness,
                        credibility, and anticipation for the­ new offering.
                      </p>
                    </div>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      The Power of OKRs for Organizational Success
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      John Doerr's insights highlight the crucial role of OKRs
                      (Objectives and Key Results) in driving organizational
                      success. OKRs provide clarity of purpose, foster
                      alignment, and encourage accountability, all of which are
                      essential for achieving meaningful outcomes.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      One of the key advantages of OKRs, as emphasized by Doerr,
                      is their ability to promote transparency and alignment
                      across the organization. By cascading objectives from
                      top-level leadership down to individual contributors, OKRs
                      ensure that everyone is working towards the same
                      overarching goals. This alignment fosters collaboration,
                      minimizes silos, and maximizes the collective impact of
                      the organization.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Additionally, OKRs encourage a culture of accountability
                      by clearly specifying expectations and holding teams
                      responsible for their performance. By periodically
                      reviewing and updating OKRs, organizations can make
                      adjustments as needed, adapt to shifting market
                      conditions, and maintain focus on the most crucial
                      priorities.
                    </p>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Transform your Organization’s Vision to Reality with OKR
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      John Doerr's insights from "Measure What Matters"
                      highlight the transformative power of OKRs in driving
                      growth and achieving organizational success. By setting
                      clear objectives, defining measurable key results, and
                      using our OKR software, organizations can unlock their
                      full potential and achieve remarkable success. Whether
                      you're aiming for incremental improvements or 10x growth,
                      OKRs provide a roadmap to turn your vision into reality.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Sign up on Aligned Rewards today, that isn’t just OKR
                      software, but everything an organization needs to boost
                      employee engagement, foster goal-setting, and track 360°
                      Performance Feedback.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* SIDEBAR */}
            <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0">
              <div className="pl-0 xl:pl-8 lg:pl-8  h-fit sticky top-24">
                {/* NEWSLETTER */}
                {/* NEWSLETTER */}
                <motion.div
                  variants={animation}
                  className="pb-8 border-b border-greyBorder"
                >
                  <div
                    variants={animation}
                    className="bg-greyBg bg-opacity-50 border border-greyBorder rounded-lg p-5 xl:p-8 lg:p-8"
                  >
                    <h5 className="text-2xl font-tight-bold">
                      Join Our Community
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3">
                      Join us so that we can send you updates on new features,
                      valuable tips, expert strategies and our exclusive offers
                      delivered directly to your inbox.
                    </p>
                    <form className="mt-5" onSubmit={formHandling.handleSubmit}>
                      <input
                        type="email"
                        placeholder="Type you email address"
                        name="newsletterEmail"
                        id="newsletterEmail"
                        value={formHandling.values.email}
                        onChange={formHandling.handleChange}
                        className={`w-full block bg-white px-4 py-3 rounded-lg text-sm font-proxima-semibold text-black border ${
                          formHandling.errors.email &&
                          formHandling.touched.email
                            ? "border-red-500"
                            : "border-greyBorder"
                        } border-greyBorder focus:outline-brandColor placeholder:font-proxima-regular`}
                      />
                      <Button
                        disabled={formLoading}
                        type={formLoading ? "button" : "submit"}
                        className="group mt-3 w-full flex items-center gap-1 justify-center shadow-none hover:shadow-none font-proxima-semibold text-white rounded-md bg-brandColor normal-case text-sm hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                      >
                        {formLoading ? "Please wait.." : "Subscribe"}
                        <MdOutlineArrowOutward className="w-4 h-4 text-white" />
                      </Button>
                    </form>
                  </div>
                </motion.div>

                {/* Schedule a Demo */}
                <motion.div variants={animation} className="pt-8">
                  <BsCalendar2DateFill className="w-10 h-10 text-black" />
                  <h5 className="mt-3 text-2xl font-tight-bold leading-snug">
                    Learn how Aligned Rewards can be useful for your
                    organization
                  </h5>
                  <p className="mt-3 text-base font-proxima-regular text-greyText">
                    Experience a guided walkthrough of all our features in this
                    exclusive video!
                  </p>
                  <Button
                    className="mt-5 shadow-none hover:shadow-none bg-brandColor text-white font-proxima-semibold text-sm normal-case"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </motion.div>

                {/* TAGS */}
                <motion.div variants={animation} className="pt-8 hidden">
                  <h5 className="text-xl xl:text-2xl lg:text-2xl font-tight-bold text-black tracking-tight">
                    Tags
                  </h5>
                  <div className="flex items-center flex-wrap gap-2 mt-5">
                    {tags.map((item) => (
                      <React.Fragment key={item.id}>
                        <SmallTagsButton label={item.name} link={item.link} />
                      </React.Fragment>
                    ))}
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALENDLY MODAL */}
      <PopupModal
        open={calendlyModal}
        onModalClose={() => setCalendlyModal(!calendlyModal)}
        rootElement={document.getElementById("root")}
        url="https://calendly.com/contact-ucp8/30min"
      />
    </motion.section>
  );
};

export default WhatIsOKR;
