import React, { useState } from "react";
import { Link } from "react-router-dom";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import { PopupModal } from "react-calendly";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* CONFIG */
import { animation, containerAnimation, GCP_BUCKET_URL } from "config";

/* ICONS */
import { MdOutlineArrowOutward } from "react-icons/md";
import { BsCalendar2DateFill } from "react-icons/bs";

/* FORM VALIDATIONS */
import * as yup from "yup";
import { useFormik } from "formik";

/* API */
import { newsletterAPI } from "service/api";

/* VALIDATION SCHEMA */
const waitlistValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .min(1, "Email Address is required")
    .required("Email Address is required."),
});

/* TAGS */
const tags = [
  {
    id: 1,
    name: "Task Management",
    link: "/blogs/task-management",
  },
  {
    id: 2,
    name: "Employee Engagement",
    link: "/blogs/employee-engagement",
  },
  {
    id: 3,
    name: "Performance Management",
    link: "/blogs/performance-management",
  },
  {
    id: 4,
    name: "Change Management",
    link: "/blogs/change-management",
  },
  {
    id: 5,
    name: "OKR Management",
    link: "/blogs/okr-management",
  },
  {
    id: 6,
    name: "Project Planning",
    link: "/blogs/project-planning",
  },
  {
    id: 7,
    name: "Project Management",
    link: "/blogs/project-management",
  },
  {
    id: 8,
    name: "Team collaboration",
    link: "/blogs/team-collaboration",
  },
];

const PerformanceManagement = () => {
  const [calendlyModal, setCalendlyModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  /* FORM HANDLING */
  const formHandling = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: waitlistValidation,
    onSubmit: (values) => {
      const obj = {
        email: values.email,
      };
      setFormLoading(true);
      newsletterAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(
              "Congratulations! 🎉 You have subscribed to our newsletter."
            );
          } else {
            toast.error(
              "We were not able to capture your details. Please try again."
            );
          }
          setFormLoading(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formHandling.resetForm();
          setFormLoading(false);
        });
    },
  });

  /* SMALL TAGS BUTTON */
  const SmallTagsButton = (props) => {
    return (
      <Link to={props.link}>
        <Button className="shadow-none hover:shadow-none bg-greyBg hover:bg-logoOrange rounded-md text-sm font-proxima-regular text-greyText hover:text-white normal-case font-medium px-4 py-2 transition-all duration-300 ease-in-out">
          {props.label}
        </Button>
      </Link>
    );
  };

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* CONTENT */}
      <motion.div variants={animation} className="py-5 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
            {/* CONTENT */}
            <div className="col-span-2 border-r-0 xl:border-r lg:border-r border-greyBorder">
              <div className="pr-0 xl:pr-8 lg:pr-8">
                <img
                  src={`${GCP_BUCKET_URL}/blogs/performance-management/performance-management-inner-banner.webp`}
                  className="rounded-xl object-cover h-[20vh] xl:h-[30vh] lg:h-[30vh] w-full shadow"
                  alt="A Сomprehensive Guide to Revolutionizing 360° performance feedback"
                />

                <div className="mt-5">
                  <h1 className="mt-5 text-xl xl:text-3xl lg:text-3xl font-tight-bold text-black">
                    A Сomprehensive Guide to Revolutionizing Performance
                    Management
                  </h1>
                  <p className="mt-3 text-base font-proxima-regular text-greyText leading-relaxed">
                    Have you had up to your neck with traditional, time
                    consuming methods of once-a- year performance appraisals
                    before? Are you about to implement a new and entirely
                    different strategy for managing job performance? Today has
                    an endless pattern of fast business where one needs instant
                    performance analyses, and this need is greater than any
                    time. Organizations can now leverage such an analysis
                    function for 360° performance feedback in real-time instead
                    of waiting for quarterly or yearly assessments, thereby
                    optimizing employee performance and driving profitability.
                    Let us walk down the path as we delve into the idea of 360°
                    performance feedback, introducing its processes and
                    objectives, and then have a closer look at the innovative
                    solutions by Aligned Rewards that are here to help you
                    simplify and refine 360° performance feedback in your
                    organization.
                  </p>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      What is 360° Performance Feedback?
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      360° performance feedback is defined as a procedure whose
                      function is to establish goals, monitor their progress,
                      provide feedback, and value performance in order to drive
                      the success of both individuals and the whole
                      organization. It includes different actions, among which
                      are establishing the goals, performance tracking, coaching
                      and development of the employees directed toward maximum
                      effectiveness and matching with the organizational goals.
                      Through 360° performance feedback, companies may
                      adequately set clear objectives, understand the employee'
                      process, and try to reach their highest potential.
                    </p>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      The Traditional Approach vs. Real-Time Analysis
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Usually, performance appraisals are conducted annually or
                      quarterly, and the employees set goals for the future
                      period, so they receive feedback based on the performance
                      appraised in the past. But this method is time-consuming
                      and not as flexible as it should be. It becomes difficult
                      for companies to sustain in new stays of business with the
                      traditional method of 360° performance feedback.
                    </p>
                    <p>
                      Meanwhile, live evaluation permits the companies to watch
                      the employees' performance all the time, appreciate good
                      work, identify areas that need improvement, and give quick
                      information and training, ensuring growth all year long.
                      By using live data and analytics, companies can be precise
                      in their decisions, encourage some of the best
                      performances, and improve their revenues.
                    </p>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      The 360° Performance Feedback Process
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      The 360° performance feedback process typically consists
                      of several key steps:
                    </p>
                    <ol className="mt-3 ml-8 list-decimal">
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-semibold text-black">
                          Goal Setting:
                        </span>{" "}
                        The major focus will be on setting out goals that are
                        clear and measurable in relation to organizational
                        objectives and individual roles and responsibilities.
                        Goals should be SMART, i.e., specific, achievable,
                        relevant, and time-bound, giving employees a solid
                        compass on their way towards success.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Performance Tracking:
                        </span>{" "}
                        It means tracking employee performance with respect to
                        predetermined objectives and standard indicators (KPIs)
                        using real-time data and analysis. By monitoring
                        progress periodically, organizations can recognize
                        achievements and flaws that can be fixed over time
                        through the required actions and modifications.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Feedback and Coaching:
                        </span>{" "}
                        Giving continuous feedback, mentoring, and helps
                        employees improve their performance and achieve their
                        goals. Feedback ought to be constructive, timely, and
                        focused on both the strong sides and the areas for
                        needed improvement. Training and mentoring of the
                        employees can be helpful in their development of new
                        skills, the overcoming of challenges, and the
                        realization of their potential.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Performance Evaluation:
                        </span>{" "}
                        Engaging in systematic performance appraisal programs to
                        measure employee progress, discover development
                        opportunities, and reward accomplishments is performance
                        evaluation. Evaluation should be based on objective
                        measures and ought to be connected to organizational
                        goals so that employees have a clear vision of their
                        performance and expectations. 360° Performance Feedback
                        includes various appraisal methods aimed at evaluating
                        employee performance, including self-assessments, peer
                        reviews, and 36-degree feedback.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Development Planning:
                        </span>{" "}
                        Engaging employees through the design of customized
                        development plans aimed at improving capabilities,
                        correcting flaws, and nurturing career development
                        include development planning. Adjustment plans should be
                        adapted to personal ambitions and plans, with options
                        for guiding, mentoring, and practical learning.
                      </li>
                    </ol>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Benefits of 360° Performance Feedback
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Effective 360° performance feedback offers numerous
                      benefits to both employees and organizations, including:
                    </p>
                    <ol className="mt-3 ml-8 list-decimal">
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-semibold text-black">
                          Enhanced Employee Engagement:
                        </span>{" "}
                        Goals that are clear, immediate feedback, and chances to
                        develop and grow lead to better engagement and job
                        satisfaction. Employees who are actively engaged show
                        more dedication to their job and, consequently,
                        demonstrate a higher level of productivity as well as a
                        longer tenure with the company.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Improved Performance:
                        </span>{" "}
                        When organizational goals are in tune with individual
                        targets and performance is measured in real time,
                        organizations can increase employee productivity and
                        efficiency. Employees are much sharper, motivated, and
                        disciplined in their work; hence, they contribute to
                        broader organizational success.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Enhanced Communication and Collaboration:
                        </span>{" "}
                        360° performance feedback helps ensure there is open
                        communication, transparency, and collaboration among
                        employees and teams, which in turn lead to better
                        results and outcomes. Through regular communication and
                        creating an environment of accountability, businesses
                        may create trust, develop enduring associations, and
                        achieve collective success.
                      </li>
                      <li className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="text-black font-proxima-semibold">
                          Better Decision-Making:
                        </span>{" "}
                        Organizations can make intelligent decisions, track
                        trends and patterns, and direct resources to areas with
                        a higher return on investment through real-time
                        performance data and analytics. Through the utilization
                        of information from 360° performance feedback solutions,
                        organizations can deploy resources in an efficient way,
                        neutralize risks, and gain chances for progress and
                        invention.
                      </li>
                    </ol>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Your Partner in 360° Performance Feedback Tracking
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Aligned Rewards is a unique software system that has been
                      built to simplify and improve 360° performance feedback
                      procedures in your firm. A solid 360° performance feedback
                      appraisal system enables organizations to conduct regular
                      performance evaluations and provide valuable feedback to
                      employees. With an effective 360° performance feedback
                      appraisal system in place, organizations can streamline
                      the evaluation process and align individual performance
                      with organizational goals.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Our tool bridges the gap between employees’ achievements
                      and rewards offered by organizations, and it can motivate
                      them towards higher levels of performance, engagement, and
                      success through the guidance of such features as goal
                      setting, performance tracking, feedback management, and
                      development planning. The Aligned Rewards solution is the
                      tool to harness real-time data and create analytics for
                      organizations to take actionable insights, smartly address
                      business issues, and eventually achieve strategic goals
                      effortlessly.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      <Link
                        to="/waitlist"
                        className="font-proxima-semibold text-brandColor"
                      >
                        Join the waitlist
                      </Link>{" "}
                      for Aligned Rewards now for an improved workplace
                      scenario.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      360° Performance Feedback is an indispensable process for
                      one’s own success and organizational success in general.
                      Implementing a live strategy of performance monitoring
                      together with the use of modern tools like Aligned Rewards
                      will help organizations improve employee performance and
                      engagement on the one hand and attain their tactical goals
                      on the other.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* SIDEBAR */}
            <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0">
              <div className="pl-0 xl:pl-8 lg:pl-8  h-fit sticky top-24">
                {/* NEWSLETTER */}
                <motion.div
                  variants={animation}
                  className="pb-8 border-b border-greyBorder"
                >
                  <div
                    variants={animation}
                    className="bg-greyBg bg-opacity-50 border border-greyBorder rounded-lg p-5 xl:p-8 lg:p-8"
                  >
                    <h5 className="text-2xl font-tight-bold">
                      Join Our Community
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3">
                      Join us so that we can send you updates on new features,
                      valuable tips, expert strategies and our exclusive offers
                      delivered directly to your inbox.
                    </p>
                    <form className="mt-5" onSubmit={formHandling.handleSubmit}>
                      <input
                        type="email"
                        placeholder="Type you email address"
                        name="newsletterEmail"
                        id="newsletterEmail"
                        value={formHandling.values.email}
                        onChange={formHandling.handleChange}
                        className={`w-full block bg-white px-4 py-3 rounded-lg text-sm font-proxima-semibold text-black border ${
                          formHandling.errors.email &&
                          formHandling.touched.email
                            ? "border-red-500"
                            : "border-greyBorder"
                        } border-greyBorder focus:outline-brandColor placeholder:font-proxima-regular`}
                      />
                      <Button
                        disabled={formLoading}
                        type={formLoading ? "button" : "submit"}
                        className="group mt-3 w-full flex items-center gap-1 justify-center shadow-none hover:shadow-none font-proxima-semibold text-white rounded-md bg-brandColor normal-case text-sm hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                      >
                        {formLoading ? "Please wait.." : "Subscribe"}
                        <MdOutlineArrowOutward className="w-4 h-4 text-white" />
                      </Button>
                    </form>
                  </div>
                </motion.div>

                {/* Schedule a Demo */}
                <motion.div variants={animation} className="pt-8">
                  <BsCalendar2DateFill className="w-10 h-10 text-black" />
                  <h5 className="mt-3 text-2xl font-tight-bold leading-snug">
                    Learn how Aligned Rewards can be useful for your
                    organization
                  </h5>
                  <p className="mt-3 text-base font-proxima-regular text-greyText">
                    Experience a guided walkthrough of all our features in this
                    exclusive video!
                  </p>
                  <Button
                    className="mt-5 shadow-none hover:shadow-none bg-brandColor text-white font-proxima-semibold text-sm normal-case"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </motion.div>

                {/* TAGS */}
                <motion.div variants={animation} className="pt-8 hidden">
                  <h5 className="text-xl xl:text-2xl lg:text-2xl font-tight-bold text-black tracking-tight">
                    Tags
                  </h5>
                  <div className="flex items-center flex-wrap gap-2 mt-5">
                    {tags.map((item) => (
                      <React.Fragment key={item.id}>
                        <SmallTagsButton label={item.name} link={item.link} />
                      </React.Fragment>
                    ))}
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALENDLY MODAL */}
      <PopupModal
        open={calendlyModal}
        onModalClose={() => setCalendlyModal(!calendlyModal)}
        rootElement={document.getElementById("root")}
        url="https://calendly.com/contact-ucp8/30min"
      />
    </motion.section>
  );
};

export default PerformanceManagement;
