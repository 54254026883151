import React, { useState } from "react";
import { Link } from "react-router-dom";

/* PACKAGES */
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import { PopupModal } from "react-calendly";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* CONFIG */
import { animation, containerAnimation } from "config";

/* ICONS */
import { MdOutlineArrowOutward } from "react-icons/md";
import { BsCalendar2DateFill } from "react-icons/bs";

/* FORM VALIDATIONS */
import * as yup from "yup";
import { useFormik } from "formik";

/* API */
import { newsletterAPI } from "service/api";

/* VALIDATION SCHEMA */
const waitlistValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .min(1, "Email Address is required")
    .required("Email Address is required."),
});

/* TAGS */
const tags = [
  {
    id: 1,
    name: "Task Management",
    link: "/blogs/task-management",
  },
  {
    id: 2,
    name: "Employee Engagement",
    link: "/blogs/employee-engagement",
  },
  {
    id: 3,
    name: "Performance Management",
    link: "/blogs/performance-management",
  },
  {
    id: 4,
    name: "Change Management",
    link: "/blogs/change-management",
  },
  {
    id: 5,
    name: "OKR Management",
    link: "/blogs/okr-management",
  },
  {
    id: 6,
    name: "Project Planning",
    link: "/blogs/project-planning",
  },
  {
    id: 7,
    name: "Project Management",
    link: "/blogs/project-management",
  },
  {
    id: 8,
    name: "Team collaboration",
    link: "/blogs/team-collaboration",
  },
];

const BenefitsRemote = () => {
  const [calendlyModal, setCalendlyModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  /* FORM HANDLING */
  const formHandling = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: waitlistValidation,
    onSubmit: (values) => {
      const obj = {
        email: values.email,
      };
      setFormLoading(true);
      newsletterAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(
              "Congratulations! 🎉 You have subscribed to our newsletter."
            );
          } else {
            toast.error(
              "We were not able to capture your details. Please try again."
            );
          }
          setFormLoading(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formHandling.resetForm();
          setFormLoading(false);
        });
    },
  });

  /* SMALL TAGS BUTTON */
  const SmallTagsButton = (props) => {
    return (
      <Link to={props.link}>
        <Button className="shadow-none hover:shadow-none bg-greyBg hover:bg-logoOrange rounded-md text-sm font-proxima-regular text-greyText hover:text-white normal-case font-medium px-4 py-2 transition-all duration-300 ease-in-out">
          {props.label}
        </Button>
      </Link>
    );
  };

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* CONTENT */}
      <motion.div variants={animation} className="py-5 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3">
            {/* CONTENT */}
            <div className="col-span-2 border-r-0 xl:border-r lg:border-r border-greyBorder">
              <div className="pr-0 xl:pr-8 lg:pr-8">
                <h1 className="mt-5 text-xl xl:text-3xl lg:text-3xl font-tight-bold text-black leading-snug">
                  The Benefits of Remote Work: How to Maintain Productivity and
                  Balance
                </h1>

                <div className="mt-5">
                  <p className="mt-3 text-base font-proxima-regular text-greyText leading-relaxed">
                    The trend of working at home is relatively new, but it has
                    become very popular over the past few years. Amplified by
                    global experiences and the evolution of technology, remote
                    work has become feasible and preferable for many employees
                    and companies. This blog by Aligned Rewards provides an
                    insight into remote work and good practices for working
                    productively and enjoying a work-life balance when working
                    remotely.
                  </p>

                  <div className="mt-5">
                    <h2 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Understanding the Benefits of Remote Work
                    </h2>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      Remote work involves working from home and comes with
                      numerous benefits for both workers and companies. Let us
                      look at some of the major advantages that make remote work
                      a viable option.
                    </p>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-bold text-black">
                        1. Increased Flexibility
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Flexible working is undoubtedly one of the biggest perks
                        of working remotely. One of the major advantages of this
                        type of employment is that employees can even select
                        their desired working hours and preferred working
                        location. This flexibility can result in high
                        satisfaction with work-life balance since employees can
                        easily fit work into their lives.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span> A
                        remote worker may wake up early, begin work in the
                        morning, leave to attend a personal errand in the middle
                        of the day, and get back to work in the evening. This
                        flexibility reduces stress and enables people to remain
                        active throughout the day.
                      </p>
                    </div>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-bold text-black">
                        2. Cost savings
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Remote work is known to result in a significant
                        reduction in costs for both the worker and the employer.
                        Employees cut costs on transport, uniforms, and meals or
                        snacks during duty. Employers, on the other hand, can
                        avoid certain overhead costs associated with maintaining
                        a workplace.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span> A
                        firm that opts for a complete implementation of remote
                        work eliminates rent charges on physical office space,
                        while employees similarly do not spend money on fuel or
                        public transport.
                      </p>
                    </div>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-bold text-black">
                        3. Access to a Global Talent Pool
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Another benefit of remote work is flexibility, which
                        leads to the ability to hire people regardless of
                        geographical location. It enables organizations to have
                        access to the most talented individuals in the world
                        with the specific skills they require for their
                        openings.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span> A
                        tech firm can employ an independent developer from
                        another country; this comes with additional benefits
                        that can be missing locally.
                      </p>
                    </div>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-bold text-black">
                        4. Improved employee satisfaction
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Remote work reduces employee turnover because employees
                        exercise independence, have flexible work schedules, and
                        experience little or no transport stress. Happy
                        employees ensure that they are committed, determined,
                        and busy working, thus increasing productivity and
                        efficiency in an organization.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span>{" "}
                        People who work from home could express higher levels of
                        job satisfaction since they are likely to be closer to
                        their families and will be able to engage in other
                        activities they like.
                      </p>
                    </div>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-bold text-black">
                        5. Enhanced Productivity
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Remote work does not necessarily deter effectiveness in
                        organizations, as some people may have assumed. Workers
                        in a remote setup are likely to have less interference
                        from other people and have more control over their
                        surroundings, which in this case means they are likely
                        to be more productive.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span> With
                        no interruptions from colleagues located in the same
                        office and less micro-management, remote employees can
                        develop their own comfortable environment to work in
                        and, thus, be more efficient.
                      </p>
                    </div>
                  </div>

                  <div className="mt-10">
                    <h2 className="text-lg xl:text-xl lg:text-xl font-tight-bold text-black">
                      Tips for Maintaining Productivity and Balance in Remote
                      Work
                    </h2>
                    <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                      As illustrated earlier, working from home elicits numerous
                      advantages; nevertheless, the organization and efficiency
                      of operations can be vague. Here are a few tips and tricks
                      that can assist in handling remote working environments
                      effectively.
                    </p>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-bold text-black">
                        1. Provide the Right Tools and Technology
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Employers should ensure that employees working remotely
                        get all the necessary equipment, including computers and
                        software. This includes an Internet connection and
                        software that is suitable for the course, as well as
                        working tools that enable effective communication and
                        cooperation.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span> Make
                        sure your staff has access to secure VPNs, office
                        collaboration tools such as Teams, Slack, and other
                        similar tools, and project management tools such as
                        Asana, Trello, etc.
                      </p>
                      <div className="mt-5">
                        <p className="text-base font-proxima-semibold text-black">
                          How to implement
                        </p>
                        <ul className="ml-8 mt-1 list-disc">
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Perform periodic surveys to detect the necessity of
                            technologies.
                          </li>
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Suggest having training concerning how to use newly
                            introduced tools properly.
                          </li>
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Make technical support available to help fix every
                            problem that may arise quickly.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-bold text-black">
                        2. Establish Clear Communication Channels
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Communication plays a central role in enabling workers
                        to complete their tasks efficiently and with the support
                        of their teammates when working remotely. For different
                        kinds of communication, set up project-specific channels
                        for, for example, project status reports, team meetings,
                        and casual interactions.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span> For
                        day-to-day interactions with the team members,
                        specifically for work-related conversations, use Slack;
                        for meetings involving multiple participants, use Zoom;
                        and for sending newsletters and updates to the members,
                        write emails.
                      </p>
                      <div className="mt-5">
                        <p className="text-base font-proxima-semibold text-black">
                          How to implement
                        </p>
                        <ul className="ml-8 mt-1 list-disc">
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Set parameters with reference to how the
                            stakeholders are supposed to engage one another and
                            the expected reply time.
                          </li>
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            To keep all players informed and in harmony, conduct
                            more virtual meetings at set intervals.
                          </li>
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            This is because it will improve the interaction
                            between different individuals as they will be able
                            to conduct video calls.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-bold text-black">
                        3. Set Clear Expectations and Goals
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Maintaining standards means that employees know what is
                        expected of them and, therefore, remain productive.
                        Explain project specifications, outline tangible goals,
                        and schedule them to guarantee high performance and
                        responsibility.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span> It
                        is advisable to define a project in terms of SMART
                        goals, which would make it easier for employees to track
                        the outcomes that were agreed upon at the onset of the
                        project.
                      </p>
                      <div className="mt-5">
                        <p className="text-base font-proxima-semibold text-black">
                          How to implement
                        </p>
                        <ul className="ml-8 mt-1 list-disc">
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Set measurable objectives and goals for your
                            employee or team.
                          </li>
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            It is also important to employ project management
                            software, as it will assist you in tracking progress
                            and due dates.
                          </li>
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Always revise and possibly redesign goals according
                            to feedback or how effective employees have been.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-bold text-black">
                        4. Foster a Supportive Company Culture
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Ensuring that all employees are physically, mentally,
                        and emotionally healthy, as well as fostering a culture
                        of solidarity in the virtual organization, is critical
                        in the current environment. Promote interaction,
                        celebrate success, and offer resources for psychological
                        assistance for high motivation and continued work.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span>{" "}
                        Schedule get-togethers, team-building sessions over
                        virtual forums, rejoicing about achievements, and
                        providing links to resources or professional help for
                        the employee’s mental health.
                      </p>
                      <div className="mt-5">
                        <p className="text-base font-proxima-semibold text-black">
                          How to implement
                        </p>
                        <ul className="ml-8 mt-1 list-disc">
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Make sure that the employees have their virtual
                            social life and team-building activities at least a
                            few times a week.
                          </li>
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Value and incentivize people and group performances.
                          </li>
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Develop platforms that allow the community to engage
                            with or dwell on less serious issues or topics.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-bold text-black">
                        5. Implement Flexible Work Policies
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        By promoting workplace flexibility, remote employees
                        will be able to strike a balance between their work and
                        family responsibilities. Offer the possibility of
                        working early shifts, a late shift, the first shift, the
                        second shift, or third shift; allow working half days or
                        four days a week; and allow working in different
                        locations for some time.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span>{" "}
                        Permit the employees to work at their convenience within
                        a fixed period of the day, for instance, from 10:00 a.m.
                        to 2:00 p.m., to enable them to meet other personal
                        calendars.
                      </p>
                      <div className="mt-5">
                        <p className="text-base font-proxima-semibold text-black">
                          How to implement
                        </p>
                        <ul className="ml-8 mt-1 list-disc">
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Create standards detailing acceptable situations and
                            conditions for flexible work arrangements
                          </li>
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Assure that all these policies are well communicated
                            to every worker.
                          </li>
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            The company should be able to observe and modify
                            policies aimed at the workers depending on the
                            workers’ feedback and performance levels.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-bold text-black">
                        6. Guarantee the Affordability of Development
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Training also enables the remote employee to learn new
                        things and be excited about the job, thus increasing
                        morale. Provide courses, webinars, and ways to access
                        training that will help them advance within your
                        company.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span> For
                        instance, to enhance online learning in the
                        organization, partner with online learning providers
                        such as Coursera or LinkedIn Learning to provide courses
                        that meet the employees’ career aspirations and
                        organizational requirements.
                      </p>
                      <div className="mt-5">
                        <p className="text-base font-proxima-semibold text-black">
                          How to implement
                        </p>
                        <ul className="ml-8 mt-1 list-disc">
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Determine areas where training may be needed at that
                            stage and recommend sources for the acquisition of
                            the missing skills.
                          </li>
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Remind employees about available opportunities, such
                            as considering obtaining certification or attending
                            webinars.
                          </li>
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Include time for professional development as part of
                            the working schedule.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-bold text-black">
                        7. Promote a Healthy Work-Life Balance
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        It is crucial to recognize that work-life balance is
                        critical when avoiding burnout and promoting work
                        efficiency. Having organizational policies that allow
                        employees to balance their work and personal
                        commitments.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span>{" "}
                        Promote employee well-being by allowing them to take
                        breaks, use up their vacation days, and avoid burnout.
                      </p>
                      <div className="mt-5">
                        <p className="text-base font-proxima-semibold text-black">
                          How to implement
                        </p>
                        <ul className="ml-8 mt-1 list-disc">
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Set rules concerning their working hours and
                            accessibility.
                          </li>
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Promote paid vacation and time off, as well as
                            taking scheduled breaks at work.
                          </li>
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Offer information or training sessions on time use
                            and stress.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="mt-5">
                      <h5 className="text-base font-proxima-bold text-black">
                        8. Engage in Supervision for Staff Health and safety
                      </h5>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        Daily or weekly checks on employees and their health can
                        assist in solving problems that may result in a loss of
                        production. Offer surveys and individual meetings, and
                        make available wellness programs to guarantee that the
                        employees are recognized and valued.
                      </p>
                      <p className="text-base font-proxima-regular text-greyText mt-3 leading-relaxed">
                        <span className="font-proxima-bold">Example:</span>{" "}
                        Engage employees in anonymous surveys or questionnaires
                        to know their levels of satisfaction or stress, then
                        address the issues.
                      </p>
                      <div className="mt-5">
                        <p className="text-base font-proxima-semibold text-black">
                          How to implement
                        </p>
                        <ul className="ml-8 mt-1 list-disc">
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Engage in wellness check-ups and feedback at
                            predetermined frequencies.
                          </li>
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Provide wellness programs and mental health support
                            services.
                          </li>
                          <li className="text-base font-proxima-regular text-greyText mt-2 leading-relaxed">
                            Advocate for well-being concerns by establishing an
                            open-door policy.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="mt-10">
                    <p className="text-base font-proxima-regular text-greyText leading-relaxed">
                      Remote work is beneficial in many ways, including
                      flexibility, cost savings, and the opportunity to access a
                      diverse talent pool. For these advantages to be fully
                      realized, organizations need to have measures in place for
                      effective remote-worker management. To maintain
                      productivity and balance in a remote work setting,
                      companies can stick to offering the appropriate equipment
                      and technology, clarifying communication avenues and
                      schedules, setting reasonable expectations, cultivating a
                      positive company culture, adopting flexible work policies,
                      guaranteeing opportunities for development and
                      advancement, supporting healthy working hours and
                      lifestyles, and regularly checking on employees’ welfare.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText leading-relaxed mt-3">
                      Implementing these approaches not only improves
                      performance but also contributes to a healthy remote
                      working culture, with employees being happier and more
                      motivated, ultimately driving organizational success.
                    </p>
                    <p className="text-base font-proxima-regular text-greyText leading-relaxed mt-3">
                      If you’re looking to build a more positive work
                      environment, join Aligned Rewards today.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* SIDEBAR */}
            <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0">
              <div className="pl-0 xl:pl-8 lg:pl-8  h-fit sticky top-24">
                {/* NEWSLETTER */}
                <motion.div
                  variants={animation}
                  className="pb-8 border-b border-greyBorder"
                >
                  <div
                    variants={animation}
                    className="bg-greyBg bg-opacity-50 border border-greyBorder rounded-lg p-5 xl:p-8 lg:p-8"
                  >
                    <h5 className="text-2xl font-tight-bold">
                      Join Our Community
                    </h5>
                    <p className="text-base font-proxima-regular text-greyText mt-3">
                      Join us so that we can send you updates on new features,
                      valuable tips, expert strategies and our exclusive offers
                      delivered directly to your inbox.
                    </p>
                    <form className="mt-5" onSubmit={formHandling.handleSubmit}>
                      <input
                        type="email"
                        placeholder="Type you email address"
                        name="newsletterEmail"
                        id="newsletterEmail"
                        value={formHandling.values.email}
                        onChange={formHandling.handleChange}
                        className={`w-full block bg-white px-4 py-3 rounded-lg text-sm font-proxima-semibold text-black border ${
                          formHandling.errors.email &&
                          formHandling.touched.email
                            ? "border-red-500"
                            : "border-greyBorder"
                        } border-greyBorder focus:outline-brandColor placeholder:font-proxima-regular`}
                      />
                      <Button
                        disabled={formLoading}
                        type={formLoading ? "button" : "submit"}
                        className="group mt-3 w-full flex items-center gap-1 justify-center shadow-none hover:shadow-none font-proxima-semibold text-white rounded-md bg-brandColor normal-case text-sm hover:bg-opacity-80 transition-all duration-300 ease-in-out"
                      >
                        {formLoading ? "Please wait.." : "Subscribe"}
                        <MdOutlineArrowOutward className="w-4 h-4 text-white" />
                      </Button>
                    </form>
                  </div>
                </motion.div>

                {/* Schedule a Demo */}
                <motion.div variants={animation} className="pt-8">
                  <BsCalendar2DateFill className="w-10 h-10 text-black" />
                  <h5 className="mt-3 text-2xl font-tight-bold leading-snug">
                    Learn how Aligned Rewards can be useful for your
                    organization
                  </h5>
                  <p className="mt-3 text-base font-proxima-regular text-greyText">
                    Experience a guided walkthrough of all our features in this
                    exclusive video!
                  </p>
                  <Button
                    className="mt-5 shadow-none hover:shadow-none bg-brandColor text-white font-proxima-semibold text-sm normal-case"
                    onClick={() => setCalendlyModal(!calendlyModal)}
                  >
                    Schedule a Demo
                  </Button>
                </motion.div>

                {/* TAGS */}
                <motion.div variants={animation} className="pt-8 hidden">
                  <h5 className="text-xl xl:text-2xl lg:text-2xl font-tight-bold text-black tracking-tight">
                    Tags
                  </h5>
                  <div className="flex items-center flex-wrap gap-2 mt-5">
                    {tags.map((item) => (
                      <React.Fragment key={item.id}>
                        <SmallTagsButton label={item.name} link={item.link} />
                      </React.Fragment>
                    ))}
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALENDLY MODAL */}
      <PopupModal
        open={calendlyModal}
        onModalClose={() => setCalendlyModal(!calendlyModal)}
        rootElement={document.getElementById("root")}
        url="https://calendly.com/contact-ucp8/30min"
      />
    </motion.section>
  );
};

export default BenefitsRemote;
