import { useState } from "react";

/* PACKAGES */
import { motion } from "framer-motion";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* ANIMATION */
import { animation, containerAnimation, GCP_BUCKET_URL } from "config";

/* ICONS */
import { BiSolidQuoteRight } from "react-icons/bi";

/* FORM VALIDATION PACKAGES */
import * as yup from "yup";
import { useFormik } from "formik";

/* API */
import { waitListAPI } from "service/api";

/* VALIDATION SCHEMA */
const waitlistValidation = yup.object().shape({
  firstName: yup
    .string()
    .min(1, "First name is required")
    .required("First name is required"),
  lastName: yup
    .string()
    .min(1, "Last name is required")
    .required("Last name is required"),
  email: yup
    .string()
    .email()
    .min(1, "Email Address is required")
    .required("Email Address is required."),
  phoneNumber: yup
    .string()
    .min(1, "Phone is required")
    .required("Phone is required"),
  organizationName: yup
    .string()
    .min(1, "Organization Name is required")
    .required("Organization Name is required"),
  employeeSize: yup
    .object()
    .required("Please select the no. of employees in your organization"),
});

/* EMPLOYEE COUNT OPTIONS */
const employeeCount = [
  { label: "Under 50", value: "Under 50" },
  { label: "51-750", value: "51-750" },
  { label: "750-2500", value: "750-2500" },
  { label: "2500+", value: "2500+" },
];

const WaitingList = () => {
  const [formLoading, setFormLoading] = useState(false);

  /* FORM HANDLING */
  const formHandling = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organizationName: "",
      employeeSize: "",
    },
    validationSchema: waitlistValidation,
    onSubmit: (values) => {
      const obj = {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        organizationName: values.organizationName,
        employeeSize: values.employeeSize?.value,
      };
      setFormLoading(true);
      waitListAPI(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(
              "Congratulations! 🎉 You're now on our exclusive waitlist! We'll keep you posted every step of the way."
            );
          } else {
            toast.error(
              "We were not able to capture your details. Please try again."
            );
          }
          setFormLoading(false);
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          formHandling.resetForm();
          setFormLoading(false);
        });
    },
  });

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* TOAST CONTAINER */}
      <ToastContainer theme="colored" closeOnClick autoClose={5000} />

      {/* GRID CONTENT */}
      <motion.div
        variants={animation}
        className="h-auto xl:h-[92vh] lg:h-[92vh] p-0 py-5 xl:p-5 lg:p-5"
      >
        <div className="container mx-auto h-full">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-y-10 xl:gap-0 lg:gap-0 h-full items-center">
            {/* LEFT SECTION */}
            <div className="col-span-1 pr-0 xl:pr-20 lg:pr-20">
              <div className="w-full xl:w-4/5 lg:w-4/5">
                {/* CONTENT */}
                <div className="pb-5 border-b border-greyBorder">
                  <h1 className="text-3xl xl:text-4xl lg:text-4xl font-tight-bold text-black leading-tight">
                    Join the Aligned Rewards journey and get early access
                  </h1>
                  <p className="text-lg xl:text-xl lg:text-xl font-proxima-regular text-greyText mt-3 tracking-tight">
                    Join our waitlist today to increase the employee engagement
                    at your organization and get notified when we launch.
                  </p>
                </div>

                {/* FORM */}
                <form className="pt-5" onSubmit={formHandling.handleSubmit}>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-1">
                      <label className="text-sm font-proxima-semibold text-black">
                        First Name <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="firstName"
                          placeholder="First name"
                          value={formHandling.values.firstName}
                          onChange={formHandling.handleChange}
                          className="w-full block bg-white bg-opacity-50 px-4 py-3 rounded-lg text-sm font-proxima-semibold text-black border border-greyBorder focus:outline-brandColor placeholder:font-proxima-regular"
                        />
                        {formHandling.errors.firstName &&
                          formHandling.touched.firstName && (
                            <small className="block text-xs font-proxima-semibold text-red-500 mt-1">
                              {formHandling.errors.firstName}
                            </small>
                          )}
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label className="text-sm font-proxima-semibold text-black">
                        Last Name <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="lastName"
                          placeholder="Last name"
                          value={formHandling.values.lastName}
                          onChange={formHandling.handleChange}
                          className="w-full block bg-white bg-opacity-50 px-4 py-3 rounded-lg text-sm font-proxima-semibold text-black border border-greyBorder focus:outline-brandColor placeholder:font-proxima-regular"
                        />
                        {formHandling.errors.lastName &&
                          formHandling.touched.lastName && (
                            <small className="block text-xs font-proxima-semibold text-red-500 mt-1">
                              {formHandling.errors.lastName}
                            </small>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <label className="text-sm font-proxima-semibold text-black">
                      Phone <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <PhoneInput
                        defaultCountry="US"
                        placeholder="Phone number"
                        value={formHandling.values.phoneNumber}
                        onChange={(e) => {
                          formHandling.setFieldValue("phoneNumber", e);
                        }}
                        className="phone-control text-sm font-proxima-semibold text-black border border-greyBorder rounded-lg px-4 py-3"
                      />
                      {formHandling.errors.phoneNumber &&
                        formHandling.touched.phoneNumber && (
                          <small className="block text-xs font-proxima-semibold text-red-500 mt-1">
                            {formHandling.errors.phoneNumber}
                          </small>
                        )}
                    </div>
                  </div>
                  <div className="mt-4">
                    <label className="text-sm font-proxima-semibold text-black">
                      Business Email <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="email"
                        name="email"
                        placeholder="Business email"
                        value={formHandling.values.email}
                        onChange={formHandling.handleChange}
                        className="w-full block bg-white bg-opacity-50 px-4 py-3 rounded-lg text-sm font-proxima-semibold text-black border border-greyBorder focus:outline-brandColor placeholder:font-proxima-regular"
                      />
                      {formHandling.errors.email &&
                        formHandling.touched.email && (
                          <small className="block text-xs font-proxima-semibold text-red-500 mt-1">
                            {formHandling.errors.email}
                          </small>
                        )}
                    </div>
                  </div>
                  <div className="mt-4">
                    <label className="text-sm font-proxima-semibold text-black">
                      Organization Name <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="organizationName"
                        placeholder="Organization name"
                        value={formHandling.values.organizationName}
                        onChange={formHandling.handleChange}
                        className="w-full block bg-white bg-opacity-50 px-4 py-3 rounded-lg text-sm font-proxima-semibold text-black border border-greyBorder focus:outline-brandColor placeholder:font-proxima-regular"
                      />
                      {formHandling.errors.organizationName &&
                        formHandling.touched.organizationName && (
                          <small className="block text-xs font-proxima-semibold text-red-500 mt-1">
                            {formHandling.errors.organizationName}
                          </small>
                        )}
                    </div>
                  </div>
                  <div className="mt-4">
                    <label className="text-sm font-proxima-semibold text-black">
                      Employee count <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Select
                        options={employeeCount}
                        classNamePrefix="custom"
                        placeholder="Select no.of employees"
                        value={formHandling.values.employeeSize}
                        onChange={(e) => {
                          formHandling.setFieldValue("employeeSize", e);
                        }}
                      />
                      {formHandling.errors.employeeSize &&
                        formHandling.touched.employeeSize && (
                          <small className="block text-xs font-proxima-semibold text-red-500 mt-1">
                            {formHandling.errors.employeeSize}
                          </small>
                        )}
                    </div>
                  </div>
                  <div className="mt-4">
                    <Button
                      disabled={formLoading}
                      type={formLoading ? "button" : "submit"}
                      className="w-full bg-brandColor hover:bg-opacity-80 py-3 shadow-none hover:shadow-none text-white text-base font-proxima-semibold normal-case"
                    >
                      {formLoading ? "Please wait.." : "Join the waitlist"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>

            {/* RIGHT SECTION */}
            <div className="col-span-1 h-full">
              <div className="w-full h-full rounded-xl bg-gradient-to-br from-blue-100 to-white relative p-5 xl:p-14 lg:p-14 overflow-hidden">
                {/* TESTIMONIAL */}
                <div className="bg-white rounded-xl px-8 py-5 shadow-md">
                  <h5 className="text-base font-proxima-regular text-greyText leading-relaxed">
                    You cannot mandate productivity; you must provide the tools
                    to let people become their best.
                  </h5>
                  <div className="mt-5 pt-5 border-t border-greyBorder">
                    <div className="flex items-center justify-between">
                      <div>
                        <h5 className="text-base font-proxima-semibold text-black tracking-tight">
                          Steve Jobs
                        </h5>
                        <p className="text-sm font-proxima-semibold text-black">
                          Former CEO of Apple
                        </p>
                      </div>
                      <BiSolidQuoteRight className="w-14 h-14 text-brandColor text-opacity-30" />
                    </div>
                  </div>
                </div>

                {/* PLATFORM VIDEO */}
                <div className="mt-5 xl:mt-14 lg:mt-14 relative">
                  <div className="w-[150vh] h-[80vh] relative xl:absolute lg:absolute top-0 -left-11">
                    <img
                      src={`${GCP_BUCKET_URL}/rename-task-development.png`}
                      className="w-full h-full"
                      alt="Join the waitlist"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default WaitingList;
