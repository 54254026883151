/* LAYOUT FILES */
import { Header, ResponsiveHeader } from "./header";
import Footer from "./footer";

const Layout = ({ children }) => {
  return (
    <>
      <div className="hidden xl:block lg:block">
        <Header />
      </div>
      <div className="block xl:hidden lg:hidden">
        <ResponsiveHeader />
      </div>
      <div className="main">{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
